// Magnet Lead
// ======================

// Vars
// ----------------------

// Base
// ----------------------

.lead-magnet {
  @include media-breakpoint-down(xs) {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .form-group {
    margin: 0 0 20px;
  }


  .lead {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: #2F3941;
    margin: 0 0 24px;

    @include media-breakpoint-down(sm) {
      text-align: center;

      .btn {
        margin-top: 10px;
      }
    }
  }

  .checkbox-group {
    display: flex;
    flex-direction: row;

    label {
      font-size: 13px;
      line-height: 16px;
      color: #646F79;
      padding-left: 8px;
      margin-top: 0;
      margin-bottom: 0;
    }

    input {
      margin-top: 1px;
    }
  }

  h3 {
    color: #2F3941;
    padding-top: 20px;

    @include media-breakpoint-down(sm) {
      text-align: center;
      font-size: 22px;
    }
  }

  .response {
    font-size: 14px;
    color: red;
    margin: 0 0 15px;
  }

  .lead-success {
    display: none;
  }

  .col-image {
    @media(max-width: 575px) {
      display: none;
    }
  }

  .w-315 {
    width: 315px;
  }

  .lead-form {
    @include media-breakpoint-down(xs) {
      margin: 0;
    }

    .lead {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
