
.product-scale {
  overflow-x: hidden;
  .main {
    overflow-x: hidden;
  }
  .graphic-benefit,
  .get-started-new--picture,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }

  .product-top-info .hero-message h1 {
    margin-bottom: 3rem;
  }
  .section-inner-bg {
    @include media-breakpoint-down(sm) {
      background-size: cover !important;
    }
  }
  .get-started-new {
    &--philip {
      padding-top: 73px;
      .section-inner {
        padding-top: 55px;
        padding-bottom: 55px;
      }
      .get-started-name-wrapper {
        z-index: 9;

        @include media-breakpoint-up(md) {
          bottom: -30px;
          right: 0;
        }
      }
      .get-started-name {
        background: rgba(#252530, .4);
      }
      .get-started-new--picture {
        @include media-breakpoint-up(md) {
          right: -8%;
          position:absolute;
          top: inherit;
          bottom: -55px;
        }
        @include media-breakpoint-up(lg) {
          right: -8%;
        }
        img {
          display: block;
        }
      }
    }
  }

  .content-section .section-subtitle {
    letter-spacing: 0.06em;
  }
  .bg-blue {
    background-color: #E1F3FF;
  }

  .section-animation,
  .content-animation,
  .scale-tailor-animation,
  .realtime-score--section-animation,
  .scaling--section-animation {
    .graphic-item,
    .graphic-item_new,
    .graphic-item_new2 {
      visibility: hidden;
      opacity: 0;
      position: relative;
    }

    &.has-animation {
      .graphic-item {
        animation: animRunSlide .5s linear forwards;
      }
    }
  }
  .workflow--section-animation,
  .smarttask--section-animation {
    .graphic-item {
      visibility: hidden;
      opacity: 0;
    }
    &.has-animation {
      .graphic-item {
        animation: animRunShow .5s linear forwards;
      }
    }
  }
  .content-animation {
    &.has-animation {
      .graphic-item,
      .graphic-item_new {
        animation: animRunSlide .6s linear forwards;
        animation-delay: .3s;
      }
    }
    img {
      user-select: none;
    }
    //section5
  }
  //
  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper,
    .graphic-benefit {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }
    .graphic-benefit__left {
      transform: translate(0, -80px);
    }
    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }
    .get-started-new--picture {
      transform: translate(150px, 0);
    }
    .graphic__get-started--right {
      transform: translate(50px, 0);
    }
    .get-started-body {
      transform: translate(-50px, 0);
    }
  }
  .content-section {
    p {
      &.section-subtitle {
        font-family: $font-montserrat;
        color: $blue-900;
        margin-bottom: .5em;
      }
    }

  }

  //nav loading
  .nav {
    &-loading {
      border: 0;
      .nav-item {
        margin-bottom: 1.5em;
        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }
      .nav-link {
        position: relative;
        border: 0;
        padding: 0 1em 0 2.5em;
        @include media-breakpoint-down(sm) {
          padding: 0 .5em 0 1.75em;
        }
        font-family: $font-roboto;
        color: #5D6871;
        &.active {
          background-color: transparent;
          h4 {
            @include media-breakpoint-down(sm) {
              font-size: 1.3rem;
            }
          }

          .loading-vertical {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            border-radius: 6px;
            z-index: 9;
            background: #5D6871;
            height: auto;
            transition: height .3s linear;
            max-height: 100%;
          }
          &.stop-autoplay {
            .loading-vertical {
              transition: height 0s;
              height: 100% !important;
            }
          }
          &:after {
            background: #EFEFF1;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            border-radius: 6px;
            height: 100%;
          }
          //&:before {
          //  content: '';
          //  position: absolute;
          //  top: 0;
          //  left: 0;
          //  width: 6px;
          //  border-radius: 6px;
          //  z-index: 9;
          //  background: #5D6871;
          //  height: 100%;
          //  transition: height 6s linear;
          //
          //}
          p {
            display: block;
            margin-bottom: 0;
          }
        }

        &:not(.active) {
          h3 {
            color: #A4B5C1;
            font-size: 1rem;
          }
          //&:before {
          //  content: '';
          //  position: absolute;
          //  top: 0;
          //  left: 0;
          //  width: 6px;
          //  border-radius: 6px;
          //  height: 0;
          //  z-index: 9;
          //}

          p {
            display: none;
          }
        }
        p {
          line-height: 1.5em;
          min-height: calc(1.6em * 4);
          @include media-breakpoint-down(sm) {
            line-height: 1.3em;
            min-height: calc(1.3em * 5);
          }
        }
      }
    }
  }
  .e-cards {
    .card {
      height: 100%;
      background: $white;
      @include border-radius(4px);
      .card-image {
        margin: 0 auto;
        display: flex;
        align-items: center;
        align-content: center;

        figure {
          margin: 0 auto;
          z-index: 1;
        }
      }
      .card-category {
        font-weight: 400;
        font-size: 1rem;
        line-height: 16px;
        color: $dark-blue;
        text-transform: uppercase;
      }
      .card-body {
        border: 1px solid #C2C5CC;
        border-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .badge-secondary {
          background-color: $light-gray-010;
          color: $dark-blue;
          @include border-radius(4px);
          padding: 4px 12px;
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
        }
      }
      .card-footer {
        text-align: center;
        border: 0;
        padding: 0;
        background-color:$dark-blue;
        &:hover {
          background-color:darken($dark-blue, 15%);
        }
        a {
          text-decoration: none;
          color: $white;
          display: block;
          padding: 0.75rem 1.25rem;
        }
      }
    }
  }

  .smart-signals--section {
    .tab-content {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 600px;
      @include media-breakpoint-down(md) {
        min-height: auto;
      }
    }
  }
  //realtime-score--section
  .realtime-score {

    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: -45%;
      z-index: -1;
    }
    &--section {
      .row {
        position: relative;
      }
      .content-animation {
        z-index: 9;
      }
    }

    &--rect-blue {
      z-index: 9;
      transform: translate(0%, 0%);

    }

    &--item1 {
      top: 0;
      left: 0;
      margin-top: 10%;
      margin-left: 0;
      z-index: 9;
      transform: translate(50%, 0%);

    }

    &--item2 {
      top: 0;
      left: 0;
      margin-top: 23%;
      margin-left: 3%;
      z-index: 9;
      transform: translate(50%, 0%);

    }

    &--item3 {
      top: 0;
      left: 0;
      margin-top: 34%;
      margin-left: 7%;
      z-index: 9;
      transform: translate(50%, 0%);

    }
    &--line {
      bottom: 0;
      left: 0;
      margin-left: 30%;
      margin-bottom: 5%;
      z-index: 1;
      transform: translate(0%, 0%);

    }

    &--avatar {
      top: 0;
      left: 0;
      margin-top: -9%;
      z-index: 9;
      transform: translate(0%, -50%);

    }

    &--badge {
      top: 0;
      left: 0;
      margin-top: -8%;
      z-index: 9;
      transform: translate(0%, -50%);

    }
  }
  //smarttask--section
  .smarttask {

    &--avatar {
      margin-left: 50px;
    }
    &--label-avatar {
      margin-top: -30px;

    }
    &--item1 {
      top: 0;
      left: 0;
      margin-top: 45px;
      margin-left: 20px;
    }

    &--item1b {
      top: 0;
      left: 0;
      margin-top: 45px;
      margin-left: -30px;
    }
    &--item2 {
      top: 0;
      left: 0;
      margin-left: 20px;
      margin-top: 105px;
    }
    &--item3 {
      top: 0;
      left: 0;
      margin-left: 20px;
      margin-top: 165px;
    }
    &--rect2 {

    }

    &--marge-avatar {
      margin-top: -40px;
      margin-left: 80px;
      transform: scale(.5) !important;
      transform-origin: bottom;
    }
    &--marge-label {
      margin-left: 150px;
      margin-top: -25px;
    }

    &--line {
      left: 0;
      top: 0;
      margin-top: 40px;
      margin-left: 110px;
      z-index: -1;
    }
    &--bg {
      top: 0;
      left: 0;
      z-index: -1;
    }

  }
  //workflow--section

  .workflow {
    &--section {
      &--bg {
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
    }
  }

  .scaling {
    &--rect-users {
      transform: translate(-50%, 0%);

    }
    &--item-score {
      top: 0;
      left: 0;
      right: 0;
      margin-left: 34%;
      margin-top: -3%;
      transform: translate(-50%, 0%);

    }
    &--rect-items {
      transform: translate(50%, 0%);
    }
    &--items-sprite {
      top: 0;
      right: 0;
      width: 100%;
      margin-top: -25%;
      margin-left: auto;
      margin-right: -28%;

    }
    &--item1 {
      top: 0;
      left: 0;
      margin-top: 10%;
      margin-left: 3%;
      transform: translate(50%, 0%);

    }
    &--item2 {
      top: 0;
      left: 0;
      margin-top: 21%;
      margin-left: 3%;
      transform: translate(50%, 0%);

    }
    &--item3 {
      top: 0;
      left: 0;
      margin-top: 32%;
      margin-left: 3%;
      transform: translate(50%, 0%);

    }
    &--avatar {
      top: 0;
      left: 0;
      margin-left: 60%;
      margin-top: 1%;
      transform: translate(0%, -50%);

    }
    &--label-csm {
      margin-left: 55%;
      margin-top: -7%;
      z-index: 3;
      transform: translate(0%, -50%);

    }
    &--line {
      bottom: 0;
      left: 0;
      margin-left: 45%;
      z-index: 2;
      transform: translate(0%, 0%);

    }
    &--bg {
      top: 0;
      left: 0;
      z-index: 1;
    }


  }
  //anim1
  .anim1 {
    *,
    *::before,
    *::after {
      box-sizing: content-box;
    }
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .content-wrapper {
      width: 100%;
      height: 100%;
    }

    .workflow--avatar-panel {
      position: relative;
      margin-top: 3rem;
    }

    .workflow--line {
      position: relative;
      left: -8.5%;
      padding-top: 15%;
      transform-origin: center;

    }
    @keyframes workflow--line-anim {
      to {
        transform: translateY(100%);
      }
    }
    .workflow--panel {
      position: absolute;
      left: 0;
      top: 0;
      gap: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      justify-content: center;
      width: 100%;
      transform: rotate(-5deg);
    }

    .workflow--avatar-grey:nth-child(1) {
      transform: translateX(70%);
    }

    @keyframes workflow--avatar-grey-1-anim {
      100% {
        transform: translateX(35%);
      }
    }

    .workflow--avatar-grey:nth-child(2) {
      transform: translateX(35%);
    }

    @keyframes workflow--avatar-grey-2-anim {
      100% {
        transform: translateX(0);
      }
    }

    .workflow--avatar-grey:nth-child(4) {
      transform: translateX(-35%);
    }

    @keyframes workflow--avatar-grey-4-anim {
      100% {
        transform: translateX(0);
      }
    }

    .workflow--avatar-grey:nth-child(5) {
      transform: translateX(-70%);
    }

    @keyframes workflow--avatar-grey-5-anim {
      100% {
        transform: translateX(-35%);
      }
    }

    .workflow--avatar-grey:nth-child(6) {
      transform: translateX(-105%);
    }

    @keyframes workflow--avatar-grey-6-anim {
      100% {
        transform: translateX(-75%);
      }
    }

    .workflow--avatar-grey:nth-child(3) {
      opacity: 0;
    }

    .workflow--avatar1 {
      transform: scale(.4) translateX(-50%);
      position: absolute;
      left: 34%;
      top: -20px;
      z-index: 2;
    }

    @keyframes workflow--avatar1-anim {
      100% {
        transform: scale(1) translate(-20%, -10%);
      }
    }

    .workflow--avatar2 {
      transform: scale(.5) translateX(-50%);
      position: absolute;
      left: 36%;
      bottom: -5%;
      z-index: 3;
    }

    @keyframes workflow--avatar2-anim {
      100% {
        transform: scale(1) translate(-25%, -10%);
      }
    }

    .workflow--badge1 {
      position: absolute;
      left: 29%;
      top: 20%;
      z-index: 2;
    }

    .workflow--badge2 {
      position: absolute;
      left: 29%;
      top: 20%;
      z-index: 2;
      opacity: 0;
    }

    @keyframes workflow--badge2-anim {
      100% {
        opacity: 1;
      }
    }

    .workflow--cms-badge {
      position: absolute;
      left: 27%;
      bottom: -3%;
      z-index: 4;
      opacity: 0;
    }

    @keyframes workflow--cms-badge-anim {
      100% {
        opacity: 1;
      }
    }

    .workflow--slack1-badge {
      position: absolute;
      left: 29%;
      top: 30%;
      z-index: 2;
    }

    @keyframes workflow--slack1-anim {
      100% {
        transform: translateY(-10%);
        opacity: 0;
      }
    }

    .workflow--slack2-badge {
      position: absolute;
      left: 26%;
      top: 28%;
      z-index: 2;
      opacity: 0;
    }

    @keyframes workflow--slack2-anim {
      100% {
        opacity: 1;
      }
    }

    .workflow--mail1 {
      position: absolute;
      left: 34.2%;
      transform: translateX(-29%);
      top: 39%;
      z-index: 2;
    }

    .workflow--mail2 {
      position: absolute;
      left: 34.2%;
      transform: translateX(-29%);
      top: 39%;
      z-index: 2;
      opacity: 0;
    }

    @keyframes workflow--mail2-anim {
      100% {
        opacity: 1;
      }
    }

    .workflow--chart1 {
      position: absolute;
      left: 18%;
      top: 55%;
      z-index: 2;
    }

    .workflow--chart2 {
      position: absolute;
      left: 18%;
      top: 55%;
      z-index: 2;
      opacity: 0;
    }

    @keyframes workflow--chart2-anim {
      100% {
        opacity: 1;
      }
    }

    .workflow--bell {
      position: absolute;
      top: 2%;
      left: 45%;
      opacity: 0;
      transform: scale(.5);
    }

    @keyframes workflow--bell-anim {
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }

    @keyframes animate-svg-stroke-4 {
      0% {
        stroke-dashoffset: 474.5679931640625px;
        stroke-dasharray: 474.5679931640625px;
      }

      100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 474.5679931640625px;
      }
    }
    @keyframes animate-svg-stroke-5 {
      0% {
        width: 0;
      }
      100% {
        width: 60%;
      }
    }
    @keyframes animate-svg-stroke-6 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes animate-svg-stroke-7 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes animate-svg-stroke-8 {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
    @keyframes animate-svg-stroke-9 {
      0% {
        stroke-dashoffset: 243.71160888671875px;
        stroke-dasharray: 243.71160888671875px;
      }

      100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 243.71160888671875px;
      }
    }
    .workflow--chart-6 {
      opacity: 0;
    }
    .workflow--chart-7 {
      opacity: 0;
    }
    .workflow--chart-8 {
      opacity: 0;
    }
    .workflow--chart-9 {
      stroke-dashoffset: 243.71160888671875px;
      stroke-dasharray: 243.71160888671875px;
    }
  }
  //anim2
  .anim2 {
    *,
    *::before,
    *::after {
      box-sizing: content-box;
    }
    width: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .content-wrapper {
      width: 100%;
      height: 100%;
    }

    .main-panel {
      position: absolute;
      top: 30%;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
    }


    .main-panel-bg-second {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transform: translateX(-5%);
    }

    @keyframes smarttask--main-panel-bg-anim {
      100% {
        opacity: 0;
        transform: translateX(15%);
      }
    }

    @keyframes smarttask--main-panel-bg-second-anim {
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .smarttask--dotted-line {
      position: relative;
      display: block;
      margin-top: 25%;
      left: 15%;
    }
    @keyframes smarttask--dotted-line-anim {
      100% {
        opacity: 1;
      }
    }
    .first-avatar-section {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    .second-avatar-section {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }

    .avatar-1 {
      position: absolute;
      left: 7%;
      top: 0;
      transform: scale(1) translate(0, 0);
    }

    @keyframes smarttask--avatar-1-anim {
      100% {
        transform: scale(.6) translate(0, 30%);
      }
    }

    .badge-1 {
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 18%;
    }

    .avatar-2 {
      position: absolute;
      left: 32%;
      bottom: -2%;
      transform: scale(.6);
      z-index: 3;
    }
    @keyframes smarttask--avatar-2-show {
      100% {
        opacity: 1;
      }
    }

    @keyframes smarttask--avatar-2-anim {
      100% {
        transform: scale(1) translate(0, -17%);
      }
    }

    .badge-2 {
      position: absolute;
      left: 25%;
      bottom: -4%;
      z-index: 4;
    }

    .first-panel {
      position: absolute;
      top: 7%;
      right: 7%;
    }

    .first-panel:nth-child(2) {
      top: 17%;
    }

    .first-panel:nth-child(3) {
      top: 27%;
    }

    .second-panel {
      position: absolute;
      top: 7%;
      right: 7%;
      transform: translateX(0);
    }

    .second-panel:nth-child(2) {
      top: 17%;
    }

    .second-panel:nth-child(3) {
      top: 27%;
    }



    @keyframes smarttask--second-panel-anim {
      100% {
        opacity: 1;
        transform: translateX(-7%);
      }
    }
    @keyframes smarttask--first-panel-anim {
      100% {
        opacity: 0;
      }
    }
  }
  //anim3
  .anim3 {
    *,
    *::before,
    *::after {
      box-sizing: content-box;
    }

    text-align: left;
    width: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    h4 {
      font-weight: 600;
      font-size: 2rem;
      margin: 0;
    }


    .panels {
      position: relative;
      background-color: #fff;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
      border-radius: 25px;
      top: 0;
      left: 0;
      padding: 2rem;
      width: 100%;
      min-height: 350px;
    }

    .panel {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 20%;
    }

    .panel:nth-child(2) {
      top: 40%;
    }

    .panel:nth-child(3) {
      top: 60%;
    }

    .second-panel {
      position: absolute;
      right: -5%;
      top: 20%;
      opacity: 0;
    }

    .second-panel:nth-child(2) {
      top: 40%;
      opacity: 0;
    }

    .second-panel:nth-child(3) {
      top: 60%;
      opacity: 0;
    }

    .avatar-panel {
      position: relative;
      left: -20%;
    }

    .dotted-line {
      position: relative;
      left: 30%;
      top: 0;
    }

    .dotted-line-circle {
      position: absolute;
      left: calc(30% - 15px);
      top: 0;
    }

    .avatar {
      position: absolute;
      left: calc(30% + 50px);
      bottom: 0;
      transform: translate(-20%, 25%) scale(0.5);
    }

    .realtime--label {
      position: absolute;
      left: calc(30% + 25px);
      bottom: -40%;
      opacity: 0;
    }

    @keyframes realtime--panel-anim {
      100% {
        opacity: 0;
      }
    }
    @keyframes realtime--second-panel-anim {
      0% {
        transform: translateX(-10%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
    @keyframes realtime--circle {
      25% {
        transform: translate(0, 100%);
      }
      50% {
        transform: translate(50%, 160%);
      }
      100% {
        transform: translate(80%, 160%);
      }
    }
    @keyframes realtime--avatar-anim {
      100% {
        transform: translate(-10%, 20%) scale(1);
      }
    }
    @keyframes realtime--labelbadge {
      100% {
        opacity: 1;
      }
    }
    @keyframes realtime--panels-anim {
      0% {
        width: 100%;
      }
      100% {
        background-color: #006CBB;
        width: 80%;
        color: #fff;
        font-weight: 700;
      }
    }
  }
  //anim4
  .anim4 {
    *,
    *::before,
    *::after {
      box-sizing: content-box;
    }
    width: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .content-wrapper {
      width: 100%;
      height: 100%;
    }

    .first-section {
      position: relative;
    }

    .gray-panel {
      display: block;
      position: relative;
    }

    .small-content-panel {
      position: absolute;
      left: 5%;
      top: -2%;
      z-index: 3;
    }

    @keyframes scaling--small-content-panel-anim {
      50% {
        transform: translate(0, 25%);
      }
      100% {
        transform: translate(60%, 25%);
      }
    }

    .total-users {
      position: absolute;
      left: 7%;
      top: 15%;
      opacity: 0;
    }

    @keyframes scaling--total-users-anim {
      100% {
        opacity: 1;
      }
    }

    .avatar-group-1 {
      position: absolute;
      top: 35%;
      left: 9.5%;
    }

    @keyframes scaling--avatar-group-1-anim {
      100% {
        transform: translateX(7%);
      }
    }

    .avatar-group-2 {
      position: absolute;
      top: 50%;
      left: 9.5%;
    }

    @keyframes avatar-group-2-anim {
      100% {
        transform: translateX(-4%);
      }
    }

    .avatar-group-3 {
      position: absolute;
      top: 65%;
      left: 9.5%;
    }

    @keyframes scaling--avatar-group-3-anim {
      100% {
        transform: translateX(9%);
      }
    }

    .content-panel-section {
      position: relative;
      width: 50%;
      height: auto;
      max-height: 75%;
      top: -12%;
      left: 20%;
    }

    .content-panel-1 {
      position: relative;
      z-index: 4;
      opacity: 1;
    }

    @keyframes scaling--content-panel-1-anim {
      100% {
        opacity: 0;
      }
    }

    .content-panel-2 {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 5;
    }

    @keyframes scaling--content-panel-2-anim {
      100% {
        opacity: 1;
      }
    }

    .dotted-line {
      position: relative;
      left: 40%;
      top: -1rem;
      display: block;
    }

    .avatar {
      position: absolute;
      left: 50%;
      bottom: -10%;
      transform: scale(.5) translateY(25%);
      z-index: 5;
    }

    @keyframes scaling--avatar-anim {
      100% {
        transform: scale(1.1) translateY(-25%);
      }
    }

    .badge_avatar {
      position: absolute;
      left: 50%;
      bottom: 0;
      opacity: 0;
      z-index: 6;
    }

    @keyframes scaling--badge-anim {
      100% {
        opacity: 1;
      }
    }

    .small-panel-1 {
      position: absolute;
      top: 13%;
      left: 2%;
      z-index: 5;
      opacity: 0;
      transform: translateX(-5%);
    }

    @keyframes scaling--small-panel-1-anim {
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .small-panel-2 {
      position: absolute;
      top: 27.5%;
      left: 2%;
      z-index: 5;
      opacity: 0;
      transform: translateX(-5%);
    }

    @keyframes scaling--small-panel-2-anim {
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .small-panel-3 {
      position: absolute;
      top: 42%;
      left: 2%;
      z-index: 5;
      opacity: 0;
      transform: translateX(-5%);
    }

    @keyframes scaling--small-panel-3-anim {
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  //integrate-everything--section--sprites
  .integrate-everything--section {
    &--sprites {
      position: relative;
      min-height: 500px;
      text-align: center;
    }

    @keyframes moveIntercom {
      0% {
        margin-top: 33%;
        margin-left: -68%;
        transform: scale(1);
      }
      50% {
        margin-top: 52%;
        margin-left: -44%;
        transform: scale(.7);
      }
      100% {
        margin-top: 33%;
        margin-left: -68%;
        transform: scale(1);
      }
    }
    &--intercom {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 33%;
      margin-left: -68%;
      //animation-delay: 2s !important;
      transform: translate(0%, -80%) scale(1);

    }
    @keyframes moveSalesforce {
      0% {
        margin-top: 13%;
        margin-right: -74%;
      }
      50% {
        margin-top: 38%;
        margin-right: -40%;
        transform: scale(.7);
      }
      100% {
        margin-top: 13%;
        margin-right: -74%;
      }
    }
    &--salesforce {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 13%;
      margin-right: -74%;
      transform: translate(0%, -80%);

    }
    @keyframes moveHubspot {
      0% {
        margin-top: 15%;
        margin-left: -40%;
      }
      50% {
        margin-top: 40%;
        margin-left: -38%;
        transform: scale(.75);
      }
      100% {
        margin-top: 15%;
        margin-left: -40%;
      }
    }
    &--hubspot {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 15%;
      margin-left: -40%;

    }
    @keyframes moveLogo {
      0% {
        margin-top: 4%;
        margin-left: -6%;
      }
      50% {
        margin-top: 31%;
        margin-left: -11%;
        transform: scale(.8);
      }
      100% {
        margin-top: 4%;
        margin-left: -6%;
      }
    }
    &--logo {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 4%;
      margin-left: -6%;

    }
    @keyframes moveRect {
      0% {
        margin-top: 2%;
        margin-right: -35%;
      }
      50% {
        margin-top: 27%;
        margin-right: -20%;
        transform: scale(.7);
      }
      100% {
        margin-top: 2%;
        margin-right: -35%;
      }
    }
    &--react {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 2%;
      margin-right: -35%;

    }

    &--cube {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 40%;
      margin-right: 0%;
      transform: translate(0%, 80%);
    }
    @keyframes moveCube2 {
      0% {
        transform: rotate(-90deg) scale(0.7);
      }
      100% {
        transform: rotate(0deg) scale(1);
      }
    }
    &--cube2 {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 10%;
      margin-right: 0%;
      transform: translate(0%, 80%) rotate(-90deg) scale(0.7);

    }
    &--bg {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 14%;
      margin-right: -44%;
      z-index: -1;
    }

  }
  ///achieve-tab1
  .achieve--bg {
    top: 0;
    left: 0;
    z-index: -1;
  }
  .achieve-tab1 {

    @keyframes achieve-tab1--toShow-anim {
      100% {
        opacity: 1;
      }
    }
    @keyframes achieve-tab1--toHide-anim {
      100% {
        opacity: 0;
      }
    }
    @keyframes achieve-tab1--cursor-anim {
      0% {
        margin-right: 53%;
        margin-top: 55%;
      }
      50% {
        margin-right: 53%;
        margin-top: 55%;
      }
      100% {
        margin-right: 0;
        margin-top: 38%;
      }
    }

    &--sprite {
      min-height: 467px;
      min-width: 467px;
    }

    &--rect1 {
      bottom: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
    }

    &--rect2 {
      bottom: 0;
      left: 0;
      z-index: 1;
      opacity: 1;
    }
    &--cursor {
      transform: translate(50%, 0%);
      top: 0;
      right: 0;
      margin-right: 53%;
      margin-top: 55%;
      z-index: 10;
    }

    &--before {
      bottom: 0;
      left: 0;
      z-index: 9;
      margin-bottom: 180px;
      margin-left: 20px;
      transform: translate(-50%, 0%);
    }
    &--after {
      top: 0;
      right: 0;
      z-index: 9;
      margin-top: 80px;
      margin-right: 20px;
      transform: translate(50%, 0%);

    }
  }
  //achieve-tab2
  .achieve-tab2 {
    &--avatar1 {
      top: 0;
      left: 0;
      margin-left: 3%;
      margin-top: 15%;
      transform: translate(0%, -50%);

    }
    &--label1 {
      top: 0;
      left: 0;
      margin-left: -8%;
      margin-top: 25%;
      transform: translate(0%, -50%);

    }
    &--avatar2 {
      top: 0;
      left: 0;
      margin-left: 23%;
      margin-top: -2%;
      transform: translate(0%, -50%);

    }

    &--label2 {
      top: 0;
      left: 0;
      margin-top: 8%;
      margin-left: 12%;
      transform: translate(0%, -50%);

    }
    &--avatar3 {
      top: 0;
      right: 0;
      margin-top: 5%;
      margin-right: 20%;
      transform: translate(0%, -50%);

    }

    &--label3 {
      top: 0;
      right: 0;
      margin-top: 14%;
      margin-right: 5%;
      transform: translate(0%, -50%);

    }
    &--line1 {
      top: 0;
      left: 0;
      margin-top: 30%;
      margin-left: 9%;
      z-index: -1;
      transform: translate(0%, 0%);

    }
    &--line2 {
      top: 0;
      left: 0;
      margin-top: 12%;
      margin-left: 30%;
      z-index: -1;
      transform: translate(0%, 0%);

    }
    &--line3 {
      top: 0;
      left: 0;
      margin-top: 20%;
      margin-left: 6%;
      z-index: -1;
      transform: translate(0%, 0%);

    }
    &--rect {
      transform: translate(0%, 80%);
      margin-top: -2%;
    }

  }
  //achieve-tab3
  .achieve-tab3 {
    &--avatar1 {
      left: 0;
      margin-left: -50%;
      transform: translate(-50%, 0%);
    }
    &--label1 {
      left: 0;
      margin-left: -50%;
      margin-top: -6%;
      transform: translate(-50%, 0%);
    }
    &--avatar2 {
      left: 0;
      margin-top: -10%;
      margin-left: 50%;
      transform: translate(50%, 0%);
    }
    &--label2 {
      left: 0;
      margin-top: -8%;
      margin-left: 53%;
      transform: translate(50%, 0%);
    }
    &--notes {
      right: 0;
      margin-right: -15%;
      margin-top: -10%;
      transform: translate(0%, 50%);
    }
    &--tasks {
      margin-top: -2%;
      transform: translate(0%, 50%);
    }
    &--line {
      top: 0;
      left: 0;
      margin-left: 30%;
      margin-top: 10%;
      z-index: -3;
      transform: translate(0%, 0%);
    }
  }

  // scale-tailor
  @keyframes scale-tailor--company2-anim {
    100% {
      opacity: 0;
    }
  }
  @keyframes scale-tailor--company-anim {
    100% {
      opacity: 1;
    }
  }
  @keyframes scale-tailor--label2 {
    0% {
      margin-top: 28%;
      margin-left: 33%;
    }
    100% {
      margin-top: 32%;
      margin-left: 55%;
      opacity: 1;
    }
  }
  @keyframes scale-tailor--label {
    0% {
      margin-top: 28%;
      margin-left: 33%;
    }
    100% {
      margin-top: 32%;
      margin-left: 55%;
      opacity: 0;
    }
  }
  .scale-tailor {
    &--company-sprite {
      min-height: 352px;

    }
    &--company2 {
      top: 0;
      left: 0;
      opacity: 1;
      z-index: -2;

    }
    &--company {
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;

    }
    &--cursor {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 14%;
      margin-left: 33%;

      @keyframes scale-tailor--moveCursor {
        0% {
          margin-left: 33%;
        }
        100% {
          margin-top: 16%;
          margin-left: 48%;
        }
      }
      z-index: 1;
      transform: translate(80%, 80%);




    }
    &--avatar {
      @keyframes scale-tailor--moveAvatar {
        0% {
          margin-top: 15%;
          margin-left: 33%;
          transform: scale(.5);

        }
        100% {
          margin-top: 18%;
          margin-left: 55%;
          transform: scale(1);
        }
      }
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 15%;
      margin-left: 33%;
    }
    &--label {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 28%;
      margin-left: 33%;
    }
    &--label2 {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      margin-top: 28%;
      margin-left: 33%;
    }
  }

  //animations
  .content-animation,
  .workflow--section-animation,
  .smarttask--section-animation,
  .scale-tailor-animation,
  .realtime-score--section-animation,
  .scaling--section-animation {
    &.has-animation {
      //integrate-everything--section--sprites
      .integrate-everything--section {
        &--intercom {
          animation: animRunSlide .6s 3s forwards,
          moveIntercom 5s 5s ease-in-out infinite;
        }

        &--salesforce {
          animation: animRunSlide .6s 3s forwards,
          moveSalesforce 5s 5s ease-in-out infinite;
        }

        &--hubspot {
          animation: animRunSlide .6s 3s forwards,
          moveHubspot 5s 5s ease-in-out infinite;
        }

        &--logo {

          animation: animRunSlide .6s 3s forwards,
          moveLogo 5s 5s ease-in-out infinite;
        }

        &--react {
          animation: animRunSlide .6s 3s forwards,
          moveRect 5s 5s ease-in-out infinite;
        }

        &--cube {
          animation: animRunSlide 0.6s 1s forwards !important;
        }

        &--cube2 {
          transform: translate(0%, 80%) rotate(-90deg) scale(0.7);
          animation: animRunSlide .4s 10s forwards !important;
        }

      }

      //smarttask
      .smarttask {

        &--avatar {
          animation: animRunSlide 0.6s .4s forwards !important;
        }

        &--label-avatar {
          animation: animRunSlide 0.6s .6s forwards !important;

        }

        &--item1 {
          animation: animRunSlide 0.6s 1.4s forwards !important;
        }

        &--item1b {
          animation: animRunSlide 0.6s 2.8s forwards !important;
        }

        &--item2 {
          animation: animRunSlide 0.6s 1.8s forwards !important;
        }

        &--item3 {
          animation: animRunSlide 0.6s 2.4s forwards !important;
        }

        &--rect2 {
          animation: animRunSlide 0.6s 1.2s forwards !important;
        }

        &--marge-avatar {
          animation: animRunSlide 0.6s 3.2s forwards !important;
        }

        &--marge-label {
          animation: animRunSlide 0.6s 3.4s forwards !important;
        }

        &--line {
          animation: animRunSlide 0.6s 3.4s forwards !important;
        }

      }

      //other

      .realtime-score {


        &--rect-blue {
          animation: animRunSlide 0.4s 0.1s forwards;
        }

        &--item1 {
          animation: animRunSlide 0.4s 1s forwards;
        }

        &--item2 {
          animation: animRunSlide 0.4s 1.6s forwards;
        }

        &--item3 {
          animation: animRunSlide 0.4s 2.2s forwards;
        }

        &--line {
          animation: animRunSlide 0.4s 3s forwards;
        }

        &--avatar {
          animation: animRunSlide 0.4s 2.6s forwards;
        }

        &--badge {
          animation: animRunSlide 0.4s 3s forwards;
        }
      }

      //achieve-tab1
      .achieve-tab1 {

        &--rect2 {
          animation: animRunSlide .4s .4s forwards,
          achieve-tab1--toHide-anim 0.4s 2.5s forwards;
        }

        &--rect1 {
          animation: animRunSlide 0.4s 2.5s forwards,
          achieve-tab1--toShow-anim 0.4s 2.5s forwards;
        }

        &--cursor {
          animation: animRunSlide 0.4s 1s forwards,
          achieve-tab1--cursor-anim .8s 2.5s forwards;
        }

        &--before {
          animation: animRunSlide 0.4s 1s forwards,
          achieve-tab1--toHide-anim 0.4s 2.5s forwards;
        }

        &--after {
          animation: animRunSlide 0.4s 2.5s forwards;
        }
      }

      //achieve-tab2
      .achieve-tab2 {
        &--avatar1 {
          animation: animRunSlide .4s 1s forwards;

        }

        &--label1 {
          animation: animRunSlide .4s 1.2s forwards;

        }

        &--avatar2 {
          animation: animRunSlide .4s 1.4s forwards;

        }

        &--label2 {
          animation: animRunSlide .4s 1.6s forwards;

        }

        &--avatar3 {
          animation: animRunSlide .4s 1.8s forwards;

        }

        &--label3 {
          animation: animRunSlide .4s 2s forwards;

        }

        &--line1 {
          animation: animRunSlide .4s 1.2s forwards;

        }

        &--line2 {
          animation: animRunSlide .4s 1.6s forwards;

        }

        &--line3 {
          animation: animRunSlide .4s 2s forwards;

        }

        &--rect {
          animation: animRunSlide .4s .6s forwards;
        }

      }

      //achieve-tab3
      .achieve-tab3 {
        &--avatar1 {
          animation: animRunSlide .4s .6s forwards;
        }

        &--label1 {
          animation: animRunSlide .4s .6s forwards;
        }

        &--tasks {
          animation: animRunSlide .4s 1s forwards;
        }

        &--notes {
          animation: animRunSlide .4s 1.4s forwards;
        }

        &--avatar2 {
          animation: animRunSlide .4s 1.8s forwards;
        }

        &--label2 {
          animation: animRunSlide .4s 1.8s forwards;
        }

        &--line {
          animation: animRunSlide .4s 2.2s forwards;
        }
      }

      //anim1
      .anim1 {
        .workflow--avatar-grey:nth-child(1) {
          animation:
            animRunShow .4s .5s forwards,
            workflow--avatar-grey-1-anim .5s 2s forwards;
        }
        .workflow--avatar-grey:nth-child(2) {
          animation:
            animRunShow .4s .5s forwards,
            workflow--avatar-grey-2-anim .5s 2s forwards;
        }
        .workflow--avatar-grey:nth-child(3) {
          opacity: 0;
        }
        .workflow--avatar-grey:nth-child(4) {
          animation:
            animRunShow .4s .5s forwards,
            workflow--avatar-grey-4-anim .5s 2s forwards;
        }
        .workflow--avatar-grey:nth-child(5) {
          animation:
            animRunShow .4s .5s forwards,
            workflow--avatar-grey-5-anim .5s 2s forwards;
        }
        .workflow--avatar-grey:nth-child(6) {
          animation:
            animRunShow .4s .5s forwards,
            workflow--avatar-grey-6-anim .5s 2s forwards;
        }


        .workflow--avatar1 {
          animation:
            animRunShow .4s .5s forwards,
            workflow--avatar1-anim .5s 2s forwards;
        }

        .workflow--bell {
          animation:
            animRunShow .4s .5s forwards,
            workflow--bell-anim .5s 3s forwards;
        }
        .workflow--badge1 {
          animation:
            animRunShow .4s .5s forwards;
        }
        .workflow--badge2 {
          animation:
            animRunShow .4s 2.5s forwards,
            workflow--badge2-anim .5s 2.5s forwards;
        }

        .workflow--slack1-badge {
          animation:
            animRunShow .4s .5s forwards,
            workflow--slack1-anim .5s 3.5s forwards;
        }

        .workflow--slack2-badge {
          animation:
            animRunShow .4s 3.5s forwards,
            workflow--slack2-anim .5s 3.5s forwards;
        }

        .workflow--mail1 {
          animation:
            animRunShow .4s .5s forwards;
        }

        .workflow--mail2 {
          animation:
            animRunShow .4s 1.5s forwards,
            workflow--mail2-anim .5s 4s forwards;
        }

        .workflow--chart1 {
          animation:
            animRunShow .4s .9s forwards,
            workflow--chart2-anim .5s 4s forwards;
        }

        .workflow--chart-4 {
          animation: animate-svg-stroke-4 2s 5s linear forwards;
        }

        .workflow--chart-5 {
          animation: animate-svg-stroke-5 1s 5.4s linear forwards;
        }

        .workflow--chart-6 {
          animation: animate-svg-stroke-6 .4s 5.4s linear forwards;
        }

        .workflow--chart-7 {
          animation: animate-svg-stroke-7 .4s 5.8s linear forwards;
        }

        .workflow--chart-8 {
          animation: animate-svg-stroke-8 .4s 5.8s linear forwards;
        }

        .workflow--chart-9 {
          animation: animate-svg-stroke-9 1s 5.8s linear forwards;
        }

        .workflow--avatar2 {
          animation:
            animRunShow .4s .5s forwards,
            workflow--avatar2-anim .5s 7s forwards;
        }

        .workflow--cms-badge {
          animation:
            animRunShow .4s .5s forwards,
            workflow--cms-badge-anim .5s 7.3s forwards;
        }

        .workflow--line {
          animation:
            animRunShow .4s 1s forwards;
            #large-circle,
            #small-circle {
              animation:
                workflow--line-anim 6.5s 2s linear forwards;
            }
        }


      }

      //anim2
      .anim2 {

        .avatar-1 {
          animation:
            animRunShow .4s .5s forwards,
            smarttask--avatar-1-anim .4s 2s linear forwards;
        }
        .badge-1 {
          animation:
            animRunShow .4s .5s forwards;
        }
        .main-panel-bg {
          animation:
            animRunShow .4s .9s forwards;
        }

        .first-panel:first-child {
          animation: animRunShow .4s 2s forwards,
          smarttask--first-panel-anim .4s 2.4s linear forwards;
        }

        .second-panel:first-child {
          animation: animRunSlide .4s 2.4s forwards,
          smarttask--second-panel-anim .4s 2.8s linear forwards;
        }

        .first-panel:nth-child(2) {
          animation: animRunSlide .4s 2.8s forwards,
          smarttask--first-panel-anim .4s 3.2s linear forwards;
        }

        .second-panel:nth-child(2) {
          animation: animRunSlide .4s 3.2s forwards,
          smarttask--second-panel-anim .4s 3.6s linear forwards;
        }

        .first-panel:nth-child(3) {
          animation: animRunSlide .4s 3.6s forwards,
          smarttask--first-panel-anim .4s 4s linear forwards;
        }

        .second-panel:nth-child(3) {
          animation: animRunSlide .4s 4s forwards,
          smarttask--second-panel-anim .4s 4.8s linear forwards;
        }
        .smarttask--dotted-line {
          animation:
            animRunShow .4s 2s forwards;
        }

        .avatar-2 {
          animation:
            animRunShow .4s 1.5s forwards,
            smarttask--avatar-2-anim .4s 7s linear forwards;
        }
        .badge-2 {
          animation:
            animRunShow .4s 1.5s forwards;
        }
      }

      //anim3
      .anim3 {

        .panels {
          animation: realtime--panels-anim 1s 2s forwards;
        }

        .panel {
          animation: realtime--panel-anim .4s 2s forwards;
        }

        .second-panel {
          animation: realtime--second-panel-anim 1s 2s forwards;
        }

        .dotted-line-circle {
          animation: realtime--circle 3s 2s linear forwards;
        }

        .avatar {
          animation: realtime--avatar-anim .5s 2s forwards;
        }

        .realtime--label {
          animation: realtime--labelbadge .4s 2s forwards;
        }
      }

      //anim4
      .anim4 {
        .small-content-panel {
          animation: scaling--small-content-panel-anim 1s 1s forwards;
        }

        .total-users {
          animation: scaling--total-users-anim .5s 1s forwards;
        }

        .avatar-group-1 {
          animation: scaling--avatar-group-1-anim 1s 1s forwards;
        }

        .avatar-group-2 {
          animation: avatar-group-2-anim 1s 1s forwards;
        }

        .avatar-group-3 {
          animation: scaling--avatar-group-3-anim 1s 1s forwards;
        }

        .content-panel-1 {
          animation: scaling--content-panel-1-anim 1s 2s forwards;
        }

        .content-panel-2 {
          animation: scaling--content-panel-2-anim .5s 2s forwards;
        }

        .avatar {
          animation: scaling--avatar-anim .5s 2s forwards;
        }

        .badge_avatar {
          animation: scaling--badge-anim .5s 3s forwards;
        }

        .small-panel-1 {
          animation: scaling--small-panel-1-anim .5s 2.5s forwards;
        }

        .small-panel-2 {
          animation: scaling--small-panel-2-anim .5s 2.5s forwards;
        }

        .small-panel-3 {
          animation: scaling--small-panel-3-anim .5s 2.5s forwards;
        }
      }

      //
      .scale-tailor {
        &--company2 {
          animation: animRunSlide .4s .5s forwards,
          scale-tailor--company2-anim .4s 3s forwards;
        }
        &--company {
          animation:
            animRunSlide .4s 2s forwards,
            scale-tailor--company-anim 1s 2s forwards;
        }
        &--cursor {
          animation: animRunSlide .4s 1s forwards,
          scale-tailor--moveCursor .4s 2.5s forwards;
        }
      }

      .scaling {
        &--rect-users {
          animation: animRunSlide .4s .6s linear forwards;
        }
        &--item-score {
          animation: animRunSlide .4s 1s linear forwards;
        }
        &--rect-items {
          animation: animRunSlide .4s 1.4s linear forwards;
        }
        &--item1 {
          animation: animRunSlide .4s 1.8s linear forwards;
        }
        &--item2 {
          animation: animRunSlide .4s 2.2s linear forwards;
        }
        &--item3 {
          animation: animRunSlide .4s 2.6s linear forwards;
        }
        &--avatar {
          animation: animRunSlide .4s 3s linear forwards;
        }
        &--label-csm {
          animation: animRunSlide .4s 3.4s linear forwards;
        }
        &--line {
          animation: animRunSlide .4s 3.4s linear forwards;
        }
      }

    }
  }
}
