
// Type
// ======================

$h1-line-height: 1.18519;

// Base
// ----------------------

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.display-1 {
  @extend %font-secondary;

  font-weight: $font-weight-semibold;
  margin-bottom: $headings-margin-bottom;
  color: $headings-color;
}

h1, .h1 {
  font-size: $h1-font-size--mob;
  line-height: $h1-line-height;

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size;
  }
}

h2, .h2 {
  font-size: $h2-font-size--mob;
  line-height: $h2-line-height;

  @include media-breakpoint-up(md) {
    font-size: $h2-font-size;
  }
}

h3, .h3 {
  font-size: $h3-font-size--mob;
  line-height: $h3-line-height;

  @include media-breakpoint-up(md) {
    font-size: $h3-font-size;
  }
}

h4, .h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5, .h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

h6, .h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}

.display-1 {
  line-height: $display1-line-height;
  font-size: $display1-size--mob;

  @include media-breakpoint-up(md) {
    font-size: $display1-size;
  }
}

strong {
  @extend %font-secondary;

  font-weight: $font-weight-bold;
}

.lead {
  @extend %font-size-xxl;

  line-height: $lead-font-size;
  font-weight: $font-weight-light;
}

//
// Emphasis
//

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

%font-size-xxs {
  font-size: (11/16 + rem);
}

%font-size-xs {
  font-size: (12/16 + rem);
}

%font-size-xsm {
  font-size: (13/16 + rem);
}

%font-size-sm {
  font-size: (14/16 + rem);
}

%font-size-md {
  font-size: (15/16 + rem);
}

%font-size-xl {
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    font-size: (18/16 + rem);
  }
}

%font-size-xxl {
  font-size: (20/16 + rem);
  line-height: 30px;
}


@mixin text-white-opacity($percent) {
  $opacity: $percent / 100;
  .text-white-#{$percent} {
    color: rgba($white, $opacity) !important;
  }
}

@for $i from 1 through 10 {
  @include text-white-opacity($i * (100 / 10));
}
