// Pricing
// ======================

// Vars
// ----------------------

$pricing-light-blue: #F7F9FA;
$pricing-white: #fff;
$background-gray: #F7F7F7;
$pricing-card-border: 2px solid #E6E6E6;
$faq-vspacing: 120px 0;
$faq-vspacing--mobile: 40px 0;

// Base
// ----------------------

.pricing {

  .hero {
    padding: 100px 0 60px;
    margin: 0 0 60px;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 180px 0 60px;
      margin: 0 0 $front-vspacing;
    }
  }
  .main {
    padding-top: 100px;
  }
  .app-features-row {
    margin-bottom: 130px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 60px;
    }
  }
  .page-header {
    @include media-breakpoint-up(lg) {
      margin-bottom: 86px;
    }

    h1 {
      br {
        @include media-breakpoint-up(sm) {
          display: none;
        }
      }
    }
  }
  .get-started {
    padding-top:0;
    @include media-breakpoint-up(md) {
      margin-top: -50px;
    }
    background: #F7F7F7;
    .section-inner {
      border-bottom-left-radius: 80px;
      padding-top: 80px;
      padding-bottom: 80px;
      @include media-breakpoint-up(md) {
        padding-top: 130px;
        padding-bottom: 130px;
      }
    }
    h2 {
      @include px-rem('margin-bottom', 40);

      @include media-breakpoint-up(lg) {
        @include px-rem('margin-bottom', 60);
      }
    }
    .bg-img-right {
      top: 120px;
      right: 0;
    }
    .bg-img-left {
      bottom: -80px;
      left: 230px;
    }
    .bg-img-topLeft {
      position: absolute;
      top: 110px;
      left: 122px;
    }
  }
  .community {
    margin: 0;
    position: relative;
    z-index: 9;
    text-align: center;
  }
  #request-form {
    h4 {
      margin: 0 0 30px;
    }
  }
  .video-embeded {
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
    border-radius: 4px;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin-left: -70px;
      margin-top: -40px;
    }
  }
  .form-section {
    margin-bottom: 80px;
    .response {
      font-size: 14px;
      color: red;
    }
  }
  @extend .vue-tel-input;

  // More features
  .more-challenge--row  {
    padding-top: 30px;
    padding-bottom: 30px;
    .more-box {
      transition: all 0.4s;
      height: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 24px 12px;
      a {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 24px 12px;
        &:hover {
          text-decoration: none;
        }
      }
      //&:hover {
      //  transform: translateY(-5px);
      //  .icon-svg {
      //    circle {
      //      @extend .icon-svg--circle-fill-hover;
      //    }
      //
      //    path,
      //    rect {
      //      @extend .icon-svg--item-fill-hover;
      //    }
      //  }
      //}
    }
    .icon-svg {
      width: 48px;
      height: 48px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
    .more-text {
      flex: 1;
      padding-left: 16px;

      p {
        color: $blue-900;
        overflow: hidden;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
      }
      .badge {
        position: relative;
        top: -2px;
        margin-left: 8px;
      }
    }
  }

  .content-section {
    margin: 0 0 $vspacing--mob;

    @include media-breakpoint-up(lg) {
      margin: 0 0 $vspacing;
    }
  }
}

// Page header
//

.pricing-top {
  background: $pricing-white;

  &.content-section {
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 90px;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 95px;
  }

  &-info {
    @extend %font-size-xsm;

    text-transform: uppercase;
    line-height: (20/13);
  }
}

.pricing-slider {
  @include media-breakpoint-down(sm) {
    padding-right: 20px;
    padding-left: 20px;
  }

  &.stuck {
    @include media-breakpoint-down(sm) {
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 10px 45px;
      z-index: 2000;
      box-shadow: 0 4px 10px -3px rgba(42, 43, 60, 0.06);
      will-change: transform;
      transition: transform 200ms linear;
    }

    &.is-bottom {
      display: none;
    }

    .pricing-top-info {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &.scroll--up {
      @include media-breakpoint-down(sm) {
        transform: translateY(70px);
      }
    }
  }

  .slider-component {
    margin: 45px auto 27px;

    @include media-breakpoint-up(md) {
      margin: 75px auto 27px;
      max-width: 770px;
      width: 100%;
    }
  }
}

// Pricing table
//

.pricing-table {
  .card-group {
    margin: 0 0 40px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .card {
      &:first-of-type {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-of-type {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  .card {
    background: $background-gray;
    border: $pricing-card-border !important;
    padding: 32px;
    box-shadow: none;

    @include media-breakpoint-down(sm) {
      border-radius: 0;
      margin: 0;
    }

    &.-popular {
      border: 2px solid $blue !important;
      background: $white;

      @include media-breakpoint-up(md) {
        margin-top: -20px;
        margin-right: -2px;
        margin-left: -2px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        z-index: 1;
      }

      .card-header {
        @include media-breakpoint-up(md) {
          padding-top: 20px;
        }
      }
    }

    &-header,
    &-body,
    &-footer {
      background: none;
      padding: 0;
      border: 0;
    }

    &-header {
      text-align: center;

      .pricing-type-label {
        @include px-rem('margin-bottom', 12);

        display: block;
        font-size: (22/16 + rem);
        font-weight: $font-weight-medium;
        text-transform: uppercase;
      }

      h3 {
        @include px-rem('margin-bottom', 24);

        @include media-breakpoint-up(lg) {
          @include px-rem('margin-bottom', 30);
        }

        &,
        sup {
          @extend %font-secondary;
          @extend %font-size-xxl;

          line-height: 36px;
          font-weight: $font-weight-semibold;
        }

        sup {
          top: -1.4rem;
        }

        .price-value {
          font-size: (50/16 + rem);
          line-height: 1;
        }
      }

      p {
        @extend %font-size-sm;
        @include px-rem('margin-bottom', 20);

        @include media-breakpoint-up(lg) {
          @include px-rem('margin-bottom', 30);
        }

        color: #646F79;
        line-height: 20px;
      }
    }

    &-footer {
      @include media-breakpoint-up(md) {
        @include px-rem('margin-bottom', 30);
      }

      padding: 0;
    }

    &-body {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      h5 {
        @include px-rem('margin-bottom', 20);
        @extend %font-size-md;

        line-height: 24px;
        text-transform: uppercase;
        color: $light-gray-050;
      }
    }
  }

  .badge-primary {
    background: $white;
    border-style: solid;
    border-radius: 12px;
    border-width: 2px;
    border-color: $blue;
    color: $blue;
    font-size: (14/16 + rem);
    line-height: 30px;
    text-transform: uppercase;
    position: absolute;
    top: -12px;
    left: 50%;
    padding: 0;
    height: 24px;
    width: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    margin: 0;
  }

  .learn-more {
    display: block;
    cursor: pointer;
    color: $blue;
    padding-left: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: $font-weight-medium;
  }

  .pricing-info {
    font-size: (13/16 + rem);

    &,
    a {
      color: $light-gray-400;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.pricing-money-back {
  display: table;
  margin: 0 auto;

  &--inner {
    display: flex;
    flex-direction: row;
    height: 36px;
    background: rgba(#78CB38, 0.1);
    border-radius: 18px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 25px;
    white-space: nowrap;
  }

  strong {
    color: #78CB38;
    font-size: 14px;
    font-weight: bold;
  }

  .icon {
    margin-right: 12px;
  }
}

// Pricing table list
//

.page-plans {
  .container {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    .section-header {
      @include media-breakpoint-down(sm) {
        padding-right: 30px;
        padding-left: 30px;
      }
    }
  }

  .table-responsive {
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid #E5E5E5;
    }

    @include media-breakpoint-up(lg) {
      @include px-rem('margin-bottom', 80);
    }
  }

  .table {
    @include media-breakpoint-down(sm) {
      table-layout: fixed;
    }

    td,
    th {
      color: $color-base;
      text-align: center;
      font-size: 1rem;
    }

    .table-body {
      td {
        @include px-rem('padding-top', 18);
        @include px-rem('padding-bottom', 17);
        @include px-rem('font-size', 10);

        border: 0;
        line-height: 20px;

        @include media-breakpoint-up(lg) {
          width: 25%;
        }

        &:first-child {
          @include px-rem('font-size', 12);
          text-align: left;
        }

        &,
        &:first-child {
          @include media-breakpoint-up(lg) {
            font-size: 1rem;
          }
        }

      }
    }

    &.table-striped tbody tr:nth-of-type(odd) {
      background-color: transparent;
    }

    &.table-striped tbody tr:nth-of-type(even) {
      background-color: #FAFAFA;
    }

    .tr-empty {
      @include media-breakpoint-down(sm) {
        display: none;
      }

      &,
      td {
        background: transparent !important;
        border: 0;
      }
    }
  }

  .table-head {
    th {
      border: 0;
      border-bottom: 2px solid #E5E5E5;
      padding: 0 0 16px;
      text-transform: uppercase;
      font-weight: $font-weight-semibold;

      @include media-breakpoint-down(sm) {
        @include px-rem('font-size', 9);
      }

      &:first-child {
        font-size: 14px;
        line-height: 21px;
        padding-left: 16px;

        @include media-breakpoint-down(sm) {
          width: 170px;
        }

        @include media-breakpoint-up(lg) {
          @include px-rem('font-size', 20);
        }

        text-align: left;
        text-transform: capitalize;
      }
    }
  }

  .table-head-buttons {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    tr th {
      @include px-rem('padding-top', 24);
      @include px-rem('padding-bottom', 40);

      text-align: center;
      border-color: #E5E5E5;
    }

    .btn-success {
      color: $white;
    }

    strong {
      @include px-rem('margin-bottom', 12);

      display: block;
      line-height: 30px;
      font-weight: $font-weight-semibold;
    }
  }

  .table-body tr td {
    padding-right: 10px;
    padding-left: 10px;
    vertical-align: middle;

    &:first-of-type {
      padding-left: 16px;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 24px;
    }
  }
}

.pricing-discounts {
  background: $pricing-light-blue;
  text-align: center;
  padding: 28px 0;

  p {
    margin: 0;

    a {
      font-weight: 400;
    }

    strong {
      @extend %font-primary;
    }
  }
}

.hidden-list {
  display: none;
}

// Faq
//

.features-tabs {
  .section-inner {
    padding-top: 160px;
    padding-bottom: 130px;
    position: relative;
  }
  .nav-pills {
    a {
      color: #2F3941;
      font-size: 20px;
    }
  }
  .nav-link {
    &:hover {
      background-color: rgba(0, 108, 187, .1);
    }
    &.active {
      background-color: #006CBB;
      font-weight: 500;
      &:after {
        content: ' \25B8';
        float: right;
      }
    }
  }
}
.section-badged {
  .section-inner {
    background: #171A2D;
    padding-top: 50px;
    padding-bottom: 40px;
    position: relative;
    border-bottom-right-radius: 80px;
  }
  &-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > div {
      margin: 0 15px 30px;
    }
  }
  .bg-img-left {
    position: absolute;
    top: 50px;
    left: -100px;
  }
  header {
    h2 {
      font-weight: 400;
    }
  }
}
.findout-section {
  padding-top: 40px;
  position: relative;
}

.page-faq {
  background: #F7F7F7;
  padding: $faq-vspacing--mobile;

  @include media-breakpoint-up(lg) {
    padding: $faq-vspacing;
  }
}

.page-challenge {
  background: #fafafa;
}

.pricing-faq {
  h5 {
    margin: 0;
    padding: 0;
  }

  p {
    color: #737580;
    font-size: (14/16 + rem);
  }

  .faq-item {
    margin: 0 0 35px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }

    p:last-of-type {
      margin: 0;
    }
  }
}

.contact-card {
  margin: 0 0 130px;

  @include media-breakpoint-down(sm) {
    margin: 0 0 60px;
  }

  .card {
    background: #f7f8fa;
    border: 0;

    .card-title {
      font-size: (24/16 + rem);
      line-height: 26px;
      font-weight: 400;
    }

    .card-body {
      padding: 50px 20px;
    }
  }
}

// Reduced plans
//

.reduced-plans {
  .page-header {
    margin-bottom: 60px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 120px;
    }

    h1 {
      @include media-breakpoint-up(lg) {
        font-size: (42/16 + rem);
      }
    }
  }

  .get-started {
    margin-bottom: 0;
  }

  .app-features-body {
    p {
      margin: 0 0 32px;
      color: #525D66;
      opacity: 0.7;
    }
  }

  h2 {
    margin: 0 0 32px;
  }
}

.concierge {
  .tq {
    color: #006CBB;
    font-size: 40px;
    line-height: 0;
    position: relative;
    top: 13px;
    display: inline-block;
  }
  &-step {
    color: #006CBB;
  }
  &--steps {
    @include media-breakpoint-up(lg) {
      margin-top: -50px;
    }
  }
  &--step_line {
    display: none;
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 70px;
      z-index: -1;
      display: block;
    }
  }

  &--step1 {
    @include media-breakpoint-up(lg) {
      margin-left: 50px;
      margin-top: 60px;
      padding: 0;
    }
  }
  &--step2 {
    @include media-breakpoint-up(lg) {
      margin-left: -20px;
      padding: 0;
    }
  }
  &--step3 {
    @include media-breakpoint-up(lg) {
      margin-left: 0px;
      padding: 0;
    }
  }
  &--step4 {
    @include media-breakpoint-up(lg) {
      margin-left: 0px;
      margin-top: 20px;
      padding: 0;
    }
  }
}



.community {
  margin: 0 0 80px;

  @include media-breakpoint-down(sm) {
    padding: 0 30px;
    margin: 0 0 50px;
  }

  text-align: center;

  p {
    margin: 0 0 22px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }

  &-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > div {
      margin: 0 15px 30px;
      @include media-breakpoint-down(sm) {
        margin: 10px;
      }
    }

    .logo-item {
      background-color: $white;
      svg {
        filter: grayscale(1);
        opacity: 0.7;
        transition: all 0.4s;
      }
      &:hover {
        svg {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }
  }
}

.quote-form {

  @include media-breakpoint-up(md) {
    margin-top: -280px;
  }
}
.testimonials {
  &--item {
    background-color: #F5F9FC;
    //padding: 4rem 5rem 1rem 2rem;
    min-height: 390px;
    border-radius: 24px;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
  }
  &--author,
  &--quote {
    opacity: .7;
  }
  &--logo {
    opacity: .32;
    filter: grayscale(100);
    transition: opacity 300ms;
  }

  &-slider {
    .tab-content {
      &:hover {
        .testimonials--logo {
          opacity: 1;
          transition: opacity 300ms;
          filter: grayscale(0);
        }
      }
    }
    .section-inner {
      padding-top: 60px;
      padding-bottom: 40px;
    }
    .tq {
      color: #006CBB;
      font-size: 130px;
      display: inline-block;
      opacity: 0.35;
      position: absolute;
      line-height: 60px;
      margin-left: -45px;
    }
    &__blockquote {
      border: 0;
      padding: 60px;
      @include media-breakpoint-up(md) {
        padding: 60px 120px 45px 70px;
      }
      p {
        font-size: 1rem;
        font-weight: 400;
        line-height: (24/16);
        display: inline-block;
      }
    }
    .nav-item {
      @include media-breakpoint-up(md) {
        padding: 0.5rem 1.2rem;
      }
    }
    .nav-link {
      picture {
        //border: 1px solid purple;
        display: block;
      }
      &.show {
        .nav-link {
          background-color: transparent;
        }
      }
      &.active {
        opacity: 1;
        filter: grayscale(0);
        background-color: transparent;
      }
      &:not(.active) {
        opacity: .25;
        filter: grayscale(100);
        &:hover {
          opacity: .45;
        }
      }
    }
  }
}

