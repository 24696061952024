// Onboarding
// ======================

@keyframes hideImg {

  0% {
    transform: translateY(-80px);
    opacity: 1;
    visibility: visible;
  }
  33% {
    opacity: 1;
    visibility: visible;
  }
  66% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
  }

}
@keyframes animHeightFull {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@keyframes fumes {
  50% {
    transform: scale(1.5);
  }
  51% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes flame {
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  33% {
    transform: translate3d(0px, 12px, 0);
  }
  66% {
    transform: translate3d(0px, 6px, 0);
  }
  100% {
    transform: translate3d(0px, 0px, 0);
  }
}


@keyframes bounce2 {
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  100% {
    transform: translate3d(0px, -25px, 0);
  }
}

@keyframes bounce {
  0% {
    transform: translate3d(0px, -25px, 0);
  }
  50% {
    transform: translate3d(0px, -15px, 0);
  }
  100% {
    transform: translate3d(0px, -25px, 0);
  }
}


.onboarding {

  .main {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
    .content-section {
      margin: 0 0 $vspacing--mob / 2;
      @include media-breakpoint-up(lg) {
        margin: 0 0 $vspacing / 2;
      }
      border-top: 1px dotted transparent;
      border-bottom: 1px dotted transparent;
    }
  }
  .hero {
    .hero-intersect {
      &.circleFill {
        @include media-breakpoint-up(lg) {
          bottom: -25vh;
          margin-left: -40vw;
        }
      }
    }
  }

  .graphic-benefit,
  .get-started-new--picture,
  .onboarding-testimonial__img,
  .onboarding-testimonial__wrapper,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }


  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper,
    .graphic-benefit {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }
    .graphic-benefit__left {
      transform: translate(-80px, 0);
    }
    .graphic-benefit__right {
      transform: translate(80px, 0);
    }
    .onboarding-testimonial__img {
      transform: translate(-80px, 0);
    }
    .onboarding-testimonial__wrapper {
      transform: translate(80px, 0);
    }
    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }
    .get-started-new--picture {
      transform: translate(150px, 0);
    }
    .graphic__get-started--right {
      transform: translate(50px, 0);
    }
    .get-started-body {
      transform: translate(-50px, 0);
    }
  }
  .content-animation {
    .graphic-item {
      visibility: hidden;
      opacity: 0;
      display: block;
      position: relative;
    }
    &.has-animation {
      .graphic-item {
        animation: animRunSlide .6s linear forwards;
        animation-delay: .3s;
      }
      .section2--line {
        animation: animHeight 1s linear forwards;
      }
      .section3--rect {
        animation: animHeightFull 1s linear forwards !important;
        animation-delay: 3s !important;
      }
    }
    /* section 4*/
    .section4--item {
      transform: translate(0px, -50px);
      animation-delay: .9s !important;
      padding-top: 5px;
      margin-top: 17%;
      max-width: 80vw;
      margin-left: 0%;
      z-index: 1;
      @include media-breakpoint-up(sm) {
        margin-top: 18%;
      }
      @include media-breakpoint-up(md) {
        max-width: 43vw;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 19%;
        max-width: 80vw;
      }
    }
    .section4--tasks {
      animation-delay: .4s !important;
      transform: translate(0px, -50px);
    }
    .section4--bg {
      filter: blur(50px);
    }

    /* section 1*/
    .section1--account {
      margin-bottom: -5%;
      transform: translate(0px, -50px);
      animation-delay: .4s !important;

    }
    .section1--line {
      z-index: -1;
      margin-right: -30%;
      text-align: center;
      transform: translate(0px, -50px);
      animation-delay: 1.4s !important;
      animation-duration: 0s !important;
    }
    .section1--card {
      margin-top: -5%;
      transform: translate(0px, -50px);
      animation-delay: .8s !important;
    }
    .section1--onboard {
      margin-top: -10%;
      z-index: 1;
      margin-right: 15%;
      transform: translate(0px, -50px);
      animation-delay: 1.4s !important;

    }
    .section1--import {
      top: 0;
      right: 0;
      margin-top: 23%;
      margin-right: 22%;
      transform: translate(-100px, 0px);
      animation-delay: 1.8s !important;
    }

    /* section 2*/
    .section2--img {
      margin-left: 8%;
      margin-top: -2%;
      transform: translate(0px, -25px);
      animation-delay: 1.4s !important;
    }
    .section2--intercom {
      margin-left: -14%;
      transform: translate(0px, -25px);
      animation-delay: .4s !important;

    }

    .section2--stripe {
      margin-left: -8%;
      transform: translate(0px, -25px);
      animation-delay: .8s !important;

    }
    .section2--twilio {
      margin-left: 8%;
      transform: translate(0px, -25px);
      animation-delay: .8s !important;
    }
    .section2--line {
      animation-delay: 1.5s !important;
      visibility: hidden;
      opacity: 0;
      display: block;
      position: relative;

    }

    /* section 3 */
    .section3--start {
      transform: translate(0px, -25px);
      animation-delay: .4s !important;
    }
    .section3--line {
      animation-delay: 1.8s !important;
    }
    .section3--activity {
      transform: translate(0px, -50px);
      animation-delay: .8s !important;
    }
    .section3--payment {
      transform: translate(0px, -50px);
      animation-delay: 1.4s !important;
    }
    .section3--scores {
      transform: translate(0px, -50px);
      animation-delay: 1.4s !important;
    }
    .section3--end {
      transform: translate(0px, -25px);
      animation-delay: 1.8s !important;
    }
    .section3--rect {
      height: 0;
    }
  }
  .rocket {
    animation: bounce2 1s 1 linear 1s,
               bounce 1.5s infinite linear 2s;
    margin-bottom: 20%;
    picture,
    img {
      max-width: 30vw;
      @include media-breakpoint-up(sm) {
        max-width: 25vw;
      }
      @include media-breakpoint-up(md) {
        max-width: 25vw;
      }
      margin: 0 auto;
    }
    &--flames {
      .flame3 {
        animation: flame 1.5s infinite;
        transform-origin: top center;
        animation-direction: reverse;
        animation-timing-function: ease-in-out;
        animation-delay: .8s;

      }
      .flame2 {
        animation: flame 1.5s infinite;
        transform-origin: top right;
        animation-direction: reverse;
        animation-timing-function: ease-in-out;
        animation-delay: .6s;

      }
      .flame1 {
        animation: flame 1.5s infinite;
        transform-origin: top left;
        animation-direction: reverse;
        animation-delay: .4s;
        animation-timing-function: ease-in-out;
      }
      .flame4 {
        opacity: 1;
        animation: flame 1.5s infinite;
        transform-origin: bottom center;
      }
    }
  }
  .hero-onboarding {
    .hide {
      visibility: hidden;
    }
    &.has-animation,
    .has-animation {
      .hero-grahic-item1,
      .hero-grahic-item2,
      .hero-grahic-item3,
      .hero-grahic-item4,
      .hero-grahic-item5,
      .hero-grahic-item6, {
        max-width: 40vw;
        animation: hideImg 2s ease-in-out;
        z-index: 2;
      }
    }

    .hero-grahic-item1 {
      bottom: 10%;
      left: 0%;
      @include media-breakpoint-up(md) {
        left: -30%;
      }
    }
    .hero-grahic-item2 {
      bottom: 30%;
      left: 0%;
      @include media-breakpoint-up(md) {
        left: -20%;
      }
    }
    .hero-grahic-item3 {
      bottom: 35%;
      left: 0%;
      @include media-breakpoint-up(md) {
        left: -40%;
      }
    }
    .hero-grahic-item4 {
      bottom: 60%;
      right: 0%;
      @include media-breakpoint-up(md) {
        right: -20%;
      }
    }
    .hero-grahic-item5 {
      bottom: 80%;
      right: 0%;
      @include media-breakpoint-up(md) {
        right: -20%;
      }
      @include media-breakpoint-up(xl) {
        right: -30%;
      }
    }
    .hero-grahic-item6 {
      bottom: 20%;
      right: 0%;
      @include media-breakpoint-up(md) {
        right: -20%;
      }
    }
  }


  .e-cards {
    .card {

      height: 100%;
      background: $white;
      @include border-radius(4px);
      .card-image {
        margin: 0 auto;
        display: flex;
        align-items: center;
        align-content: center;

        figure {
          margin: 0 auto;
          z-index: 1;
        }
      }
      .card-category {
        font-weight: 400;
        font-size: 1rem;
        line-height: 16px;
        color: $dark-blue;
        text-transform: uppercase;
      }
      .card-body {
        border: 1px solid #C2C5CC;
        border-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .badge-secondary {
          background-color: $light-gray-010;
          color: $dark-blue;
          @include border-radius(4px);
          padding: 4px 12px;
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
        }
      }
      .card-footer {
        text-align: center;
        border: 0;
        padding: 0;
        background-color:$dark-blue;
        &:hover {
          background-color:darken($dark-blue, 15%);
        }
        a {
          text-decoration: none;
          color: $white;
          display: block;
          padding: 0.75rem 1.25rem;
        }
      }
    }
  }

  .onboarding-testimonial {
    &.has-animation {
      .onboarding-testimonial__img,
      .onboarding-testimonial__wrapper {
        animation: animRunSlide .4s linear forwards;
        animation-delay: 1.3s;
      }
    }
    &__wrapper {
      background: #F3F7FB;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding: 30px 20px;
    }
  }
}
