// Dashboard Page
// ======================

.dashboard-page {

  //testimonial

  //
  .get-started-new--picture,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }
  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }

    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }
    .get-started-new--picture {
      transform: translate(150px, 0);
    }
    .graphic__get-started--right {
      transform: translate(50px, 0);
    }
    .get-started-body {
      transform: translate(-50px, 0);
    }
  }

  //main
  .main {
    padding-top: 90px;

    @include media-breakpoint-up(md) {
      padding-top: 140px;
    }
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }

  }


  .get-customer-picture {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  //animation

  .content-animation {
    .graphic-item {
      visibility: hidden;
      opacity: 0;
      position: relative;
    }
    &.has-animation {
      .graphic-item {
        animation: animRunSlide .4s linear forwards;
      }
      .unlock-insight {
        &--line {
          animation: animHeightFull .4s linear forwards;
        }
        &--line2 {
          animation: animHeightFull .4s linear forwards;
        }
      }
      .financial-outlook {
        &--menu {
          animation: animRunSlide .4s linear forwards;
        }
        &--line {
          animation: animHeightFull .4s linear forwards;
        }
      }
      .predict-cashflow {
        &--line1,
        &--line2,
        &--line3,
        &--line4 {
          animation: animHeightFull .4s linear forwards;
        }
      }
    }
  }


  //sections

  //section1
  .visualize-data {
    &--dashboard {
      bottom: 0;
      left:0;
      right: 0;
      z-index: -1;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 100vw;
      }
    }
    &--right {
      margin-left: 45%;

      transform: translate(80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 50vw;
        margin-right: -15px;
      }

    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -9;
    }
  }
  //section2
  .financial-outlook {
    .content-animation {
    }
    &--menu {
      visibility: hidden;
      opacity: 0;

      top: 0;
      right: 0;
      transform: translate(-80%, 0%);
      margin-right: 40%;
      margin-top: 27%;
      animation-delay: .4s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 40vw;
        margin-right: 55%;
      }
    }
    &--line {
      top: 0;
      right: 0;
      margin-top: 15%;
      margin-right: 35%;
      z-index: -1;
      height: 0;
      overflow: hidden;
      animation-delay: 1.8s !important;
      animation-duration: 1s !important;
      @include media-breakpoint-down(xs) {
        max-width: 22vw;
        margin-right: 45%;
        margin-top: 10%;
      }
    }
    &--mrr {
      margin-right: -25px;
      transform: translate(80%, 0%);
      animation-delay: .8s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 40vw;
        margin-left: 50%;
        margin-right: 0px;
      }

    }
    &--csm {
      margin-right: -25px;
      transform: translate(80%, 0%);
      animation-delay: 1s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 40vw;
        margin-left: 50%;
        margin-right: 0px;
      }
    }
    &--ghs {
      margin-right: -25px;
      transform: translate(80%, 0%);
      animation-delay: 1.4s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 40vw;
        margin-left: 50%;
        margin-right: 0px;
      }
    }
    &--bg {
      top: 50%;
      right: 0;
      transform: translate(0%, -50%);
      margin-right: -30%;
      z-index: -9;
    }
  }
  //section3
  .monitor-activity {

    &--slider {
      .hide {
        display: none;
        opacity: 0;
        height: 0;
        z-index: -1;
      }
      .show {
        display: block;
        opacity: 1;
        height: 100%;
        z-index: 9;
      }
    }
    &--tasks {
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 80vw;
        margin-left: 0%;
      }
      &-slider {
        padding-top: calc(232 / 549 * 100%);
        picture {
          top: 0;
          left: 0;
        }
      }
    }
    &--emails {
      transform: translate(80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .6s !important;
      margin-right: -10%;
      @include media-breakpoint-down(xs) {
        max-width: 70vw;
        margin-left: 20%;
      }
      &-slider {
        padding-top: calc(232 / 482 * 100%);
        picture {
          top: 0;
          right: 0;
        }
      }
    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 10%;
      margin-left: -25%;
      z-index: -9;
    }
  }
  //section4
  .retention-power {
    &--stats {
      margin-top: 0;
      margin-right: 8px;
      right: 0;
      z-index: 1;
      transform: translate(80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 40vw;
      }
    }
    &--chart {
      margin-top: 15%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 100vw;
      }
    }

    &--bg2 {
      bottom: -20%;
      margin-left: -40%;
      z-index: -9;
      width: 100%;
      left: 0;
    }
    &--bg {
      bottom: 0;
      margin-right: -40%;
      z-index: -9;
      width: 100%;
      right: 0;
    }
  }
  //section5
  .unlock-insight {

    &--mrr {
      margin-left: 10px;
      margin-top: 5px;
      justify-content: start;
      transform: translate(0%, -80%);
      animation-delay: .4s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(xs) {
        max-width: 65vw;
        margin-top: -5px;
      }
    }
    &--cohort {
      margin-left: -30px;
      justify-content: start;
      transform: translate(0%, -30%);
      animation-delay: .8s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(xs) {
        margin-left: -20px;
      }
    }
    &--feature {
      justify-content: start;
      transform: translate(0%, -80%);
      animation-delay: 1.2s !important;
      animation-duration: .4s !important;
      margin-left: 28%;
      @include media-breakpoint-down(xs) {
        margin-left: 15%;
        margin-right: -30px;
      }
    }
    &--line {
      top: 0;
      left: 0;
      margin-left: 50%;
      margin-top: 5%;
      z-index: -9;
      height: 0;
      overflow: hidden;
      animation-delay: 1.2s !important;
      animation-duration: 1s !important;
      @include media-breakpoint-down(xs) {
        max-width: 17vw;
        margin-left: 65%;
      }
    }
    &--line2 {
      top: 0;
      left: 0;
      margin-left: 10%;
      margin-top: 58.5%;
      z-index: -9;
      height: 0;
      overflow: hidden;
      animation-delay: 1.6s !important;
      animation-duration: 1s !important;
      @include media-breakpoint-down(xs) {
        max-width: 25vw;
        margin-top: 66%;
        margin-left: 12%;
      }
    }
    &--bg  {
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      margin-top: 0;
      margin-left: -45%;
      z-index: -9;
      width: 100%;

    }
  }
  //section6
  .predict-cashflow {
    &--quote1 {
      margin-right: 25%;
      transform: translate(0%, -80%);
      animation-delay: .4s !important;
      animation-duration: .4s !important;
    }
    &--quote2 {
      margin-right: 8%;
      transform: translate(0%, -80%);
      animation-delay: .8s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(xs) {
        max-width: 60vw;
        margin-left: 25%;
      }
    }
    &--cashflow {
      margin-right: -30px;
      transform: translate(0%, -80%);
      animation-delay: 1.2s !important;
      animation-duration: .8s !important;
    }
    &--ghs {
      margin-top: 5%;
      transform: translate(0%, -80%);
      animation-delay: 1.6s !important;
      animation-duration: .4s !important;
    }
    &--csm {
      transform: translate(0%, -80%);
      animation-delay: 2s !important;
      animation-duration: .4s !important;
    }
    &--line1 {
      top: 0;
      right: 0;
      margin-top: 7%;
      margin-right: 22%;

      z-index: -1;
      height: 0;
      overflow: hidden;
      animation-delay: 1.2s !important;
      animation-duration: 1s !important;
    }
    &--line2 {
      top: 0;
      right: 0;
      margin-top: 20%;
      margin-right: 5%;

      z-index: -1;
      height: 0;
      overflow: hidden;
      animation-delay: 2.2s !important;
      animation-duration: 1s !important;
    }
    &--line3 {
      top: 0;
      right: 0;

      margin-top: -4%;
      margin-right: 15%;

      z-index: -1;
      height: 0;
      overflow: hidden;
      animation-delay: 2.4s !important;
      animation-duration: .8s !important;
    }
    &--line4 {
      top: 0;
      right: 0;

      margin-top: -4%;
      margin-right: 43%;

      z-index: -1;
      height: 0;
      overflow: hidden;
      animation-delay: 2.4s !important;
      animation-duration: .8s !important;
    }
    &--bg  {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      margin-right: -35%;
      z-index: -9;
    }
  }
  //section7
  .share-dash {
    &--report{
      justify-content: start;
      margin-left: -30px;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(xs) {
        margin-left: -20px;
      }
    }
    &--share{
      top: 0;
      left:0;
      margin-top: 31%;
      margin-left: 36%;
      transform: translate(0%, 80%);
      animation-delay: .8s !important;
      animation-duration: .4s !important;

      @include media-breakpoint-down(xs) {
        max-width: 40vw;
        margin-top: 50%;
        margin-left: 50%;
      }
    }
    &--persons {
      top: 0;
      left:0;
      margin-top: 40%;
      margin-left: 35%;
      @include media-breakpoint-down(xs) {
        margin-top: 65%;
        margin-left: 50%;
      }
    }
    &--john {
      margin-left: -65%;
      margin-bottom: -15px;
      transform: translate(0%, 80%);
      animation-delay: 1s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(xs) {
        max-width: 44vw;
        margin-left: -31%;
        margin-bottom: -10px;
      }
    }
    &--margaret {
      margin-right: -25%;
      margin-bottom: -15px;
      transform: translate(0%, 80%);
      animation-delay: 1.2s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(xs) {
        max-width: 50vw;
        margin-left: 10%;
        margin-right: 0;
        margin-bottom: -10px;
      }
    }
    &--anne {
      margin-left: -25%;
      transform: translate(0%, 80%);
      animation-delay: 1.4s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(xs) {
        max-width: 44vw;
        margin-left: -20%;
      }
    }
    &--bg  {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: -25%;
      margin-left: -40%;
      z-index: -9;
      width: 100%;
    }
  }
}

