// Security
// ======================

.security {
  .page-faq {
    padding-top: 0;
    background-color: $white;

    .card-header {
      background-color: $white;
    }
  }
  .commitment {
    &__item {
      background: $dark-blue;
      border: 4px solid rgba(255, 255, 255, 0.16);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
      border-radius: 80px;
      color: $white;
      padding: 30px;
    }
    &__img {
      min-height: 187px;
    }
  }
  .compliance {
    &__item {
      .col-md-3-2 {
        @include media-breakpoint-up(md) {
          -ms-flex: 0 0 29%;
          flex: 0 0 29%;
          max-width: 29%;
        }
      }
    }
    &__title {
      @include media-breakpoint-up(md) {
        margin-top: 135px;
      }
      color: $blue-800;
      font-size: 24px;
      font-family: $font-roboto;

    }
    &__subtitle {
      color: $dark-blue;
      @extend .mb-4;
      font-size: 24px;
      font-family: $font-roboto;
      font-weight: 500;
    }
    &__svg {
      @include media-breakpoint-up(md) {
        min-height: 51px;
      }
    }
    &__bg {
      bottom: 0;
      right: 0;
      margin-bottom: -100%;
      @include media-breakpoint-up(md) {
        margin-bottom: -30%;
      }
      margin-right:-30%;
      z-index: -1;
    }
  }
}
