// Buttons
// ======================

// Vars
// ----------------------

$notice-bar-bg: #191919;
$notice-bar-border-radius: 3px;

// Base
// ----------------------

#notice-bar {
  background: #383a4f;
  position: fixed;
  z-index: 2147483647;
  top: 0;
  left: 0;
  border-radius: 0;
  color: #fff;
  display: none;
  margin: 20px;

  @include media-breakpoint-up(md) {
    top: auto;
    bottom: 20px;
    width: 100%;
    max-width: 85%;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  .notice-bar-inner {
    padding: 20px;

    @include media-breakpoint-up(md) {
      max-width: 100%;
      padding: 20px 200px 20px 85px;
      position: relative;
    }
  }

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  h3,
  strong,
  p {
    color: #fff;
  }

  h3 {
    margin: 0 0 35px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  strong {
    display: none;
    text-transform: uppercase;
    position: absolute;
    left: 20px;
    font-size: 14px;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

  p {
    font-size: (12/16 + rem);
    margin: 0;
  }

  .notice-bar-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 14px;

    &,
    &:hover {
      text-decoration: none;
    }

    @include media-breakpoint-up(md) {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
