// Product
// ======================

@keyframes animation-showhide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes line1 {
  /* clean-css ignore:end */
  0% {
    d: path("M 67 214 C 67 214 112 212 170 209 C 212 207 201 209 258 205 C 307 205 307 205 367 202 C 409 199 396 200 450 199 C 505 195 541 194 541 194");
  }
  100% {
    d: path("M67 194C67 194 114 146 161 146C209 146 209 123 256 123C304 123 304 136 351 136C398 136 398 210 446 210C493 210 541 84 541 84");
  }
  /* clean-css ignore:end */
}
@keyframes line2 {
  /* clean-css ignore:start */
  0% {
    d: path("M 67 214 C 67 214 112 212 170 209 C 212 207 201 209 258 205 C 307 205 307 205 367 202 C 409 199 396 200 450 199 C 505 195 541 194 541 194");
  }
  100% {
    d: path("M67 234C67 234 114 196 161 196C209 196 209 173 256 173C304 173 304 156 351 156C398 156 398 170 446 170C493 170 541 134 541 134");
  }
  /* clean-css ignore:end */
}
@keyframes line3 {
  /* clean-css ignore:start */
  0% {
    d: path("M 67 214 C 67 214 112 212 170 209 C 212 207 201 209 258 205 C 307 205 307 205 367 202 C 409 199 396 200 450 199 C 505 195 541 194 541 194");
  }
  100% {
    d: path("M67 214C67 214 114 206 161 206C209 206 209 223 256 223C304 223 304 186 351 186C398 186 398 150 446 150C493 150 541 194 541 194");
  }
  /* clean-css ignore:end */
}

.product-solution {
  .graphic-benefit,
  .get-started-new--picture,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }

  //hero
  .hero-cover{
    &--circle-author {
      @include media-breakpoint-down(md) {
        right: 38%;
        transform: scale(0.7);
        bottom: -30px;
      }
      @include media-breakpoint-up(md) {
        right: 46%;
        position: absolute;
      }
      bottom: -10px;
      z-index: -1

    }
    &--testimonial{
      &-author {
        @include media-breakpoint-up(md) {
          @include border-radius(8px);
          background: rgba(255,255,255, .3);
        }
      }
    }
    &--author {
      bottom: -10px;
      z-index: 1;
      max-width: 50vw;
      right: 44%;
      @include media-breakpoint-down(md) {
        transform: scaleX(-1);
      }
      @include media-breakpoint-up(md) {
        right: 44%;
      }
    }
    &--fadeIn {
      @include media-breakpoint-up(lg) {
        margin-top: -8rem;
      }
    }
  }
  .hero-cover--testimonial {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 35vh;
    visibility: hidden;
    opacity: 0;
    position: relative;
    @include media-breakpoint-down(md) {
      height: 30vh;
    }
    &-quote {
      @include media-breakpoint-down(md) {
        display: flex !important;
        animation: animRunSlide .6s linear forwards;
      }
    }
    &-badge {
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
    &.hide {
      opacity: 0;
      display: none;
    }
    &.show {
      transform: translate(80%, 0%);
      display: flex;
      animation: animRunSlide .6s linear forwards;
    }
  }
  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper,
    .graphic-benefit {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }
    .graphic-benefit__left {
      transform: translate(-80px, 0);
    }
    .graphic-benefit__right {
      transform: translate(80px, 0);
    }
    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }
    .get-started-new--picture {
      transform: translate(150px, 0);
    }
    .graphic__get-started--right {
      transform: translate(50px, 0);
    }
    .get-started-body {
      transform: translate(-50px, 0);
    }
    .impact-section {
      &--stripe-line,
      &--start-line,
      &--salesforce-line,
      &--user-line,
      &--user-line,
      &--rect {
        animation: animHeightFull 1s linear forwards 1.6s;
      }
    }
    .upsell-section {
      &--line {
          animation: animHeightFull 1s linear forwards 1.6s;
      }
    }
    .channel-section {
      &--customers {
        &-lineUp {
          //animation: animHeightFull 2s linear forwards 1.6s;
        }
        &-lineDown {
          //animation: animHeightFull 2s linear forwards 1.6s;
        }
      }
    }
    .teamup-section {
      &--started {
        &-line {
          animation: animHeightFull 2s linear forwards 1.6s;
        }
      }
      &--asign-csm {
        &-line {
          animation: animHeightFull 2s linear forwards 1.6s;
        }
      }
      &--sales-rep {
        &-line {
          animation: animHeightFull 2s linear forwards 1.6s;
        }
      }
      &--notify {
        &-line {
          animation: animHeightFull 2s linear forwards 1.6s;
        }
      }
    }
    .svg-animate {
      .circleSvg {
        opacity: 0;
        animation: animation-showhide .1s linear forwards 2.1s;
      }
      #line1 {
        stroke: #006CBB;
        fill: none;
        animation: line1 1s linear forwards  1.1s;
      }
      #line2 {
        stroke: #08DACC;
        fill: none;
        animation: line2 1s linear forwards  1.1s;
      }
      #line3 {
        stroke: #545566;
        fill: none;
        animation: line3 1s linear forwards  1.1s;
      }
    }

  }

  .get-customer-picture {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  //animation

  .content-animation {
    .graphic-item {
      visibility: hidden;
      opacity: 0;
      position: relative;
    }
    &.has-animation {
      .graphic-item {
        animation: animRunSlide .4s linear forwards;
      }
    }
  }


  //section 1
  .upsell-section {
    overflow: hidden;
    &--img2 {
      top: 0;
      right: 0;
      margin-bottom: 11%;
      margin-right: 33%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(md) {
        margin-left: 10%;
      }
    }
    &--img1 {
      margin-top: 2%;
      margin-right: 5%;
      transform: translate(-80%, 0%);
      animation-delay: .6s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(md) {
        margin-left: 25%;
      }

    }
    &--block {
      transform: translate(80%, 0%);
      animation-delay: 1s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(md) {
        margin-left: 40%;
      }
    }
    &--tasks{
      margin-right: 20%;
      transform: translate(80%, 0%);
      animation-delay: 1.2s !important;
      animation-duration: .4s !important;
    }
    &--line {
      top: 0;
      right: 0;
      margin-right: 32%;
      margin-top: 10%;
      z-index: -1;
      animation-delay: 1.2s !important;
      height: 0;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        max-width: 6vw;
      }

    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -9;
      width: 100%;
      height: 100%;
      @include media-breakpoint-up(md) {
        margin-left: 18%;
        margin-top: -5%;
      }
    }
  }

  //section 2 {
  .impact-section {
    &--n5 {
      @include media-breakpoint-up(md) {
        margin-left: -16% !important;
      }

    }
    &--user {
      margin-bottom: 1%;
      transform: translate(0%, -80%);
      animation-delay: .4s !important;
      @include media-breakpoint-down(md) {
        max-width: 35vw;
        margin-left: 20%;
      }
      &-line {
        left: 50%;
        transform: translateX(-50%);
        margin-top: -14%;
        margin-left: -7.5%;
        z-index: -1;
        animation-delay: 1.6s !important;
        height: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          margin-left: -7%;
          margin-top: -21%;
          max-width: 8vw;
        }
      }
    }
    &--stripe {
      //margin-bottom: 5%;
      transform: translate(0%, -80%);
      animation-delay: .8s !important;
      @include media-breakpoint-down(md) {
        max-width: 30vw;
        margin-left: -14%;
      }
      &-line {
        left: 50%;
        transform: translateX(-50%);
        margin-top: -7%;
        margin-left: -10%;
        z-index: -1;
        animation-delay: 1.6s !important;
        height: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          max-width: 14vw;
        }
      }
    }
    &--salesforce {
      top: 20px;
      margin-left: 10%;
      margin-right: -13%;
      transform: translate(0%, -80%);
      animation-delay: .8s !important;
      @include media-breakpoint-down(md) {
        max-width: 50vw;
      }
      &-line {
        left: 50%;
        transform: translateX(-50%);
        margin-top: -4%;
        margin-left: -1%;
        z-index: -1;
        animation-delay: 1.6s !important;
        height: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          margin-left: 4%;
          max-width: 14vw;

        }
      }
    }
    &--startGroup {
      margin-top: 7%;
      min-height: 150px;
      @include media-breakpoint-down(md) {
        max-width: 30vw;
        margin-left: 28%;

      }
    }
    &--start {
      margin-bottom: 2%;
      transform: translate(0%, -80%);
      animation-delay: 1.2s !important;
      margin-top: 6%;
      @include media-breakpoint-down(md) {
        max-width: 24vw;
        margin-left: 28%;
        margin-top: 12%;
      }
      &-line {
        left: 50%;
        transform: translateX(-50%);
        margin-top: -5%;
        margin-left: -7.5%;
        z-index: -1;
        height: 0;
        overflow: hidden;
        animation-delay: 2.2s !important;

      }
    }
    &--health {
      transform: translate(0%, -80%);
      animation-delay: 1.8s !important;
      @include media-breakpoint-down(md) {
        max-width: 30vw;
        margin-left: 24%;
      }
    }
    &--notification {
      transform: translate(0%, -80%);
      animation-delay: 2.2s !important;
      @include media-breakpoint-down(md) {
        max-width: 30vw;
        margin-left: -16%;
      }
    }
    &--assign {
      margin-left: 14%;
      margin-right: 2%;
      transform: translate(0%, -80%);
      animation-delay: 2.2s !important;
      @include media-breakpoint-down(md) {
        max-width: 30vw;
        margin-left: 21%;
      }
    }
    &--end {
      margin-top:-1%;
      transform: translate(0%, -80%);
      animation-delay: 2.6s !important;
      @include media-breakpoint-down(md) {
        max-width: 20vw;
        margin-left: 30%;
      }
    }
    &--rect {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 43%;
      margin-left: -8%;
      z-index: -1;
      animation-delay: 2.6s !important;
      animation-duration: 1s !important;
      height: 0;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        margin-top: 60%;
        max-width: 55vw;
      }
    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: -10%;
      z-index: -9;
      width: 100%;
      height: 100%;
    }

  }
  //section 3
  .teamup-section {
    @include media-breakpoint-down(md) {
      overflow: hidden;
    }
    &--group {
      position: relative;
      right: -20px;
    }
    &--asign-csm {
      transform: translate(80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .4s !important;
      &-line {
        top: 0;
        left: 0;
        margin-top: 6%;
        margin-left: -25%;
        z-index: -1;
        animation-delay: .8s !important;
        height: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          transform: scale(0.7);
          margin-top: -24%;
          margin-left: -42%;
        }
      }
    }
    &--started {
      transform: translate(80%, 0%);
      animation-delay: .6s !important;
      animation-duration: .4s !important;
      &-line {
        top: 0;
        left: 0;
        margin-top: 47%;
        margin-left: -25%;
        z-index: -1;
        animation-delay: 1s !important;
        height: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          transform: scale(0.7);
          margin-top: 23%;
          margin-left: -48%;
        }
      }
    }
    &--sales-rep {
      transform: translate(80%, 0%);
      animation-delay: .8s !important;
      animation-duration: .4s !important;
      &-line {
        top: 0;
        left: 0;
        margin-top: 86%;
        margin-left: -25%;
        z-index: -1;
        animation-delay: 1.2s !important;
        height: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          transform: scale(0.7);
          margin-top: 68%;
          margin-left: -49%;
        }
      }
    }
    &--notify {
      transform: translate(80%, 0%);
      animation-delay: 1s !important;
      animation-duration: .4s !important;
      &-line {
        top: 0;
        left: 0;
        margin-top: 126%;
        margin-left: -25%;
        z-index: -1;
        animation-delay: 1.4s !important;
        height: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          transform: scale(0.7);
          margin-top: 111%;
          margin-left: -42%;
        }

      }
    }
    &--block {
      margin-top: 10%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(md) {
        margin-top: 20%;
      }
    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 30%;
      z-index: -9;
      width: 100%;
      height: 100%;
      margin-top: -5%;
      @include media-breakpoint-down(md) {
        margin-left: 0%;
      }
    }
  }

  //section 4
  .numbers-section {
    &--chart {
      margin-top: 20%;
      transform: translate(0%, -80%);
      animation-delay: .4s !important;
      @include media-breakpoint-down(md) {

      }
    }
    &--pie {
      top:0;
      left:0;
      margin-top: 5%;
      margin-left: 8%;
      transform: translate(-80%, 0%);
      animation-delay: 1s !important;
      @include media-breakpoint-down(md) {
        max-width: 18vw;
      }
    }
    &--email {
      top:0;
      right: 0;
      margin-right:10%;
      transform: translate(0%, -80%);
      animation-delay: .8s !important;
      animation-duration: .3s !important;
      @include media-breakpoint-down(md) {
        max-width: 50vw;
      }

    }
    &--responses {
      bottom:0;
      left: 0;
      margin-bottom: -5%;
      transform: translate(0%, 80%);
      animation-delay: 1.2s !important;
      @include media-breakpoint-down(md) {
        max-width: 44vw;
        margin-bottom: 5%;

      }
    }

    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 5%;
      margin-left: -20%;
      z-index: -9;
      width: 100%;
      height: 100%;
    }
  }

  //section 5
  .channel-section {
    &--customers {
      margin-right: 5%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
      &-lineUp {
        z-index: -1;
        animation-delay: 1s !important;

        margin-top: -14%;
        @include media-breakpoint-down(md) {
          transform: scale(.7) !important;
          margin-top: -20%;
        }
      }
      &-lineDown {
        z-index: -1;
        animation-delay: 1s !important;
        margin-top: 9%;
        @include media-breakpoint-down(md) {
          transform: scale(.7) !important;
          margin-top:5%;
        }
      }
    }
    &--gmail {
      margin-bottom: -5%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
    }
    &--sms {
      margin-bottom: -5%;
      transform: translate(-80%, 0%);
      animation-delay: .6s !important;
    }
    &--hubspot {
      margin-bottom: -5%;
      transform: translate(-80%, 0%);
      animation-delay: .6s !important;
    }
    &--intercom {
      margin-bottom: -5%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
    }
    &--circle {
      transform: translate(-80%, 0%);
      animation-delay: 1.4s !important;

      &-left{
        transform: translate(-80%, 0%);
        animation-delay: 1.8s !important;
        @include media-breakpoint-down(md) {
          transform: scale(.7) !important;
        }
      }
      &-right{
        transform: translate(-80%, 0%);
        animation-delay: 2.4s !important;
        @include media-breakpoint-down(md) {
          transform: scale(.7) !important;
        }
      }
    }
    &--csm {
      margin-left: 2%;
      transform: translate(-80%, 0%);
      animation-delay: 2s !important;
    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -9;
      width: 100%;
      height: 100%;
      text-align: center;
      margin-top: -1%;
      margin-left: 18%;
      @include media-breakpoint-down(md) {
        max-width: 34vw;
        margin-top: 9%;
        margin-left: 15%;
      }
    }
  }
  .e-cards {
    .card {
      height: 100%;
      background: $white;
      @include border-radius(4px);
      .card-image {
        margin: 0 auto;
        display: flex;
        align-items: center;
        align-content: center;

        figure {
          margin: 0 auto;
          z-index: 1;
        }
      }
      .card-category {
        font-weight: 400;
        font-size: 1rem;
        line-height: 16px;
        color: $dark-blue;
        text-transform: uppercase;
      }
      .card-body {
        border: 1px solid #C2C5CC;
        border-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .badge-secondary {
          background-color: $light-gray-010;
          color: $dark-blue;
          @include border-radius(4px);
          padding: 4px 12px;
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
        }
      }
      .card-footer {
        text-align: center;
        border: 0;
        padding: 0;
        background-color:$dark-blue;
        &:hover {
          background-color:darken($dark-blue, 15%);
        }
        a {
          text-decoration: none;
          color: $white;
          display: block;
          padding: 0.75rem 1.25rem;
        }
      }
    }
  }

}
