// Buttons
// ======================

// Vars
// ----------------------

// Base
// ----------------------

.modal-backdrop {
  &.show {
    opacity: 0.6;
  }
}

.modal-open {
  .modal {
    overflow: hidden !important;
    height: 100vh;
    -webkit-overflow-scrolling: touch !important;

    &.modal-center {
      align-items: center !important;
      display: flex !important;
      justify-content: center;

      .modal-dialog {
        flex-grow: 1;
      }
    }
  }

  // drift widget
  //

  #drift-widget-container {
    display: none;
  }
}

.modal {
  color: #fff !important;
  z-index: 9999;

  .modal-dialog,
  .modal-content {
    @media (min-width: 992px) and (max-height: 650px) {
      height: 100%;
      border: 0;
    }

    @include media-breakpoint-down(sm) {
      border: 0;
      margin: 0;
      border-radius: 0;
    }
  }

  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-content {
    position: relative;
    padding-top: 55px;

    @include media-breakpoint-down(xs) {
      padding-top: 40px;
      overflow-y: auto;
      height: 100%;
    }
  }

  .modal-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;
    padding: 0;
    border: 0;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 0;
      margin: 0;

      @include media-breakpoint-up(md) {
        top: 32px;
        right: 32px;
      }

      &,
      &:hover {
        outline: 0;
      }
    }

    .icon {
      @include media-breakpoint-down(xs) {
        svg {
          * {
            fill: #000 !important;
          }
        }
      }
    }
  }

  .modal-body {
    padding: 0 20px 35px;
    height: calc(100% - 55px);
    overflow: auto;

    @media (min-width: 400px) {
      padding: 0 30px 30px;
    }

    @include media-breakpoint-up(sm) {
      padding: 0 45px 50px 15px;
    }
  }
}

.modal--large {
  .modal-dialog {
    max-width: 770px;
  }
}

.lightbox {
  .modal-content {
    background-color: transparent;
    border: 0;
  }
  .modal-body {
    padding: 0 30px 0 30px;
  }
  .modal-header {
    .close {
      opacity: .8;
      z-index: 1041;
      margin: 20px;
    }
    .icon {
      @include media-breakpoint-down(xs) {
        svg {
          * {
            fill: #fff !important;
          }
        }
      }
    }
  }
  .modal-dialog {
    max-width: $modal-xl;
  }
}
