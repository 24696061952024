// Careers
// ======================

// Vars
// ----------------------

$placeholder-color: #949A9E;
$border-color: #DADDE2;
$terms-color: #646F79;

// Base
// ----------------------

.request {
  .form-section {
    margin: 0;

    p,
    .list {
      margin: 0 0 20px;
    }
    .formRequest {
      @include media-breakpoint-up(lg) {
        margin-top: -90px;
      }
    }
    .response {
      font-size: 14px;
      color: red;
    }
  }

  #request-form {
    h4 {
      margin: 0 0 30px;
    }
  }

  .form-circle {
    position: absolute;
    top: -70px;
    left: 0;

    @include media-breakpoint-up(lg) {
      top: -100px;
      left: -30px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .request-content {
    padding: 0 0 50px;

    @include media-breakpoint-up(lg) {
      padding: 60px 0 0;
    }
  }

  .iti__flag {background-image: url("path/to/flags.png");}

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("path/to/flags@2x.png");}
  }

  .vue-tel-input {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    border: 2px solid $border-color;
    position: relative;
    &:focus-within {
      border: 2px solid $blue-900;
      box-shadow: none;
      outline: none;
    }
    input {
      &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
      }
    }
    .vti__selection .vti__country-code {
      color: $gray-700;
      font-size: 1rem;
      font-weight: 500;
    }
    .vti__dropdown {
      border-right: 1px solid $border-color;
      position: unset;
    }
    .vti__dropdown-list {
      width: calc(100% + 4px);
      margin-left: -1px;
    }
    .vti__dropdown-item {
      font-family: inherit;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
      &.last-preferred {
        border-bottom: none;
      }
      strong {
        font-family: inherit;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
      }
    }

    .vti__dropdown-arrow {
      margin-left: 5px;
    }

    .vti__input {
      padding-left: 10px;
    }
  }

  .product-testimonials {
    padding-top: 70px;

    @include media-breakpoint-up(md) {
      padding-top: 140px;
    }
  }

  .section-logos {
    &--grey {
      background-color: $gray-300;
    }
  }
}
.request-webinar {
  &.hero-on-dark {

    .hero-dark {
      .container {
        p {
          font-family: $font-montserrat;
          font-weight: $font-weight-light;
        }
      }
    }
    .hero-circle {
      background: $dark-blue;
    }
    .hero-cover {
      &--bigCircle{
        position:absolute;
        top: auto;
        left: 51%;
        bottom: 140px;
      }
      &--author {
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -2vw;
        max-width: 550px;
      }
      &--grid {
        p {
          font-size: 19px;
        }
      }
      &--circle {
        position:absolute;
        left: 49%;
        bottom: 40px;
      }
    }
  }
  .webinar-hero{
    &--desc {
      > div {
        @extend %font-secondary;
        line-height: 2.2;
      }
      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }
  .special-guest {
    font-size: 18px;
    img {
      border: 1px solid $white;
      @include border-radius(50%);
    }
  }
  .speaker {
    &--item {
      margin-bottom: 30px;
      img {
        @include border-radius(50%);
        padding: 2px;
      }
      a {
        color: $dark-blue;
        text-decoration: underline;
      }
    }
    &--author {
      font-family: $font-montserrat;
      font-size: (18/16 + rem);
      padding-bottom: .75rem;
    }
  }
}
