// G2 badges slider animation
// ======================

$size: clamp(10rem, 1rem, 30rem);
$gap: calc(#{$size} / 14);
$duration: 40s;
$scroll-start: 0;
$scroll-end: calc(-100% - #{$gap});

.section-badged {
  &.content-section {
    margin: 0 0 $vspacing--mob;
    @include media-breakpoint-up(lg) {
      margin: 0 0 $vspacing;
    }
  }
}

.features-logos {
  position: relative;
  display: flex;
  flex-direction: column;
  //gap: $gap;
  margin: auto;
  max-width: 100vw;
  @include media-breakpoint-up(lg) {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  @include media-breakpoint-up(xl) {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  &__wrapper {
    display: flex;
    overflow: hidden;
    user-select: none;

    &::before,
    &::after {
      background: linear-gradient(to right,  rgba(23,26,45,1) 0%,rgba(23,26,45,0) 100%);
      content: "";
      position: absolute;
      width: 8vw;
      height: 100%;
      z-index: 2;
      @include media-breakpoint-up(xl) {
        width: 15vw;
        background: linear-gradient(to right,  rgba(23,26,45,1) 20%,rgba(23,26,45,0) 100%);
      }
    }
    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }
    &::before {
      left: 0;
      top: 0;
    }
  }
  &__logo-item {
    display: flex;
    place-items: center;
    justify-content: center;
    //width: $size;
    width: auto;
    //aspect-ratio: 16/9;
    padding: calc(#{$size} / 5);
  }
  &__group {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    //justify-content: space-around;
    justify-content: space-evenly;
    //gap: $gap;
    min-width: 100%;
    animation: scroll-x $duration linear infinite;
    @media (prefers-reduced-motion: reduce) {
      animation-play-state: paused;
    }
  }
}


@keyframes scroll-x {
  from {
    transform: translateX($scroll-start);
  }
  to {
    transform: translateX($scroll-end);
  }
}
