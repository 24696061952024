// Press
// ======================

// Vars
// ----------------------

// Base
// ----------------------

.press {
  .press-about {
    @include media-breakpoint-up(lg) {
      margin-bottom: 80px;
    }
  }

  .product-features {
    &-item {
      .icon-svg {
        &,
        svg {
          width: 48px !important;
          height: 48px !important;
        }
      }

      p {
        margin: 0 0 30px;

        @include media-breakpoint-up(lg) {
          margin: 0 0 55px;
        }

        color: $light-gray-600;
      }
    }
  }

  .press-sections {
    @include media-breakpoint-up(lg) {
      margin-bottom: 120px;
    }
  }
}

.press-about {
  p {
    margin: 0 0 25px;
    color: #525D66
  }

  .section-header {
    margin: 0 0 32px;
  }
}

.press-sections {
  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;
  }

  .section-header {
    margin: 0 0 24px;
  }

  .section-title {
    font-size: (32/16 + rem);
  }

  .lead {
    color: #525D66;
    font-size: 1rem;
    line-height: 24px;
  }

  .btn + .text-info {
    padding-top: 24px;
  }

  .text-info {
    color: $light-gray-400 !important;
    font-size: (14/16 + rem);
    margin: 0 0 7px;
  }

  .btn {
    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }

  &.is-founder {
    @include media-breakpoint-up(lg) {
      picture {
        text-align: center;
        display: block;
      }

      img {
        max-width: 360px;
      }
    }
  }

  picture {
    display: block;
    margin: 0 0 30px;

    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }
}
