// Careers
// ======================

// Vars
// ----------------------

$card-oportunities-bg: #f0f6fb;

// Base
// ----------------------

.job {
  .main {
    padding: 0;
  }

  .form-section {
    margin-top: 0;
  }

  .content-section {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #2f3941;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 30px;
    }

    ul {
      padding: 0 0 0 20px;
    }

    h4 {
      margin: 0 0 24px;
    }

    p {
      margin: 0 0 23px;
    }

    p + h4 {
      padding-top: 14px;
    }

    .card {
      margin-top: 60px;
    }
  }

  .display-form {
    h4 {
      margin: 0 0 40px;
    }
  }

  .btn-wrap {
    text-align: center;

    .btn {
      width: 100%;
      max-width: 370px;
    }
  }

  .hero {
    .lead {
      margin: 0;
    }

    .back {
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: 24px;
      font-size: 13px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.3);
    }

    .hero-intersect {
      bottom: 0;

      &--right {
        left: auto;
        right: 50%;
        margin: 0 calc(-50vw - 140px) 0 0;
      }
    }
  }
}
