// Badge
// ======================

// Vars
// ----------------------

$badge--primary: $blue;
$badge--success: $green;

// Base
// ----------------------

.badge {
  font-size: (9.8/16 + rem);
  color: #fff;
  border-radius: 8px;
  line-height: 15px;
  font-weight: 500;
  padding: 0 6px;
  white-space: nowrap;
}

// Type
// ----------------------

.badge-primary {
  background-color: $badge--primary;
}

.badge-success {
  background-color: $badge--success;
}
