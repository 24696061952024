
.product-automation {
  .page-faq {
    padding-top: 0;
    background: $white;
    .card-header {
      background: $white;
    }
  }
  .content-section {
    p {
      &.section-subtitle {
        font-family: $font-montserrat;
        color: $blue-900;
        margin-bottom: .5em;
      }
    }

  }

  //nav loading
  .nav {
    &-loading {
      border: 0;
      .nav-item {
        margin-bottom: 1.5em;
        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }
      .nav-link {
        position: relative;
        border: 0;
        padding: 0 1em 0 2.5em;
        @include media-breakpoint-down(sm) {
          padding: 0 .5em 0 1.75em;
        }

        &.active {
          background-color: transparent;
          h3 {
            font-family: $font-roboto;
            @extend .h2;
            @include media-breakpoint-down(sm) {
              font-size: 1.3rem;
            }
          }

          .loading-vertical {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            border-radius: 6px;
            z-index: 9;
            background: #5D6871;
            height: auto;
            transition: height .3s linear;
            max-height: 100%;
          }
          &.stop-autoplay {
            .loading-vertical {
              transition: height 0s;
              height: 100% !important;
            }
          }
          &:after {
            background: #EFEFF1;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            border-radius: 6px;
            height: 100%;
          }
          //&:before {
          //  content: '';
          //  position: absolute;
          //  top: 0;
          //  left: 0;
          //  width: 6px;
          //  border-radius: 6px;
          //  z-index: 9;
          //  background: #5D6871;
          //  height: 100%;
          //  transition: height 6s linear;
          //
          //}
          p {
            display: block;
            margin-bottom: 0;
          }
        }

        &:not(.active) {
          h3 {
            color: #A4B5C1;
            font-size: 1rem;
          }
          //&:before {
          //  content: '';
          //  position: absolute;
          //  top: 0;
          //  left: 0;
          //  width: 6px;
          //  border-radius: 6px;
          //  height: 0;
          //  z-index: 9;
          //}

          p {
            display: none;
          }
        }
        p {
          line-height: 1.5em;
          min-height: calc(1.6em * 3);
        }
      }
    }
  }

  //hero
  .text-blue {
    opacity: 1;
    transition: opacity .5s ease-in-out;
  }

  .text-blue.fade-out {
    opacity: 0;
  }


  //animation

  @keyframes animRunSlideForward {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes animRunSlideReverse {
    from {
      opacity: 1;
      visibility: visible;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
  }
  @keyframes animRunSlideReverseOpacity {
    from {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
    to {
      transform: translateX(80%);
      opacity: 0;
      visibility: hidden;
    }
  }
  overflow-x: hidden;
  .graphic-benefit,
  .get-started-new--picture,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }
  .main {
    overflow-x: hidden;
  }
  .get-customer-picture {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .section-animation,
  .content-animation {
    .graphic-item,
    .graphic-item_new,
    .graphic-item_new2 {
      visibility: hidden;
      opacity: 0;
      position: relative;
    }

    &.has-animation {
      .graphic-item {
        animation: animRunSlide .5s linear forwards;
      }
    }
  }
  .get-started,
  .testimonials-fullscreen {
    &:not(.get-started-new) {
      margin: 0;
    }
  }
  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper,
    .graphic-benefit {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }

    .graphic-benefit__left {
      transform: translate(0, -80px);
    }

    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }

    .get-started-new--picture {
      transform: translate(150px, 0);
    }

    .graphic__get-started--right {
      transform: translate(50px, 0);
    }

    .get-started-body {
      transform: translate(-50px, 0);
    }

  }

  .content-animation {
    &.has-animation {
      .graphic-item,
      .graphic-item_new {
        animation: animRunSlide .6s linear forwards;
        animation-delay: .3s;
        &.insights {
          &--payment-line,
          &--email-line,
          &--status-line {
            animation: animHeight .6s linear forwards;
          }
        }

        &.personalized-health2 {
          &--red {
            animation: animRunSlideForward .5s linear forwards 4s;
          }
          &--green {
            animation:
              animRunSlideForward .5s linear forwards 1s,
              animRunSlideReverse .5s linear forwards 4s;
          }
          &--rect-red {
            animation: animRunSlideForward .5s linear forwards 4s;
          }
          &--rect-green {

            animation:
              animRunSlideForward .5s linear forwards 1.5s,
              animRunSlideReverse .5s linear forwards 4s;
          }
          &--chart-red {
            animation: animRunSlideForward .5s linear forwards 4s;
          }

          &--chart-green {
            animation:
              animRunSlideForward .5s linear forwards 2s,
              animRunSlideReverse .5s linear forwards 4s;
          }
          &--pointer {
            animation:
              animRunSlideForward .4s linear forwards 3.5s,
              animRunSlideReverse .4s linear forwards 4s;

          }
        }
      }
    }
    img {
      user-select: none;
    }
    //section5
  }



  //section

  .seamless-integration {
    &--payment {
      display: block;
      transform: translate(0px, -50px);
      animation-delay: .1s !important;
      max-width: 45vw;
      margin: 4% 0 0 -10%;
      @include media-breakpoint-down(sm) {
        margin: 4% 0 0 20%;
      }

    }

    &--payment-line {
      top: 0px;
      left: 0px;
      z-index: -1;
      animation-delay: 1.5s !important;
      overflow: hidden;
      max-width: 9vw;
      margin-top: 8%;
      margin-left: 37%;
      @include media-breakpoint-down(sm) {
        margin-top: 11%;
        margin-left: 38%;
      }
    }

    &--touchpoint {
      display: block;
      transform: translate(0px, -50px);
      animation-delay: 1.5s !important;
      max-width: 50vw;
      margin: 2% 0 0 40%;
      @include media-breakpoint-down(sm) {
        margin: 2% 0 0 45%;
      }
    }

    &--touchpoint-line {
      top: 0;
      left: 0;
      z-index: -1;
      animation-delay: 2.5s !important;
      overflow: hidden;
      max-width: 9vw;
      margin-top: 15%;
      margin-left: 60%;
      @include media-breakpoint-down(sm) {
        margin-top: 10%;
        margin-left: 65%;
      }
    }

    &--tickets {
      display: block;
      transform: translate(0px, -50px);
      animation-delay: 2.5s !important;
      max-width: 50vw;
      margin: 1% 0 0 -35%;
      @include media-breakpoint-down(sm) {
        margin: 1% 0 0 0%;
      }
    }

    &--tickets-line {
      top: 0;
      left: 0;
      z-index: -1;
      animation-delay: 3.5s !important;
      overflow: hidden;
      max-width: 9vw;
      margin-top: 33%;
      margin-left: 23%;
      @include media-breakpoint-down(sm) {
        margin-top: 40%;
        margin-left: 25%;
      }

    }


    &--rect {
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      transform: translate(0px, 50px);
      animation-delay: .25s !important;
      margin: 10% 0 0 0;
      max-width: 85vw;

      @include media-breakpoint-down(sm) {
        //margin: 4% 8% 0 0;
      }
    }

    &--bg {
      top: 100px;
      left: 100px;
      z-index: -9;
    }
  }

  .channel-section {
    &--customers {
      margin-right: 5%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
      &-lineUp {
        z-index: -1;
        animation-delay: 1s !important;

        margin-top: -14%;
        @include media-breakpoint-down(md) {
          transform: scale(.7) !important;
          margin-top: -20%;
        }
      }
      &-lineDown {
        z-index: -1;
        animation-delay: 1s !important;
        margin-top: 9%;
        @include media-breakpoint-down(md) {
          transform: scale(.7) !important;
          margin-top:5%;
        }
      }
    }
    &--gmail {
      margin-bottom: -5%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
    }
    &--sms {
      margin-bottom: -5%;
      transform: translate(-80%, 0%);
      animation-delay: .6s !important;
    }
    &--hubspot {
      margin-bottom: -5%;
      transform: translate(-80%, 0%);
      animation-delay: .6s !important;
    }
    &--intercom {
      margin-bottom: -5%;
      transform: translate(-80%, 0%);
      animation-delay: .4s !important;
    }
    &--circle {
      transform: translate(-80%, 0%);
      animation-delay: 1.4s !important;

      &-left{
        transform: translate(-80%, 0%);
        animation-delay: 1.8s !important;
        @include media-breakpoint-down(md) {
          transform: scale(.7) !important;
        }
      }
      &-right{
        transform: translate(-80%, 0%);
        animation-delay: 2.4s !important;
        @include media-breakpoint-down(md) {
          transform: scale(.7) !important;
        }
      }
    }
    &--csm {
      margin-left: 2%;
      transform: translate(-80%, 0%);
      animation-delay: 2s !important;
    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -9;
      width: 100%;
      height: 100%;
      text-align: center;
      margin-top: -1%;
      margin-left: 18%;
      @include media-breakpoint-down(md) {
        max-width: 34vw;
        margin-top: 9%;
        margin-left: 15%;
      }
    }
  }



  //instant-insights--section
  .instant-insights--section {
    &-cursor {
      top: 0;
      right: 0;
      margin-top: 32%;
      margin-right: 28%;
      animation-delay: .8s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 2;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }

    &-notify {
      top: 0;
      right: 0;
      margin-top: 25%;
      margin-right: -20%;
      animation-delay: .8s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 2;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }

    &-rect {
      transform: translate(80%, 0%);
      animation-delay: .4s !important;
      animation-duration: .4s !important;
    }
  }


  //team-impact--section
  .team-impact--section {

    &-rect {

    }
    &-rect1 {
      top: 0;
      left: 0;
      margin-top: 11%;
      margin-left: 8%;
      animation-delay: .4s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 2;
      @include media-breakpoint-down(sm) {
        max-width: 75vw;
        margin-top: 12%;
        margin-left: 9%;
      }
    }
    &-rect2 {
      top: 0;
      left: 0;
      margin-top: 22%;
      margin-left: 8%;
      animation-delay: .8s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 2;
      @include media-breakpoint-down(sm) {
        max-width: 75vw;
        margin-top: 25%;
        margin-left: 9%;
      }
    }
    &-rect3 {
      top: 0;
      left: 0;
      margin-top: 33%;
      margin-left: 8%;
      animation-delay: 1.2s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 2;
      @include media-breakpoint-down(sm) {
        max-width: 75vw;
        margin-top: 37%;
        margin-left: 9%;
      }
    }


    &-john {
      top: 0;
      left: 0;
      margin-top: 38%;
      margin-left: 11%;
      animation-delay: 1.4s !important;
      transform: translate(0%, 80%);
      animation-duration: .4s !important;
      z-index: 4;
      @include media-breakpoint-down(sm) {
        max-width: 59vw;
        margin-top: 44%;
      }
    }
    &-margaret {
      top: 0;
      left: 0;
      margin-top: 49%;
      margin-left: 30%;
      animation-delay: 1.8s !important;
      transform: translate(0%, 80%);
      animation-duration: .4s !important;
      z-index: 4;
      @include media-breakpoint-down(sm) {
        max-width: 59vw;
        margin-top: 58%;
      }
    }
    &-anne {
      top: 0;
      left: 0;
      margin-top: 62%;
      margin-left: 24%;
      animation-delay: 2.2s !important;
      transform: translate(0%, 80%);
      animation-duration: .4s !important;
      z-index: 4;
      @include media-breakpoint-down(sm) {
        max-width: 59vw;
        margin-top: 76%;
      }
    }

    &-share {
      top: 0;
      left: 0;
      margin-top: -22%;
      margin-left: 37%;
      animation-delay: 2.4s !important;
      transform: translate(0%, 80%);
      animation-duration: .4s !important;

      z-index: 3;
      @include media-breakpoint-down(sm) {

        max-width: 23vw;
        margin-top: -27%;
      }
    }

  }

  //customize-engagement-section
  .customize-engagement-section {
    &-score {
      margin-top: 0%;
      margin-left: 20%;
      animation-delay: .4s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
    }

    &-signals {
      margin-top: 0%;
      margin-left: -30%;
      animation-delay: .8s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        max-width: 68vw;
        margin-top: 0%;
        margin-left: 0%;
      }
    }

    &-decreased {
      margin-top: 0%;
      margin-left: 0%;
      animation-delay: 1.2s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
    }

    &-increased {
      margin-top: 4%;
      margin-left: 0%;
      animation-delay: 1.6s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
    }

    &-notify {
      margin-top: 1%;
      margin-left: 0%;
      animation-delay: 2s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        max-width: 56vw;
        margin-top: 4%;
      }
    }
    &-line {
      margin-top: 10%;
      margin-left: 0%;
      animation-delay: 2s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
  }

  //playbook--section-comunication1
  .playbook--section {
    &-bg {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 20%;
      margin-right: 0%;
      z-index: -1;
    }
    &-sprite {
      position: relative;
      margin-top: 0%;
      margin-right: 0%;
      margin-bottom: 52%;
      @include media-breakpoint-down(sm) {
        margin-bottom: 60%;
      }
    }
    &-comunication1 {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 0%;
      margin-right: 0%;
      //animation-delay: .6s !important;
      //transform: translate(0%, 0%);
      //animation-duration: .6s !important;
      z-index: 1;

      //animation:
      //  animRunSlide 0.6s .6s forwards,
      //  hideSprite 0.6s 1.5s forwards !important;

    }
    &-comunication2 {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 0%;
      margin-right: 0%;
      //animation-delay: 1.5s !important;
      //transform: translate(0%, 0%);
      //animation-duration: .6s !important;
      z-index: 2;

      //animation:
      //  animRunSlide .6s 1.5s forwards,
      //  hideSprite .6s 3s forwards !important;


    }
    &-comunication3 {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 0%;
      margin-right: 0%;
      //animation-delay: 3s !important;
      //transform: translate(0%, 0%);
      //animation-duration: .6s !important;
      z-index: 3;
      //animation:
      //  animRunSlide .6s 3s forwards,
      //  hideSprite .6s 4.6s forwards !important;
    }
    &-comunication4 {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 0%;
      margin-right: 0%;
      //animation-delay: 4s !important;
      transform: translate(0%, 0%);
      //animation-duration: .6s !important;
      z-index: 4;
    }
  }

  .content-animation {
    &.has-animation {
      .playbook--section {
        &-comunication1 {
          animation:
            animRunSlide .6s .6s forwards,
            hideSprite .8s 2s forwards !important;
        }
        &-comunication2 {
          animation:
            animRunSlide 1s 1.5s forwards,
            hideSprite .8s 3.5s forwards !important;
        }
        &-comunication3 {
          animation:
            animRunSlide 1s 3s forwards,
            hideSprite .8s 5s forwards !important;
        }
        &-comunication4 {
          animation-delay: 4.6s !important;
          animation-duration: 1s !important;
        }
      }

    }

  }


  //playbook--section-portal
  .playbook--section-portal  {
    &--sprite {
      position: relative;
      margin-bottom: 70%;
      margin-top: -12%;
      text-align: right;
    }
    &--default {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 0%;
      margin-right: 0%;
      //animation-delay: .6s !important;
      //transform: translate(0%, 0%);
      //animation-duration: .6s !important;
      z-index: 3;
      //animation:
      //  animRunSlide .6s .6s forwards,
      //  hideSprite .6s 2s forwards !important;
    }
    &--hover {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 1%;
      margin-right: 1%;
      //animation-delay: 1.2s !important;
      transform: translate(0%, 0%);
      //animation-duration: .6s !important;
      z-index: 4;
    }

  }

  .content-animation {
    &.has-animation {
      .playbook--section-portal  {
        &--default {
          animation:
            animRunSlide .6s .6s forwards,
            hideSprite .6s 2s forwards !important;
        }
        &--hover {
          animation-delay: 2s !important;
          animation-duration: .6s !important;
        }

      }
    }
  }

  //playbook--section-other-automations-sprite
  .playbook--section-other-automations {
    &-sprite {
      position: relative;
      margin-bottom: 70%;
      margin-top: -12%;
    }

    &1 {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 0%;
      margin-right: 0%;
      //animation-delay: .6s !important;
      transform: translate(0%, 0%);
      //animation-duration: .6s !important;
      z-index: 4;
      //animation:
      //  animRunSlide .6s .6s forwards,
      //  hideSprite .6s 2.6s forwards !important;
    }

    &2 {
      top: 0;
      right: 0;
      left: 0;
      margin-top: 0%;
      margin-right: 0%;
      //animation-delay: 2s !important;
      transform: translate(0%, 0%);
      //animation-duration: .6s !important;
      z-index: 5;
    }
  }
  .content-animation {
    &.has-animation {
      .playbook--section-other-automations {

        &1 {
          animation:
            animRunSlide .6s .6s forwards,
            hideSprite .8s 2.5s forwards;
        }
        &2 {
          animation:
            animRunSlide .8s 2.5s forwards;
        }
      }
    }
  }


    //playbook--section-note-taking
  .playbook--section-note-taking {
    &-sprite {
      margin-bottom: 58%;
    }
    &-rect {
      top: 0;
      right: 0;
      margin-top: 0%;
      margin-right: 0%;
      //animation-delay:.6s !important;
      transform: translate(80%, 0%);
      //animation-duration: .4s !important;
      margin-bottom: 3%;
    }
    &-sidebar {
      top: 0;
      right: 0;
      margin-top: -8%;
      margin-right: 13%;
      //animation-delay: 1s !important;
      transform: translate(80%, 0%);
      //animation-duration: .6s !important;
      //filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.12));
      //border-radius: 15px;
      //display: inline-flex;
      //animation:
      //  animRunSlide .6s 1.5s forwards,
      //  addBorder .6s 2s forwards !important;
      @include media-breakpoint-down(sm) {
        max-width: 27vw;
      }
    }
    @keyframes addBorder {
      0% {
        border: 2px solid #ffffff;
      }
      100% {
        border: 2px solid #006CBB;
      }
    }
  }
  .content-animation {
    &.has-animation {
      .playbook--section-note-taking {
        &-rect {
          animation-delay: .6s !important;
          animation-duration: .4s !important;
        }
        &-sidebar {
          animation-delay: 1s !important;
          animation-duration: .6s !important;
        }
      }
    }
  }




  //drive-product--section-cursor

  .drive-product--section {
    &-rect {
      animation-delay: .4s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
    }
    &-cursor {
      top: 0;
      right: 0;
      margin-top: 20%;
      margin-right: -3%;
      animation-delay:.8s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
    }
    &-notify {
      top: 0;
      right: 0;
      margin-top: -11%;
      margin-right: -14%;
      animation-delay: 1.4s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
    }
    &-bg {
      top: 0;
      right: 0;
      margin-top: 0%;
      margin-right: -25%;
      z-index: -9;
    }
  }


  //smart-signals--section
  .smart-signals {
    &--section {
      .tab-content {
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
          height: 450px;
        }
        .tab-pane {
          @include media-breakpoint-up(md) {
            width: 100%;
          }
        }
      }
    }
  }

  //ai-automation--section
  .ai-automation{
    &--section {
      .tab-content {
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
          height: 510px;
        }
        .tab-pane {
          @include media-breakpoint-up(md) {
            width: 100%;
          }
        }
      }
    }
  }

  //crm-opportunities--section

  .crm-opportunities {
    &--section {
      &--row {
        margin-top: 10%;
        position: relative;
      }
      &-hubspot {
        text-align: left;
        top: 0;
        left: 0;
        margin-top: 0%;
        margin-left: 0%;
        animation-delay: .8s !important;
        transform: translate(80%, 0%);
        animation-duration: .4s !important;
        &--line {
          top: 0;
          left: 0;
          margin-top: 7%;
          margin-left: 7%;
          animation-delay: 1.4s !important;
          transform: translate(0%, 0%);
          animation-duration: .4s !important;
          z-index: -1;
        }
      }

      &-custify {
        top: 0;
        right: 0;
        margin-top: 0%;
        margin-right: -20%;
        animation-delay: .4s !important;
        transform: translate(80%, 0%);
        animation-duration: .4s !important;
        &--line {
          top: 0;
          left: 0;
          margin-top: 14%;
          margin-left: 49%;
          animation-delay: 2.6s !important;
          transform: translate(0%, 0%);
          animation-duration: .4s !important;
          z-index: -1;
        }
      }
      &-chart {
        text-align: right;
        top: 0;
        right: 0;
        margin-top: 0%;
        margin-right: 0%;
        animation-delay: 2s !important;
        transform: translate(80%, 0%);
        animation-duration: .4s !important;
      }

      &-salesforce {
        text-align: left;
        top: 0;
        left: 0;
        margin-top: -1%;
        margin-left: 0%;
        animation-delay: 1.6s !important;
        transform: translate(80%, 0%);
        animation-duration: .4s !important;
        &--line {
          top: 0;
          left: 0;
          margin-top: 54%;
          margin-left: 15%;
          animation-delay: 2.2s !important;
          transform: translate(0%, 0%);
          animation-duration: .4s !important;
          z-index: -1;
        }
      }

    }
  }


  //calculated-metrics--section-patterns
  .calculated-metrics--section {
    &-row {
      margin-top: 17%;
      margin-left: 10%;
    }
    &-rect {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 10%;
      animation-delay: .4s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      z-index: 9;
    }

    &-form {
      top: 0;
      right: 0;
      margin-top: 28%;
      margin-right: -18%;
      animation-delay: .8s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 9;
    }

    &-insights {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      animation-delay: 1.2s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 9;
    }

    &-patterns {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      animation-delay: 1.4s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 9;
    }

    &-trends {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      animation-delay: 1.6s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 9;
    }
    &-line {
      top: 0;
      left: 0;
      margin-top: 22%;
      margin-left: -3%;
      animation-delay: 2s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: -1;
    }
  }

  @keyframes play-sprite {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 0px -3764px;
    }
  }

  @keyframes fade-effect {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }

  //optimize-allocation--section
  .optimize-allocation {
    &--section {

    }
    &--sprite {
      padding-top: 70%;

    }
  }

  //ai-generated--section-rects

  .ai-generated--section {
    &-rects {
      padding-bottom: 75%;
      margin-left: 8%;
      margin-top: 13%;
      animation: hideSprite 0.6s 2.5s forwards !important;
    }
    &-rect1 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 1;
      animation-delay: .4s !important;
      transform: translate(0%, 0%);
      animation-duration: .6s !important;
    }
    &-rect2 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 1;
      animation-delay: 1.2s !important;
      transform: translate(0%, 0%);
      animation-duration: .6s !important;
    }
    &-rect3 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 1;
      animation-delay: 2s !important;
      transform: translate(0%, 0%);
      animation-duration: .6s !important;
    }
    @keyframes moveCursor {
      0% {
        margin-top: 28%;
        margin-right: 8%;
      }
      50% {
        margin-top: 50%;
        margin-right: 48%;
      }
      100% {
        margin-top: 63%;
        margin-right: -5%;
      }
    }

    &-cursor {
      top: 0;
      right: 0;
      margin-top: 28%;
      margin-right: 8%;
      z-index: 1;
      transform: translate(80%, 0%);

      animation:
        animRunSlide 0.6s .4s forwards,
        moveCursor 1.8s 1s forwards,
        hideSprite 0.6s 2.5s forwards !important;
    }
    //&-badge {
    //  top: 0;
    //  left: 0;
    //  margin-top: 0%;
    //  margin-left: 8%;
    //  z-index: -2;
    //  transform: translate(0%, 0%);
    //  animation:
    //    animRunSlide .6s 2.8s forwards,
    //    hideSprite .6s 3.8s forwards !important;
    //}
    &-badge2 {
      top: 0;
      left: 0;
      margin-top: 1%;
      margin-left: 8%;
      z-index: -1;
      animation-delay: 2.6s !important;
      transform: translate(0%, 0%);
      animation-duration: .8s !important;

    }
  }

  //optimize-allocation--bg
  .optimize-allocation {
    &--sprite1 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 1;
      animation-delay: 1s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;

    }
    &--sprite2 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 2;
      animation-delay: 2s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;

    }
    &--sprite3 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 3;
      animation-delay: 3s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;

    }
    &--sprite4 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 4;
      animation-delay: 4s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;

    }
    &--sprite5 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 5;
      animation-delay: 5s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;

    }
    &--sprite6 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 6;
      animation:
        animRunSlide 0.4s 6s forwards,
        hideSprite 0.5s 7s forwards !important;
      transform: translate(0%, 0%);

    }
    @keyframes hideSprite {
      from {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
      to {
        opacity: 0;
        visibility: hidden;
      }
    }
    &--sprite7 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 7;
      transform: translate(0%, 0%);
      animation:
        animRunSlide 0.4s 7s forwards,
        hideSprite 0.5s 8s forwards !important;
    }
    &--sprite8 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 8;
      animation-delay: 9s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;

    }
    &--sprite9 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 9;
      animation-delay: 10s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;
    }
    &--sprite10 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: 0%;
      z-index: 10;
      animation-delay: 11s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;
    }

    &--bg {
      top: 0;
      right: 0;
      margin-top: 16%;
      margin-right: -5%;
      z-index: -1;
    }
  }

  //treat-individuals--section

  .treat-individuals--section{
    &-segment {
      top: 0;
      margin-top: 15%;
      animation-delay: .4s !important;
      transform: translate(-80%, 0%);
      animation-duration: .4s !important;
      max-width: 85vw;
      z-index: 5;
      @include media-breakpoint-down(sm) {
        margin-top: 55%;
      }
    }
    &-pop {
      top: 0;
      right: 0;
      margin-top: -10%;
      margin-right: 6%;
      animation-delay: .8s !important;
      transform: translate(-80%, 0%);
      animation-duration: .4s !important;
      z-index: 9;
      @include media-breakpoint-down(sm) {
        margin-right: -1%;
        margin-top: 10%;
        max-width: 69vw;
      }
    }
    &-bg1 {
      top: 0;
      left: 0;
      margin-top: 0%;
      margin-left: -2%;
      z-index: 1;
    }
    &-bg2 {
      bottom: 0;
      right: 0;
      margin-bottom: 0%;
      margin-right: -2%;
      z-index: 1;
    }
  }

  //generate-summaries--section-text

  .generate-summaries--section {
    &-frame {
      animation-delay: .4s !important;
      transform: translate(0%, 0%);
      animation-duration: .4s !important;
    }
    &-text {
      top: 0;
      right: 0;
      margin-top: 9%;
      margin-right: -2%;
      animation-delay: .6s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        margin-right: 3%;
        max-width: 79vw;
      }
    }
  }

  //integrate-everything--section--sprites
  .integrate-everything--section {
    &--sprites {
      position: relative;
      min-height: 500px;
      text-align: center;
    }

    @keyframes moveIntercom {
      0% {
        margin-top: 33%;
        margin-left: -68%;
        transform: scale(1);
      }
      50% {
        margin-top: 52%;
        margin-left: -44%;
        transform: scale(.7);
      }
      100% {
        margin-top: 33%;
        margin-left: -68%;
        transform: scale(1);
      }
    }
    &--intercom {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 33%;
      margin-left: -68%;
      //animation-delay: 2s !important;
      transform: translate(0%, -80%) scale(1);

    }
    @keyframes moveSalesforce {
      0% {
        margin-top: 13%;
        margin-right: -74%;
      }
      50% {
        margin-top: 38%;
        margin-right: -40%;
        transform: scale(.7);
      }
      100% {
        margin-top: 13%;
        margin-right: -74%;
      }
    }
    &--salesforce {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 13%;
      margin-right: -74%;
      transform: translate(0%, -80%);

    }
    @keyframes moveHubspot {
      0% {
        margin-top: 15%;
        margin-left: -40%;
      }
      50% {
        margin-top: 40%;
        margin-left: -38%;
        transform: scale(.75);
      }
      100% {
        margin-top: 15%;
        margin-left: -40%;
      }
    }
    &--hubspot {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 15%;
      margin-left: -40%;

    }
    @keyframes moveLogo {
      0% {
        margin-top: 4%;
        margin-left: -6%;
      }
      50% {
        margin-top: 31%;
        margin-left: -11%;
        transform: scale(.8);
      }
      100% {
        margin-top: 4%;
        margin-left: -6%;
      }
    }
    &--logo {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 4%;
      margin-left: -6%;

    }
    @keyframes moveRect {
      0% {
        margin-top: 2%;
        margin-right: -35%;
      }
      50% {
        margin-top: 27%;
        margin-right: -20%;
        transform: scale(.7);
      }
      100% {
        margin-top: 2%;
        margin-right: -35%;
      }
    }
    &--react {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 2%;
      margin-right: -35%;

    }

    &--cube {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 40%;
      margin-right: 0%;
      transform: translate(0%, 80%);
    }
    @keyframes moveCube2 {
      0% {
        transform: rotate(-90deg) scale(0.7);
      }
      100% {
        transform: rotate(0deg) scale(1);
      }
    }
    &--cube2 {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 10%;
      margin-right: 0%;
      transform: translate(0%, 80%) rotate(-90deg) scale(0.7);

    }
    &--bg {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 14%;
      margin-right: -44%;
      z-index: -1;
    }

  }


  .content-animation {
    &.has-animation {
      //integrate-everything--section--sprites
      .integrate-everything--section {
        &--intercom {
          animation:
            animRunSlide .6s 3s forwards,
            moveIntercom 5s 5s ease-in-out infinite;
        }
        &--salesforce {
          animation:
            animRunSlide .6s 3s forwards,
            moveSalesforce 5s 5s ease-in-out infinite;
        }
        &--hubspot {
          animation:
            animRunSlide .6s 3s forwards,
            moveHubspot 5s 5s ease-in-out infinite;
        }
        &--logo {

          animation:
            animRunSlide .6s 3s forwards,
            moveLogo 5s 5s ease-in-out infinite;
        }
        &--react {
          animation:
            animRunSlide .6s 3s forwards,
            moveRect 5s 5s ease-in-out infinite;
        }
        &--cube {
          animation:
            animRunSlide 0.6s 1s forwards!important;
        }
        &--cube2 {
          transform: translate(0%, 80%) rotate(-90deg) scale(0.7);
          animation:
            animRunSlide .4s 10s forwards !important;
        }

      }
    }
  }

  //playbook--section
  .playbook--section {
    &-bg-blue {
      position: relative;
      background-color:#F0F9FF;
      padding: 10px;
      margin-bottom: 30px;
      border-radius: 25px;
      text-align: center;
      overflow: hidden;
      &--header {
        padding: 30px;
        @include media-breakpoint-down(sm) {
          padding: 15px 10px;
        }
      }

      &--title {
        font-size: 20px;
        margin-bottom: 10px !important;
        font-weight: $font-weight-semibold;
        font-family: $font-montserrat;
        color: #444D54;
        a {
          color: #444D54;
        }
      }
    }
  }
}
