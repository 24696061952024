// Churn table

.churn-table {
  background: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  margin: 0 0 40px;

  .table-responsive {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;

    .table {
      border-collapse: collapse;
      border: 0;
      margin: 0;
      padding: 0;

      @include media-breakpoint-down(sm) {
        table-layout: fixed;
      }

      thead {
        th {
          border-bottom: 0;
          text-transform: uppercase;
          font-size: 12px;

          @include media-breakpoint-up(md) {
            font-size: 14px;
          }
        }
      }

    }
  }

  @include media-breakpoint-up(xl) {
    padding: 39px;
  }

  th, td {
    border: 1px solid #ededed;
    text-align: center;
    font-weight: bold;
    color: #a1a1a6;
    vertical-align: middle;
    padding: 5px 10px;

    @include media-breakpoint-up(xl) {
      padding: 20px;
    }
  }

  td {
    font-weight: normal;
    color: #6d6e7a;
    font-size: 16px;
  }

  tbody {
    tr {
      td:first-child {
        text-align: right;
        font-size: 15px;
        width: 230px;

        @include media-breakpoint-up(xl) {
          padding-right: 20px;
          padding-left: 10px;
        }
      }
    }
  }

  .table-content {
    background-color: #fafafa;
    font-weight: bold;
    color: #6c6c79;
  }

  .icon-textbox {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .icon-textbox .icon-textbox--icon {
    display: none;
    color: #92a6b0;
    font-size: 15px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    padding: 0 10px;
    background-color: #fff;
    position: relative;
    width: 35px;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .icon-textbox .churn-textbox {
    border: 1px solid #e6e6e6;
    font-weight: bold;
    color: #6c6c79;
    width: 100%;
    padding: 5px 10px;
    height: 38px;
    outline: 0;
    box-shadow: none;
    text-align: center;
  }

  .icon-textbox .percentage {
    position: absolute;
    font-size: 12px;
    color: #a1a1a6;
    top: 10px;
    right: 15px;
  }

  .table {
    .churn--current {
      color: #ff9934;
    }

    .churn--projection {
      color: #35a1eb;
    }

    .churn--col {
      @include media-breakpoint-up(md) {
        width: 245px;
      }
    }
  }
}

.churn-chart {
  padding-top: 20px;

  &-inner {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 10px;

    @include media-breakpoint-up(xl) {
      padding: 25px;
    }
  }
}
