// Partner program
// ======================

// Vars
// ----------------------

// Base
// ----------------------

.partner-program {
  .page-header {
    @include media-breakpoint-up(md) {
      margin-bottom: 90px;
    }
  }

  .form-section {
    position: relative;
    margin: 0 0 80px;
    padding: $vspacing--mob 0;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      padding: $vspacing 0 100px;
    }
  }

  .page-faq {
    background: none;
    padding-top: 0;

    @include media-breakpoint-up(md) {
      padding-top: 60px;
    }
  }

  .accordion {
    .card-header {
      background-color: transparent;
    }
  }

  .page-header {
    .lead {
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        margin-bottom: 70px;
      }
    }

    .btn-primary {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .features-description {
    @include media-breakpoint-up(md) {
      max-width: 480px;
    }
  }

  .container--abs {
    position: absolute;
    bottom: -150px;
    left: -150px;
    z-index: 0;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    > span {
      display: inline-block;
    }

    svg {
      margin: 0;
      display: block;
    }
  }

  .card-testimonials {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    margin-top: 50px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    p {
      font-size: 20px;
      font-weight: 300;
      font-style: italic;
    }

    small {
      display: block;
      text-transform: uppercase;
      color: #006CBB;
      margin: 0 0 24px;
      font-size: 13px;
    }

    h5 {
      margin: 0 0 8px;
      line-height: 24px;
    }

    picture {
      display: block;
      margin: 0 0 24px;
      line-height: 0;
    }
  }
}

.partners-benefits {
  .benefits-col {
    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        border-right: 1px solid #E4E7EA;
        border-left: 1px solid #E4E7EA;
      }
    }
  }

  .section-title {
    @include media-breakpoint-up(md) {
      margin-bottom: 50px;
    }
  }
}

.benefits {
  @include media-breakpoint-up(md) {
    border: 1px solid #E4E7EA;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    padding: 60px 0;
  }

  &-item {
    padding-top: 200px;
    position: relative;
    text-align: center;
    margin: 0 0 35px;

    @include media-breakpoint-up(md) {
      margin: 0;
    }

    @include media-breakpoint-up(md) {
      padding-right: 40px;
      padding-left: 40px;
    }

    .benefits-image {
      display: block;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    h4 {
      line-height: 26px;
      margin: 0 0 20px;
    }
  }
}

.image--175 {
  width: 175px;
}

.image--190 {
  width: 190px;
}

.image--205 {
  width: 205px;
}

.features-description {
  p {
    margin: 0 0 24px;
    color: $light-gray-600;
  }
}

#partner-form {
  h4 {
    margin: 0 0 30px;
  }
}
