// Portal Page
// ======================

// Vars
// ----------------------

// Base
// ----------------------

.portal-page {

  .main {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
    .content-section {
      //border: 1px solid blue;

    }
  }

  .content-animation {
    //border: 1px solid red;
  }
  //
  .compare {
    display: grid;
    place-items: center;
  }
  .container-compare {
    display: grid;
    place-content: center;
    position: relative;
    overflow: hidden;
    border-radius: .5rem;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }
  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    display: block;
  }
  .image-before {
    position: absolute;
    inset: 0;
    width: var(--position);
    height: 100%;
    object-fit: cover;
    object-position: left;
  }
  .slider {
    position: absolute;
    inset: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  .slider:focus-visible ~ .slider-button {
    outline: 5px solid black;
    outline-offset: 3px;
  }
  .slider-line {
    position: absolute;
    inset: 0;
    width: .2rem;
    height: 100%;
    background-color: #fff;
    left: var(--position);
    transform: translateX(-50%);
    pointer-events: none;
  }
  .slider-button {
    position: absolute;
    margin-bottom: 2%;
    padding: 0 .5rem;
    display: grid;
    place-items: center;
    bottom: 0;
    left: var(--position);
    transform: translate(-50%, 0%);
    pointer-events: none;
    svg {
      @include media-breakpoint-down(sm) {
        max-width: 33vw;
      }
    }
  }
  //




  //
  .get-started-new--picture,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }
  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }

    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }
    .get-started-new--picture {
      transform: translate(150px, 0);
    }
    .graphic__get-started--right {
      transform: translate(50px, 0);
    }
    .get-started-body {
      transform: translate(-50px, 0);
    }
  }
  //
  .content-animation {
    .graphic-item {
      visibility: hidden;
      opacity: 0;
      position: relative;
    }
    &.has-animation {

      .graphic-item {
        animation: animRunSlide .4s linear forwards;
      }
      .transparency {
        &--task-line {
          animation: animHeightFull 1s linear forwards 1.4s !important;
        }
      }
      .improve-task {
        &--line-green,
        &--line-orange {
          animation: animHeightFull 1s linear forwards 1.6s !important;
        }
      }
      .self-service {
        &--line {
          &-green,
          &-orange,
          &-blue {
            animation: animHeightFull 1s linear forwards 1.6s !important;
          }
        }
      }
    }
  }
  //


  .get-customer-picture {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  //
  .hero {
    &--animation {
      @include media-breakpoint-down(md) {
        //transform: scale(.85);
      }
    }
    &--img {
      z-index: 1;
      animation-delay: .3s !important;
      @include media-breakpoint-down(xs) {
        max-width: 45vw;
        margin: 0 auto;
      }
    }

    //left
    &--newfile {
      top: 50%;
      left: 50%;
      transform: translate(80%, 0%);
      margin-top: -15%;
      margin-left: -50%;
      animation-delay: .4s !important;
      @include media-breakpoint-down(xs) {
        max-width: 27vw;
      }
    }
    &--click {
      top: 50%;
      left: 50%;
      transform: translate(80%, 0%);
      margin-top: -5%;
      margin-left: -38%;
      animation-delay: 2s !important;
      @include media-breakpoint-down(xs) {
        max-width: 15vw;
      }
    }
    &--documents {
      top: 50%;
      left: 50%;
      transform: translate(80%, 0%);
      margin-top: 7%;
      margin-left: -49%;
      animation-delay: 2.8s !important;
      @include media-breakpoint-down(xs) {
        max-width: 24vw;
      }
    }
    &--kpi {
      top: 50%;
      left: 50%;
      transform: translate(80%, 0%);
      margin-top: 16%;
      margin-left: -40%;
      animation-delay: 1.2s !important;
      @include media-breakpoint-down(xs) {
        max-width: 16vw;
      }
    }

    //right
    &--report {
      top: 50%;
      left: 50%;
      transform: translate(-80%, 0%);
      margin-top: -8%;
      margin-left: 17%;
      animation-delay: 1.6s !important;
      @include media-breakpoint-down(xs) {
        max-width: 24vw;
        margin-left: 22%;
      }
    }
    &--status {
      top: 50%;
      left: 50%;
      transform: translate(-80%, 0%);
      margin-top: -1%;
      margin-left: 15%;
      animation-delay: 3s !important;
      @include media-breakpoint-down(xs) {
        max-width: 34vw;
        margin-left: 20%;
      }
    }
    &--tasks {
      top: 50%;
      left: 50%;
      transform: translate(-80%, 0%);
      margin-top: 7%;
      margin-left: 15%;
      animation-delay: .8s !important;
      @include media-breakpoint-down(xs) {
        max-width: 22vw;
        margin-left: 20%;
      }
    }
    &--chat {
      top: 50%;
      left: 50%;
      transform: translate(-80%, 0%);
      margin-top: 17%;
      margin-left: 18%;
      animation-delay: 2.4s !important;
      @include media-breakpoint-down(xs) {
        max-width: 18vw;
        margin-left: 23%;
      }
    }
  }


  //
  .transparency {
    &--task-minute {
      margin-right: 20%;
      transform: translate(0px, -100px);
      animation-delay: .3s !important;
      @include media-breakpoint-down(sm) {
        max-width: 94vw;
      }
    }

    &--task-meetings {
      margin-right: -35px;
      margin-top: -10px;
      transform: translate(0px, -100px);
      animation-delay: .9s !important;
      @include media-breakpoint-down(sm) {
        max-width: 94vw;
      }
    }

    &--task-email {
      margin-right: 0;
      margin-top: 5%;
      transform: translate(0px, -100px);
      animation-delay: .6s !important;
      @include media-breakpoint-down(sm) {
        max-width: 100vw;
      }
    }

    &--task-note {
      margin-top: 4%;
      margin-right: 0;
      transform: translate(0px, -100px);
      animation-delay: 1.2s !important;
      @include media-breakpoint-down(md) {
        max-width: 60vw;
        margin-left: 30%;
      }
    }

    &--task-line {
      top: 0;
      left: 0;
      right: 0;
      margin-top: 5%;
      margin-left: 22%;
      z-index: -1;
      overflow: hidden;
      height: 0;
      @include media-breakpoint-down(md) {
        max-width: 21vw;
      }
      @include media-breakpoint-down(sm) {
        max-width: 40vw;
      }
    }

    &--task-bg {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      margin-right: -25%;
      z-index: -9;
    }

  }

  //
  .improve-task {
    &--animation {

      @include media-breakpoint-down(md) {
        transform: scale(.8);
      }
    }
    &--tasks {
      margin-left: -10%;
      transform: translate(0px, -50px);
      animation-delay: .3s !important;
    }
    &--deliver{
      margin-top: 5%;
      margin-left: -5%;
      transform: translate(0px, -80px);
      animation-delay: .6s !important;
    }
    &--import{
      margin-top: 10px;
      margin-left: -40%;
      transform: translate(-80px, 0px);
      animation-delay: .9s !important;
    }
    &--integration {
      margin-top: 10px;
      margin-left: -5%;
      transform: translate(80px, 0px);
      animation-delay: 1.2s !important;
    }
    &--done {
      margin-top: 5%;
      margin-right: -50%;
      transform: translate(0px, -80px);
      animation-delay: 1.5s !important;
    }
    &--line {
      &-orange{
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        margin-top: 45%;
        margin-right: -32%;
        animation-delay: 1.5s !important;
        height: 0;
        overflow: hidden;
      }
      &-green {
        top: 0;
        left: 0;
        right: 0;
        margin-left: -35%;
        margin-top: 10%;
        z-index: -1;
        animation-delay: .9s !important;
        height: 0;
        overflow: hidden;

      }
    }
    &--bg {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      margin-top: 15%;
      margin-right: -15%;
      z-index: -9;
    }
  }
  //
  .self-service {
    &--animation {
      @include media-breakpoint-down(md) {
        transform: scale(.85);
      }
    }

    &--note {
      margin-left: -30%;
      transform: translate(0px, -50px);
      animation-delay: .4s !important;
    }

    &--email {
      margin-top: 5%;
      margin-right: -30%;
      transform: translate(0px, -50px);
      animation-delay: .8s !important;
    }
    &--file {
      transform: translate(0px, -50px);
      animation-delay: 1.2s !important;
    }

    &--block-file {
      margin-top: 8%;
      transform: translate(0px, -50px);
      animation-delay: 1.6s !important;
    }
    &--block-meetings {
      margin-top: 5px;
      transform: translate(0px, -50px);
      animation-delay: 2.2s !important;
    }
    &--block-notes {
      margin-top: 5px;
      transform: translate(0px, -50px);
      animation-delay: 1.9s !important;
    }
    &--block-task {
      margin-top: 5px;
      transform: translate(0px, -50px);
      animation-delay: 2.5s !important;
    }
    &--block-kpi {
      margin-top: 5px;
      transform: translate(0px, -50px);
      animation-delay: 2.8s !important;
    }
    &--line {
      &-green {
        top: 0;
        left: 0;
        right: 0;
        margin-right: -15%;
        margin-top: calc(44px + 2%);
        z-index: -1;
        overflow: hidden;
        height: 0;
        @include media-breakpoint-up(sm) {
          margin-right: -20%;
        }
      }
      &-blue {
        top: 0;
        left: 0;
        right: 0;
        margin-top: calc(88px * 2 - 44px + 9%);
        z-index: -1;
        overflow: hidden;
        height: 0;
        @include media-breakpoint-up(sm) {
          margin-left: 0;
        }
      }
      &-orange {
        top: 0;
        left: 0;
        right: 0;

        margin-top: calc(88px * 3 - 44px + 7%);
        margin-left: -15%;
        z-index: -1;
        overflow: hidden;
        height: 0;
        @include media-breakpoint-up(sm) {
          margin-left: -20%;
        }

      }

    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 10%;
      z-index: -9;
    }
  }
  //
  .right-information {

    &--rect {
      z-index: -1;
      top: 0;
      left: 0;
      margin-top: 10%;
      margin-left: 8%;
      animation-delay: .3s !important;
    }
    &--send {
      margin-top: -5%;
      margin-left: 15%;
      transform: translate(-80px, 0px);
      animation-delay: .6s !important;
    }
    &--kpi {
      margin-top: -5%;
      margin-left: 35%;
      transform: translate(80px, 80px);
      animation-delay: .9s !important;
    }
    &--onboard {
      transform: translate(-80px, -80px);
      animation-delay: .3s !important;
    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 10%;
      margin-left: -25%;
      z-index: -9;
    }
  }
  //
  .others {

    &--bg {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      z-index: -9;
    }
  }
  //
  .nav {
    &--vertical-left {
      .nav-link {
        color: $blue-900;
        border-radius: 5px;
        font-size: 20px;
        font-family: $font-montserrat;
        margin: .5rem 0;
        padding: 1rem 0 1rem 2rem;
        &.active {
          font-weight: bold;
          background: rgba($blue, .05);
          border-left: 4px solid $blue;

        }
      }
    }
    &--item {
      @include border-radius-separate(16px, 0px, 16px, 0px);
      background: rgba($blue, .1);
      padding: $nav-link-padding-y 0;
      @include media-breakpoint-up(sm) {
        padding: $nav-link-padding-y;
      }
      line-height: 1.5;
      &:last-child {
        @include border-radius-separate(0px, 16px, 0px, 16px);

      }
    }
    &--link {
      color:$blue;
      font-family: $font-montserrat;
      @extend %font-size-xsm;
      padding: $nav-link-padding-y;
      @include media-breakpoint-up(sm) {
        padding: $nav-link-padding-y 1.5rem;
      }
      border-radius: 8px;
      font-weight: bold;
      text-decoration: none;
      line-height: 1;
      &.active {
        color:$white;
        background: $blue;
      }
    }
  }



}
