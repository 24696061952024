// Footer
// ======================

// Vars
// ----------------------

$footer-bg: $dark-blue;
$footer-border: #383A4F;
$footer-padding: 64px 0;
$footer-padding--mob: 56px 0 40px;
$color-footer-text: #6A6C83;

// Base
// ----------------------

.footer {
  @extend %font-size-sm;

  background: $footer-bg;
  padding: $footer-padding;
  color: $color-footer-text;

  @include media-breakpoint-down(sm) {
    padding: $footer-padding--mob;
  }

  .secondary-title {
    @extend %font-size-xl;

    font-weight: $font-weight-semibold;
    color: #fff;
    margin: 0 0 20px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px !important;
    }
  }

  .row {
    > div {
      @include media-breakpoint-down(md) {
        margin: 0 0 30px;
      }
    }
  }

  &-bottom {
    border-top: 1px solid $footer-border;
    padding-top: 30px;
    margin-top: 60px;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      flex-direction: column;
      align-items: center;
    }
  }

  .icon {
    &--iso {
      margin: 0 0 25px;
      @include media-breakpoint-up(md) {
        margin: 15px 0 0;
      }
      @include media-breakpoint-up(lg) {
        margin: 0 0 0 33px;
      }
    }
    &--soc {
      margin: 0 0 25px 25px;
      @include media-breakpoint-up(md) {
        margin: 15px 0 0 25px;
      }
      @include media-breakpoint-up(lg) {
        margin: 0 0 0 35px;
      }
      svg {
        width: 74px;
        height: 74px;
      }
      .grey {
        display: block;
      }
      .color {
        display: none;
      }
      &:hover {
        .color {
          display: block;
        }
        .grey {
          display: none;
        }
      }
    }
  }

  .bottom__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      align-items: inherit;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }
}

// Social links
//
.social-links {
  list-style: none;

  li {
    float: left;
    margin: 0 5px;
  }

  a {
    display: block;
    width: 30px;
    height: 30px;
    color: #6A6C83;

    svg {
      * {
        transition: all 0.3s;
      }
    }

    &:hover {
      color: #fff;
    }
  }
}
