// Forms
// ======================

// Vars
// ----------------------

$placeholder-color: #949A9E;
$border-color: #DADDE2;

// Base
// ----------------------

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.form-group {
  margin: 0 0 24px;
  padding: 0;

  label,
  .form-control--label {
    margin-bottom: 8px;
    color: $blue-900;
  }
}

.form-control {
  border: 2px solid $border-color;
  border-radius: 3px;
  margin: 0;
  height: 48px;
  transition: all 0.3s;

  &::placeholder {
    color: $placeholder-color;
  }

  &:focus {
    border-color: $blue-900;
    outline: 0;
    box-shadow: none;
  }

  &--file {
    display: none;
  }

  &--fileoutput {
    display: block;
    width: 100%;
    color: $placeholder-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 48px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.has-error {
    border-color: $red;
  }
}

textarea.form-control {
  height: 90px;
}

.error-feedback {
  color: $red;
  font-size: 12px;
  line-height: 14px;
  padding-top: 4px;
  text-align: right;
  display: none;

  &.has-error {
    display: block;
  }
}

.card {
  .form-row {
    @include media-breakpoint-up(lg) {
      @include media-breakpoint-up(md) {
        margin-right: -28px;
        margin-left: -28px;
      }
    }

    > [class*="col-"] {
      @include media-breakpoint-up(lg) {
        padding-right: 28px;
        padding-left: 28px;
      }
    }
  }
}

.form-group---material {
  position: relative;
  padding-bottom: 0;

  label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: .5em;
    transition: 0.2s ease all;
    color: #ccc;
  }
  input,
  .select-text {
    background-color: transparent;
    padding: .5em 0;
    line-height: 1;
    border: 0;
    display: block;
    width: 100%;
    margin-top: 2.5em;
    border-bottom: 2px solid rgba(0,0,0, 0.12);
    appearance: none;
    -webkit-appearance:none;
    &:focus,
    &:valid {
      outline: none;
      border-bottom: 2px solid $blue;
      ~ label {
        color: $blue;
        font-size: 0.875em;
        top: -1.25em;
      }
    }
    &:focus {
      outline: none;
    }
  }

  select,
  option {
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5;
  }
  &.select {
    position: relative;
    width: 100%;
    margin-top: 2.5em;
    &:after {
      position: absolute;
      top: 18px;
      right: 10px;
      width: 0;
      height: 0;
      padding: 0;
      content: '';
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid rgba(0, 0, 0, 0.3);
      pointer-events: none;
    }
    &-highlight {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
    }
  }
}
