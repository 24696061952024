// Buttons
// ======================

// Base
//

.icon {
  display: inline-block;

  &-fullstack {
    background: url(../images/icons/icon-careers.png) no-repeat;
    width: 64px;
    height: 64px;
  }

  &-phone {
    background: url(../images/icons/icon-phone.png) no-repeat;
    width: 20px;
    height: 19px;
  }

  &-file {
    background: url(../images/icons/icon-file.png) no-repeat;
    width: 20px;
    height: 21px;
  }
  &-check {
    background: url(../images/pricing/check-mark.svg) no-repeat;
    width: 10px;
    height: 7.46px;
  }
  &-check-circle {
    background: url(../images/pricing/check-mark-circle.svg) no-repeat;
    width: 28px;
    height: 28px;
  }
  &-arrow-down {
    background: url(../images/pricing/learn-more-arrow.svg) no-repeat;
    width: 5.5px;
    height: 11px;
  }
  &-plus {
    background: url(../images/pricing/plus.svg) no-repeat;
    width: 16px;
    height: 16px;
  }
  &-line {
    background: url(../images/pricing/minus.svg) no-repeat;
    width: 16px;
    height: 2px;
  }
  &-full-stack {
    background: url(../images/svg/icon-fullstack.svg) no-repeat;
    width: 64px;
    height: 64px;
  }
}

.svg-icon {
  line-height: 0;
  display: inline-block;

  &.icon-info {
    svg {
      * {
        transition: opacity 0.3s;
      }
    }

    &:hover {
      svg * {
        fill: #383a4f !important;
        opacity: 1;
      }
    }
  }

  &.-popover {
    cursor: pointer;
  }
}

.icon-svg {
  display: inline-block;
  line-height: 0;

  svg {
    * {
      transition: all 0.5s;
    }
  }

  &--menu {
    width: 32px;
    height: 32px;
    color: $blue;
  }

  &--circle-fill {
    fill: rgba($blue, 0.1) !important;
  }

  &--circle-fill-hover {
    fill: $blue !important;
    opacity: 1;
  }

  &--item-fill {
    fill: $blue !important;
  }

  &--item-fill-hover {
    fill: #fff !important;
  }
}
