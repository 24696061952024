// Main
// ======================

// Vars
// ----------------------

$cta-bg: #4b89fc;
$cta-bg-secondary: #0066b1;
$header-circle-background: $blue;
$page-header-vspacing: 120px;
$page-header-vspacing--mobile: 70px;

// Base
// ----------------------

//Product page text format
.product-top-info,
.get-customer,
.product-adoption,
.customer-lifecycle,
.get-started,
.more-features {
  .lead {
    font-size: 18px;
    line-height: 24px;
    color: $light-gray-800;
  }
}

.graphic-circle {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 400px;
  overflow: hidden;
  z-index: -1;

  @include media-breakpoint-up(md) {
    height: 100vh;
  }

  &__item {
    background: $header-circle-background;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    opacity: 0.04;
    width: 515px;
    height: 515px;
    transform: translate(50%, -50%);
    z-index: -1;

    @include media-breakpoint-up(md) {
      width: 1300px;
      height: 1300px;
      transform: translate(520px, -836px);
    }
  }

}

.page-header {
  margin-bottom: $page-header-vspacing--mobile;

  @include media-breakpoint-up(lg) {
    margin-bottom: $page-header-vspacing;
  }

  h1 {
    margin: 0;
    text-transform: capitalize;
  }

  h1 + .lead {
    @include px-rem('padding-top', 24);
  }

  .page-subtitle {
    color: #006CBB;
    font-size: 18px;
    font-weight: 600;
  }
}

.section-header {
  @include px-rem('margin-bottom', 30);

  @include media-breakpoint-up(lg) {
    @include px-rem('margin-bottom', 60);
  }

  .section-title,
  h2 {
    margin: 0;
  }

  .section-title + .lead,
  h2 + .lead {
    @include px-rem('padding-top', 24);
  }

  .lead {
    color: $light-gray-800;
    margin: 0;
  }
}

.main {
  padding: 180px 0 0;

  @include media-breakpoint-down(md) {
    padding: 100px 0 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .home & {
    padding: 0;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &.page--default {
    .page-header {
      margin-bottom: 70px;
    }
  }

  &.page--l {
    text-align: justify;
  }
}

.content-section,
.vertical-spacing {
  margin: 0 0 $vspacing--mob;

  @include media-breakpoint-up(lg) {
    margin: 0 0 $vspacing;
  }
}

.content-section {
  &.-narrow {
    .container {
      max-width: 770px;
    }
  }
}

.w-narrow {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
}

.thank-you-message {
  @extend .font-weight-light;
}

// Modules
// ----------------------

// Hero
//

.hero {
  padding: 100px 0 60px;
  margin: 0 0 60px;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: 180px 0 60px;
    margin: 0 0 $front-vspacing;
  }

  .container {
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(sm) {
      padding-bottom: 70px;
    }
  }

  .hero-circle {
    background: $hero-bg;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: $hero-circle-size--mob;
    height: $hero-circle-size--mob;
    z-index: 0;
    bottom: 0;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      width: $hero-circle-size;
      height: $hero-circle-size;
    }
  }

  .hero-intersect {
    left: 33%;
    bottom: 0;
    display: block;

    @include media-breakpoint-up(lg) {
      top: auto;
      left: 50%;
      bottom: -120px;
      margin-left: calc(-50vw - 100px);
    }
    &.circleFill {
      @include media-breakpoint-up(lg) {
        bottom: 0;
        margin-left: -50vw;
      }
    }
  }

  @keyframes animIntersectSlide {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }

  .hero-circle,
  .hero-intersect {
    position: absolute;
  }

  .hero-buttons {
    @include px-rem('margin-bottom', 24);

    display: flex;
    flex-direction: row;

    .btn {
      @include media-breakpoint-up(lg) {
        @include px-rem('margin-right', 24);
      }
    }

    .btn-primary {
      @include media-breakpoint-down(lg) {
        width: 100%;
        display: block;
      }
    }
  }

  .hero-title {
    margin: 0 0 24px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 48px;
    }
  }

  .hero-message {
    position: relative;
    z-index: 1;
  }

  .hero-description {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 16px;
    font-weight: 400;
    text-transform: capitalize;
  }

  .hero-logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    margin-left: -25px;
  }

  .hero-note {
    @extend %font-size-sm;
    @include px-rem('margin-bottom', 30);

    color: $light-gray-400;
  }
}

.hero-on-dark {
  .header:not(.has-scroll) {
    .navbar-nav,
    .nav-link {
      color: $white;
    }

    .logo-svg-color {
      display: none;
    }
    .logo-svg-white {
      display: block !important;
    }

    .navbar-toggler .nav-bar {
      background-color: #fff;
    }

    &.is-menu-open {
      .navbar-toggler .nav-bar {
        background-color: $blue;
      }

      .logo-svg-color {
        display: block !important;
      }
      .logo-svg-white {
        display: none !important;
      }
    }
  }

  .is-menu-open {
    .navbar-nav,
    .nav-link {
      color: $blue-800 !important;
    }

  }
}

.hero-secondary {
  .hero-intersect {
    bottom: 0;

    &--right {
      left: auto;
      right: 50%;
      margin: 0 calc(-50vw - 140px) 0 0;
    }
  }
}
.hero-on-dark {
  .hero-circle {
    background: $dark-blue;
  }
}
.hero-dark {
  .hero-circle {
    background-color: $dark-blue-hero;
  }

  .container {
    background: none;
  }
}

.hero-primary {
  .hero-circle {
    background: $blue;
  }

  .container {
    background: none;
  }
}

.hero-primary,
.hero-dark {
  .container {
    color: $white;
    text-align: center;

    h2 {
      padding-bottom: 24px;
      text-transform: capitalize;
    }

    p {
      padding-bottom: 40px;
    }
  }
}

// CTA
//

.cta-signup {
  position: relative;
  background: $cta-bg;
  border-radius: 5px;
  box-shadow: 0 10px 40px 0 rgba(75, 137, 252, 0.4);
  color: #fff;
  padding: 20px 60px;
  margin: 0;

  .cta-secondary & {
    background: rgba($cta-bg-secondary, 0.95);
    height: 210px;
    display: flex;
    padding-right: 100px;

    @include media-breakpoint-down(lg) {
      padding-right: 30px;
    }

    @include media-breakpoint-down(md) {
      height: 280px;
      padding: 25px 30px;
    }

    @include media-breakpoint-down(xs) {
      height: 210px;
    }

    .btn {
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: 25px 30px 30px;
  }

  .btn-primary {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.2);
    padding: 13px 43px;

    @include media-breakpoint-down(xs) {
      display: block;
    }
  }

  .btn-dark {
    padding: 16px 40px;
  }

  .row {
    height: 130px;

    @include media-breakpoint-down(md) {
      height: auto;
    }
  }

  &::before {
    content: "";
    background: url(../images/cta-pattern.png) no-repeat top left;
    -webkit-background-size: cover;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .cta-secondary & {
      background: url(../images/cta-pattern-secondary.png) no-repeat bottom left;
    }
  }

  .h2 {
    line-height: 50px;
    font-size: (36/16 + rem);
    color: #fff;

    @include media-breakpoint-down(md) {
      margin: 0 0 25px !important;
      font-size: (26/16 + rem);
      line-height: 30px;
      text-align: center;
    }
  }

  .cta-signup-btn-wrapper {
    z-index: 10;

    @include media-breakpoint-down(md) {
      text-align: center !important;
    }
  }

  .cta-graphic {
    background: url(../images/cta-graphic.png) no-repeat;
    position: absolute;
    left: 60px;
    bottom: 0;
    width: 470px;
    height: 163px;
    display: block;

    @include media-breakpoint-down(lg) {
      left: 30px;
    }

    @include media-breakpoint-down(md) {
      left: 50%;
      transform: translateX(-50%);
    }

    @include media-breakpoint-down(xs) {
      width: 280px;
      height: 97px;
      background-size: 280px 97px;
    }
  }
}

// Competitors
//

.page-competitors {
  .app-features-row {
    &:last-of-type {
      padding-top: 0;
    }

    @include media-breakpoint-down(xs) {
      margin: 0 0 60px;
    }
  }

  .app-features-image {
    @include media-breakpoint-down(xs) {
      margin: 0 !important;
    }
  }
}

// Article forms
//

.more-insights {
  border-radius: 5px;
  background: $blue;
  padding: 0;
  height: 100%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  position: relative;

  &--inner {
    padding: 240px 24px 0;
    text-align: center;

    h5,
    p {
      color: #fff;
    }
  }

  &--graphic {
    display: block;
    position: absolute;
  }

  &--graphic-top {
    top: 0;
    left: 0;
    right: 0;
    height: 157px;

    svg {
      display: block;
      margin: 0 auto;
    }
  }

  &--graphic-mail {
    width: 100px;
    height: 108px;
    left: 50%;
    top: 83px;
    transform: translateX(-50%);
  }

  &--graphic-bottom-left {
    width: 105px;
    height: 55px;
    bottom: 10px;
    left: 9px;
  }

  &--graphic-bottom-right {
    width: 204px;
    height: 204px;
    bottom: 0;
    right: 0;
  }

  .insights-form {
    margin: 0;
    overflow: visible;

    .form-group {
      display: flex;
      flex-direction: row;
      position: relative;
      margin: 0;
    }

    input {
      float: none;
      width: calc(100% - 105px);
      height: 44px;
      border: 0;
      color: #999;
      font-size: 14px;
      line-height: 20px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 1;

      &.form-control {
        margin: 0;
      }
    }

    .btn {
      border: 1px solid #F79A3E;
      width: 105px;
      background-color: #F79A3E;
      border-radius: 0 3px 3px 0;
      color: #fff;
      z-index: 9;
    }
  }

  h5 {
    font-size: (24/16 + rem);
    line-height: 26px;
    margin: 0 0 24px;
  }

  p {
    margin: 0 0 32px;
  }
}

.comment-form {
  background: #f6f7f9;
  padding: 20px;
  border-radius: 5px;
  margin-top: 50px;

  @include media-breakpoint-up(md) {
    padding: 45px 50px;
  }

  h3 {
    font-size: (24/16 + rem);
    margin: 0 0 30px;
  }

  .form-row {
    margin: 0 -15px;
  }

  .form-group {
    padding: 0 15px;
    margin: 0 0 20px;
    width: 100%;

    label {
      color: #494a4c;
      font-size: (14/16 + rem);
      margin: 0 0 8px;
    }

    .form-control {
      padding: 10px 20px;
      margin: 0;
      background: #fff;
      line-height: 1.4;
      font-size: (14/16 + rem);
      border: 1px solid #c4c9cc;
      box-shadow: inset 0.5px 0.866px 4px 0px rgba(0, 0, 0, 0.1);
    }

    textarea.form-control,
    .input-comment.form-control {
      height: 90px;
    }
  }

  #mce-success-response,
  #mce-error-response {
    margin: 0 0 20px;
  }
}

#mce-success-response {
  font-size: (14/16 + rem);
  padding: 5px 0 0;
}

#mc_embed_signup div.mce_inline_error,
div.mce_inline_error {
  display: block;
  clear: both;
  font-size: (12/16 + rem);
  font-weight: 400;
  margin: 0;
  padding: 5px 10px;
  background-color: #6B0505;
  z-index: 1;
  color: #fff;
  word-break: break-word;
}

.iframe-form {
  position: relative;

  @include media-breakpoint-down(sm) {
    min-height: 600px;
  }

  iframe {
    @include media-breakpoint-down(sm) {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.g-recaptcha {
  padding: 10px 0 0;

  .grecaptcha-badge {
    position: static !important;
    margin: 0 auto;
  }
}

// Form sections
//

.form-section {
  margin-top: -148px;
  margin-bottom: $vspacing - 10;
  position: relative;
  z-index: 100;

  @include media-breakpoint-down(md) {
    margin-bottom: 55px;
    margin-top: -108px;
  }

  .card {
    text-align: left;
    max-width: 570px;
    margin: 0 auto;

    &.card--large {
      max-width: 770px;
    }

    .card-body {
      padding: 48px;

      @include media-breakpoint-down(md) {
        padding: 20px;
      }
      label {
        small {
          color: $gray-600;
        }
      }

      p {
        margin: 0;
        text-align: center;
        color: $terms-color;
        font-size: 14px;
        &.question-Title {
          font-size: 1rem;
        }
        a {
          text-decoration: underline;
          color: $terms-color;
        }
      }

      .display-message {
        display: none;

        &.is-active {
          display: block;
        }

        h3 {
          margin-bottom: 32px;
        }

        p {
          text-align: left;
          margin-bottom: 23px;
        }

        .ceo-details {
          margin-top: 40px;
          display: flex;
          flex-direction: row;

          .ceo-image {
            margin-right: 20px;
            width: 48px;
            height: 48px;
            overflow: hidden;
            border-radius: 50%;
          }
        }

        .hand-like {
          position: absolute;
          right: 0;
          bottom: 36px;
        }

        @include media-breakpoint-down(md) {
          .ceo-details {
            padding-bottom: 60px;
          }
        }
      }
    }
  }

  .btn-wrap {
    padding-top: 10px;
    margin: 0 0 16px;
  }

  svg {
    margin: -35px auto 0;
    display: flex;
  }
}

// Background
//

.bg-light-blue {
  background: #F7F9FA;
}
.bg-dark-blue {
  background: #171A2D;
  .text-contrast {
    color: #D8DCDE;
  }
}

// More features
.more-features {
  &--row {
    > div {
      margin: 0 0 24px;
    }
  }
  .more-box {
    background: $white;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border-bottom: 3px solid transparent;
    transition: all 0.4s;
    height: 100%;

    a {
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 24px;
      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      border-bottom-color: $blue;
      box-shadow: 0 11px 12px 0 rgba(36, 50, 66, 0.12);
      transform: translateY(-5px);

      h5 {
        color: $blue;
      }

      .icon-svg {
        circle {
          @extend .icon-svg--circle-fill-hover;
        }

        path,
        rect {
          @extend .icon-svg--item-fill-hover;
        }
      }
    }
  }
  .icon-svg {
    width: 48px;
    height: 48px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  .more-text {
    flex: 1;
    padding-left: 16px;

    h5 {
      @extend %font-primary;
      color: $blue-900;
      font-weight: $font-weight-medium;
      margin: 0 0 5px;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 16px;
    }

    p {
      color: $light-gray-600;
      overflow: hidden;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }
    .badge {
      position: relative;
      top: -2px;
      margin-left: 8px;
    }
  }
}
