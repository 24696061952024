// Calculator
// ======================

// Vars
// ----------------------

$hero-circle-size: 8000px;
$hero-circle-size--mob: 3000px;

$front-vspacing: $vspacing;
$front-vspacing--mob: $vspacing--mob;

$testimonials-bg: $gray-300;
$testimonials-border-radius: 20px;
$tab-active-bg: rgba(0, 108, 187, 0.04);
$tab-active-border: $blue;
$tab-image-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

// Base
// ----------------------

.calculator {
  .hero {
    padding-top: 160px !important;
    .hero-intersect {
      &--right {
        bottom: -250px;
        right: 50%;
        margin: 0 calc(-50vw - 140px) 0 0;
      }
    }
  }


  .loading {
    position: fixed;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.62);
  }

  .vue-slider-dot-tooltip-top {
    opacity: 0;
  }

  .vue-slider-rail {
    background-color: #F2F2F2;
    height: 6px;
  }

  .vue-slider-dot {
    border: 4px solid #006CBB;
    width: 18px !important;
    height: 18px !important;
    top: 3px !important;
    background: #fff;
    border-radius: 100%;
  }

  .upsell_range .vue-slider-dot {
    border: 4px solid #82888c;
  }

  .upsell_range .vue-slider-process {
    background-color: #82888c;
  }

  .vue-slider-process {
    background-color: #006CBB;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: 18% auto;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  main.main {
    margin-top: -50px;

    @include media-breakpoint-up(lg) {
      margin-top: -150px;
    }

  }


  .hero-secondary {
    .container {
      text-align: center;

      p {
        margin-bottom: 55px;
      }
    }
  }

  input.rangeInput {
    display: block;
    width: 100%;
  }

  div#js-app__contact-form {
    max-width: 770px;
    margin-bottom: -50px;
    z-index: 100

  }

  button.btn.btn-primary.btn-block.btn-caluclate {
    margin-bottom: 8px;
    margin-top: 22px;
  }

  .form-group {
    margin: 0 0 40px;
    padding: 0;
  }

  .state-buttons {
    display: flex;
    align-items: center;
    padding: 0 0 8px;

    .btn {
      padding: 0;
      color: #82888D;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      outline: 0;
      box-shadow: none;
    }

    .sep {
      display: inline-block;
      padding: 0 10px;
      color: #2F3941;
      opacity: 0.4;
      line-height: 24px;
      font-weight: 400;
    }

    .is-active {
      color: #006CBB;
      border-bottom: 1px solid;
    }
  }

  .card.chart-card {
    max-width: 100%;
    padding: 0 !important;
    margin: 0 0 80px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 120px;
    }

    .churn-chart {
      padding: 0;

      .churn-chart-inner {
        padding-top: 100px;
      }
    }
  }

  .community {
    margin: 0 0 20px;
    text-align: center;
  }

  .get-started {
    padding: 0;
    background: none;
    margin-bottom: 0px;
    padding-top: 80px;

    .bg-img-right {
      top: -110px !important;
    }

    .hero-logos {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .get-started-note {
      @include media-breakpoint-up(lg) {
        margin: 0 0 32px;
      }
    }
  }

  .form-section {
    margin-bottom: 0px;
  }

  .hero-description {
    color: #006CBB;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: #FAFAFA;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #FFF;
  }

  .page-calculator.content-section {
    margin: 0 0 40px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 60px;
    }
  }

  .table {
    td:nth-child(1) {
      text-align: left;
    }

    td {
      @extend %font-primary;

      font-size: 16px;
      text-align: right;
    }

    td span svg {
      margin: 0 auto !important;
    }

    th {
      @extend %font-secondary;

      font-weight: $font-weight-semibold;
      font-size: 16px;
      line-height: 30px;
      text-align: right;
      border-top: 0;
    }

    th.tdTitle {
      font-size: 20px;
      line-height: 28px;
      padding-left: 0;
      padding-bottom: 16px !important;
      padding-top: 16px !important;
      text-align: left;
    }
  }

  a.download-btn {
    max-width: 211px;
    margin: 0 auto;
  }

  section.product-adoption.content-section {
    h2 {
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 24px;
      color: #2F3941;
      opacity: 0.7;
    }
  }


  .table--conclusions {
    th {
      &:last-of-type {
        width: 59px;
      }
    }
  }
}

