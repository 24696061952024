// Content
// ======================

// Vars
// ----------------------

// Base
// ----------------------

.content-page {
  padding-bottom: 140px;

  > h3 {
    &:first-child {
      & + ul {
        li {
          &:first-child {
            font-weight: $font-weight-medium;
          }
        }
      }
    }
  }

  p, li {
    margin-bottom: 24px;
  }

  p + h3,
  ul + h3 {
    padding-top: 15px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding-left: 52px;
      position: relative;

      .list-index {
        display: inline-block;
        min-width: 51px;
        position: absolute;
        top: 0;
        left: 0;
      }

      ul {
        padding-top: 24px;
      }
    }
  }

  h3 {
    margin: 0 0 24px;
  }

  .table {

    &-blue {
      td {
        padding: .5em 1rem;
      }
      th {
        background-color: $dark-blue;
        border-color: $dark-blue;
      }
    }
  }
}


.ai-policy {
  .content-page {
    .list--bullets {
      li {
        ul {
          list-style: none;
          padding: 0;
          margin-top: 1rem;
          li {
            padding-left: 0;
            margin-bottom: 1rem;
            margin-left: 1.5rem;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              left: -1.5rem;
              top: 8px;
              width: 8px;
              height: 8px;
              background-color: black;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .list--bullets-parent {
      list-style: none;
      padding: 0;
      margin-top: 1rem;
      li {
        padding-left: 0;
        margin-bottom: 1rem;
        margin-left: 1.5rem;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: -1.5rem;
          top: 8px;
          width: 8px;
          height: 8px;
          background-color: black;
          border-radius: 50%;
        }
      }
    }
  }
}
