// Product
// ======================

// Vars
// ----------------------

// Base
// ----------------------

$background-started: #383A4F;
@keyframes animHeight {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  15% {
    height: 15%;
    opacity: 1;
    visibility: visible;
  }
  80% {
    height: 80%;
    opacity: 1;
    visibility: visible;
  }
  100% {
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes animRunShow {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes animRunSlide {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}
@keyframes delayedShow {
  to {
    visibility: visible;
  }
}
@keyframes fadeinSvg {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

.product {
  overflow-x: hidden;
  &-360 {

    .main {
      @include media-breakpoint-down(sm) {
        overflow: hidden;
      }
      .content-section {
        margin: 0 0 $vspacing--mob / 2;
        @include media-breakpoint-up(lg) {
          margin: 0 0 $vspacing / 2;
        }
        border-top: 1px dotted transparent;
        border-bottom: 1px dotted transparent;
      }
    }


    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper {
      visibility: hidden;
      opacity: 0;
    }
    .has-animation {
      .get-started-new--picture,
      .graphic__get-started--right,
      .get-started-body,
      .get-started-name-wrapper {
        animation: animRunSlide .4s linear forwards;
        animation-delay: .4s;
      }

      .get-started-name-wrapper {
        transform: translate(150px, 0);
      }
      .get-started-new--picture {
        transform: translate(150px, 0);
      }
      .graphic__get-started--right {
        transform: translate(50px, 0);
      }
      .get-started-body {
        transform: translate(-50px, 0);
      }
    }

    .content-animation {
      .graphic-item {
        visibility: hidden;
        opacity: 0;
        display: block;
        position: relative;
      }
      &.has-animation {
        .unite-teamBtn {
          animation: animRunFade .3s linear forwards;
          animation-delay: 1s;
        }
        .graphic-item {
          animation: animRunSlide .6s linear forwards;
          animation-delay: .3s;
          &.unite {
            &--dashboard {
              animation-delay: .3s;
            }
            &--right {
              animation-delay: .3s;

              #fill {
                width: 25%;
                transition-delay: 1s;
                transition: width 2s linear;
              }
            }
            &--blue {
              animation-delay: 0s;
            }
            &--orange {
              animation-delay: 0s;

            }
          }
          &.oversee {
            &--boarding{
              animation-delay: .4s !important;
              animation-duration: .4s !important;

            }
            &--tasks {
              animation-delay: .8s !important;
              animation-duration: .4s !important;

            }
            &--notes{
              animation-delay: 1.2s !important;
              animation-duration: .4s !important;

            }
            &--assigned{
              animation-delay: 1.6s !important;
              animation-duration: .4s !important;

            }
            &--line {
              animation: animHeight 1s linear forwards;
              animation-delay: 1.8s !important;
              animation-duration: .4s !important;
            }
          }
          &.interactions {
            &--line {
              animation: animHeight .4s linear forwards;
            }
          }
          &.insights {
            &--payment-line,
            &--email-line,
            &--status-line {
              animation: animHeight .6s linear forwards;
            }
          }
        }
      }
      .unite {
        &--dashboard {
          animation-delay: .5s;

          transform: translateX(-50px);
        }
        &--right {
          top: -40px;
          right: 10px;
          max-width: 40vw;
          animation-delay: .5s;

          #fill {
            width: 0;
          }
          transform: translateX(50px);
        }
        &--blue {
          top: 0;
          right: -150px;
          z-index: -1;
          max-width: 100vw;
        }
        &--orange {
          top: -120px;
          left: -120px;
          z-index: -1;
          max-width: 100vw;
        }
      }
      .track {
        &--csm {
          top: 0;
          z-index: 2;
          transform: translate(50px, 0px);
          max-width: 80vw;
          margin-bottom: -12%;
          right: -14%;
          @include media-breakpoint-up(md) {
            right: -5%;
          }

        }
        &--health {
          top: 0;
          z-index: 1;
          transform: translate(-50px, 0px);
          max-width: 80vw;
          right: 0;
          @include media-breakpoint-up(md) {
            right: 10%;
          }

        }
        &--score {
          top: 0px;
          z-index: 2;
          transform: translate(50px, 0px);
          margin-top: -8%;
          max-width: 73vw;
          right: -17%;
          @include media-breakpoint-up(md) {
            right: 0;
          }
        }
        &--global {
          top: 35%;
          right: 0%;
          z-index: -9;
          max-width: 100vw;
          .get-customer-picture {
            transform: scale(1.2);
          }
          @include media-breakpoint-up(md) {
            top: 0%;
            right: -25%;
          }

        }
      }
      .oversee {
        &--boarding{
          .get-customer-picture {
            max-width: 45vw;
            @include media-breakpoint-up(md) {
              max-width: 45vw;
            }
          }
          z-index: 1;
          margin-bottom: 4%;
          transform: translate(0px, -50px);
        }
        &--tasks{
          left: -5px;
          margin-bottom: -5px;
          z-index: 1;
          @include media-breakpoint-up(md) {
            left: -10px;
            margin-bottom: -15px;
          }
          transform: translate(0px, -50px);

        }
        &--notes{
          left: -5px;
          z-index: 1;
          @include media-breakpoint-up(md) {
            left: -10px;
          }
          transform: translate(0px, -50px);

        }
        &--assigned {
          transform: translate(0px, -50px);
          margin-top: 6%;
          margin-left: 46%;
          top: 0;
          left: 0;
          z-index: 1;
          @include media-breakpoint-up(md) {
            margin-top: 6%;
            margin-left: 45%;
          }
          .get-customer-picture {
            max-width: 45vw;

            @include media-breakpoint-up(md) {
              max-width: 45vw;
            }
          }


        }
        &--line {
          position: absolute;
          top: 0;
          left:0;
          width: 29vw;
          overflow: hidden;
          margin-top: 3%;
          margin-left: 34%;
          z-index: -1;
          @include media-breakpoint-up(md) {
            width: 15vw;
          }


        }
        &--bg {
          top: 50%;
          left: -25%;
          z-index: -9;
          max-width: 100vw;
          transform: translateY(-50%) scaleX(-1);
          @include media-breakpoint-up(md) {
            transform: translateY(-50%) scaleX(1);
          }
        }
      }
      .interactions {
        &--joined {
          animation-delay: .1s !important;
          transform: translate(0px, -50px);
          max-width: 70vw;
          z-index: 1;
          @include media-breakpoint-up(lg) {
            margin-top: -30px;
            left: -50%;
          }
        }
        &--notes {
          transform: translate(0px, -50px);
          animation-delay: .4s !important;
          margin-top: 40px;
          margin-left: 10%;
          z-index: 1;
          @include media-breakpoint-up(lg) {
            margin-top: 60px;
            right: 15px;
          }
        }
        &--ticket {
          right: 0;
          z-index: 1;
          margin-top: 0;
          transform: translate(0px, -50px);
          animation-delay: .8s !important;
          margin-left: 5%;

        }
        &--update {
          transform: translate(0px, -50px);
          animation-delay: 1.2s !important;
          max-width: 45vw;
          margin-top: 0%;
          margin-left: 15%;
          z-index: 1;
          @include media-breakpoint-up(lg) {
            margin-top: 4%;
            left: -50%;
          }
        }
        &--line {
          overflow: hidden;
          animation-delay: 1.2s !important;
          max-width: 60vw;
          margin-top: 9%;
          right: 0;
          top:0;
          margin-right: 3%;
          z-index: -1;
          @include media-breakpoint-up(sm) {
            margin-right: 20%;
          }
          @include media-breakpoint-up(md) {
            margin-right: 20%;
            max-width: 36vw;
          }
          @include media-breakpoint-up(lg) {
            margin-top: 2%;
            margin-right: 15%;
          }
        }
        &--bg {
          top: 50%;
          right: -150px;
          transform: translateY(-50%);
          z-index: -9;
        }
      }
      .insights {
        &--payment {
          transform: translate(0px, -50px);
          animation-delay: .3s !important;
          max-width: 50vw;
          margin: 4% 0 0 12%;
        }
        &--payment-line {
          top: 0px;
          left: 0px;
          z-index: -1;
          animation-delay: .7s !important;
          overflow: hidden;
          max-width: 6vw;
          margin-top: 11%;
          margin-left: 34%;

          @include media-breakpoint-up(md) {
            margin-top: 11%;
            margin-left: 34%;
          }
        }

        &--email {
          transform: translate(0px, -50px);
          animation-delay: 1s !important;
          max-width: 50vw;
          margin: 5% 0 0 40%;
          @include media-breakpoint-up(lg) {

          }
        }
        &--email-line {
          top: 0;
          left: 0;
          z-index: -1;
          animation-delay: 1.5s !important;
          overflow: hidden;
          max-width: 6vw;
          margin-top: 30%;
          margin-left: 51%;
          @include media-breakpoint-up(md) {
            margin-top: 28%;
            margin-left: 51%;
          }

        }

        &--status {
          transform: translate(0px, -50px);
          animation-delay: 2s !important;
          max-width: 50vw;
          margin: 4% 0 0 0;
        }
        &--status-line {
          top: 0;
          left: 0;
          z-index: -1;
          animation-delay: 2.5s !important;
          overflow: hidden;
          max-width: 6vw;
          margin-top: 47%;
          margin-left: 25%;
          @include media-breakpoint-up(md) {
            margin-top: 42%;
            margin-left: 25%;
          }
        }

        &--block {
          top: 0;
          left: 0;
          z-index: 1;
          display: block;
          transform: translate(0px, 50px);
          animation-delay: .25s !important;
          margin: 10% 0 0 0;
          max-width: 85vw;

          @include media-breakpoint-down(sm) {
            //margin: 4% 8% 0 0;
          }
        }
        &--bg1 {
          top: 100px;
          left: 100px;
          z-index: -9;
          //animation-delay: 1s !important;
        }
        &--bg2 {
          bottom: -150px;
          left: -200px;
          z-index: -9;
          //animation-delay: 1s !important;
        }
      }
    }
    .unite-team {
      &Btn {
        z-index: 9;
        position: absolute;
        transform: translate(-50%, -50%) scale(.4);
        top: 50%;
        left: 50%;
        margin: auto;
        visibility: hidden;
        opacity: 0;
        @include media-breakpoint-up(lg) {
          transform: translate(-50%, -50%) scale(1);
        }
        &:hover {
          cursor: pointer;
          svg {
            #rect {
              opacity: 0.55;
            }
          }
        }
        span {
          text-align: center;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
        }
      }
    }

  }

  .content-section {
    h2 {
      margin-bottom: 32px;
    }

    p {
      color: $light-gray-800;
      margin-bottom: 23px;
    }
    .list--bullets {
      margin-bottom: 23px;
      margin-left: 15px;
      li {
        color: $light-gray-800;
      }
    }
  }

  .main {
    padding-top: 100px;

    @include media-breakpoint-up(md) {
      padding-top: 155px;
    }
  }

  .get-started-note {
    br {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  h1 {
    br {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .list--check-circle {
    padding-bottom: 20px;
    list-style: none;
    padding-left: 0px;
    li {
      position: relative;
      padding-left: 40px;
      margin: 0 0 15px;
      color: $light-gray-800;
    }
    .icon {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .list--styled {
    padding-bottom: 20px;
    list-style: none;
    padding-left: 10px;

    li {
      position: relative;
      padding-left: 30px;
      margin: 0 0 12px;
      color: $light-gray-800;
      &:before {
        transform: scale(1.8);
      }
    }

    .icon {
      position: absolute;
      left: 0;
      top: 9px;
    }
  }

  .content-note {
    &--narrow {
      margin: 0 auto;
      width: 90%;

      @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 570px
      }
    }

    p {
      @extend %font-size-sm;

      color: $light-gray-600;
      line-height: 20px;
    }
  }

  picture {
    display: block;
  }
  .hero-cover {
    &--fadeIn {
      @keyframes heroFadeIn{
        0% {
          opacity:0;
        }
        100% {
          opacity:1;
        }
      }
      @-webkit-keyframes heroFadeIn {
        0% {
          opacity:0;
        }
        100% {
          opacity:1;
        }
      }

      animation: heroFadeIn ease 3s;
      -webkit-animation: heroFadeIn ease 3s;
      .lead {
        font-size: 1rem;

      }
    }
    &--author {
      &.left {
        position: absolute;
        bottom: -10px;
        right: 49%;
        max-width: 230px;
        &.nml {
          right: 51%;
        }
        &.large {
          max-width: 280px;
        }
        @include media-breakpoint-up(sm) {
          right: 50.5%;
        }
        @include media-breakpoint-up(md) {
          right: 49%;
          max-width: 300px;
          &.nml {
            right: 49%;
          }
          &.large {
            max-width: 380px;
          }
        }
        @include media-breakpoint-up(lg) {
          bottom: -10px;
          right: 48%;
          max-width: 300px;
          &.nml {
            right: 48%;
          }
          &.large {
            max-width: 300px;
          }
        }
        @include media-breakpoint-up(xl) {
          right: 47%;
          max-width: 360px;
          &.large {
            max-width: 460px;
          }
        }
      }
      &.right {
        position: absolute;
        bottom: -10px;
        left: 49%;
        max-width: 230px;
        @include media-breakpoint-up(sm) {
          left: 50.5%;
        }
        @include media-breakpoint-up(md) {
          left: 51.5%;
          max-width: 300px;
        }
        @include media-breakpoint-up(lg) {
          bottom: 0;
          left: 52.5%;
          max-width: 350px;
        }
        @include media-breakpoint-up(xl) {
          left: 53.2%;
          max-width: 360px;
        }
      }

    }
    &--placeholder {
      @include media-breakpoint-down(sm) {
        min-height: 150px;
      }
    }
  }
  .testimonials-slider {
    .video-embeded {
      filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
      border-radius: 24px;
      overflow: hidden;
      @include media-breakpoint-up(md) {
        margin-left: -70px;
        margin-top: -40px;
      }
    }
  }
}


// Top section

.product-top-info {
  .hero-message {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    .hero-subtitle {
      display: block;
      font-size: 13px;
      font-weight: $font-weight-medium;
      line-height: 18px;
      text-transform: uppercase;
      color: $blue;
      margin: 0 0 8px;
    }

    h1 {
      @include px-rem('margin-bottom', 24);
    }

    p {
      @include px-rem('margin-bottom', 48);
    }
  }

  .hero-graphic {
    padding: 0;
    line-height: 0;

    @include media-breakpoint-up(lg) {
      min-height: 479px;
    }

    picture {
      display: inline-block;

      img {
        position: relative;
        z-index: 10;
      }
    }
  }

  .hero-graphic-square {
    top: 20px;
    left: 15px;
    right: auto;
    bottom: auto;
  }
}

.get-customer {
  position: relative;

  .section-title {
    margin-bottom: 0 !important;
  }

  &.growth-section {
    .container {
      position: relative;

      &:before {
        content: '';
        background: url(../images/product/faded-logo.png) no-repeat;
        -webkit-background-size: 700px 700px;
        background-size: 700px 700px;
        position: absolute;
        top: 0;
        left: -330px;
        width: 700px;
        height: 700px;
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }
  }

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
    margin-bottom: 40px;
  }

  .get-customer-picture {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .increase-trial {
    box-shadow: 0 60px 60px rgba(61, 70, 77, 0.1);
    //border-top: 4px solid $blue;
    border-radius: 4px 4px 0 0;
  }

  .create-values {
    margin-top: 10px;
    margin-bottom: 30px
  }

  .img-360-2 {
    position: relative;
    top: 0;
    left: 0;
    box-shadow: 25px 25px 50px 0 white inset;
  }

  .img-360 {
    position: absolute;
    bottom: 0;
    left: 131px;
  }
}

.product-adoption {

  .adoption-picture {
    position: relative;
    overflow: hidden;
    display: block;

    img {
      @include media-breakpoint-up(md) {
        float: right;
      }
    }
  }

  .opinion-matters {
    margin-left: 150px;
    margin-bottom: 59px;
  }

  .img-chart {
    position: relative;
    bottom: 100px;
    z-index: -2;
    left: 170px;
  }

  .img-chart1 {
    position: relative;
    z-index: -1;
  }

  .img-chart2 {
    position: absolute;
    right: -100px;
  }
}

.customer-lifecycle {
  position: relative;
  overflow: hidden;
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding: 30px 0;
  }

  &.graphic--automate {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    .container {
      padding-top: 0;
      padding-bottom: 0;

      @include media-breakpoint-up(lg) {
        > .row {
          min-height: 700px;
        }
      }
    }

    li,
    p {
      color: $light-gray-700;
    }

    .graphic--automate {
      background: url('../images/product/product-transparent-circle.png') no-repeat;
      width: 700px;
      height: 700px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -230px;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .content--image {
      @include media-breakpoint-down(sm) {
        text-align: center;
        padding: 0;
      }
    }
  }

  .container {
    position: relative;

    @include media-breakpoint-up(lg) {
      padding-top: 65px;
    }
  }

  .graphic__line {
    display: none;
    width: 821px;
    height: 629px;
    background: url(../images/product/product-transparent-rectangle.png);
    position: absolute;
    top: 0;
    left: -265px;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .license-utilization {
    padding-right: 0;
  }

  .license-utilization-text {
    h2,
    p {
      margin-left: 14px;
    }
  }

  .portofolio-health-text {
    h2,
    p {
      margin-left: 45px;
    }
  }

  .sidebar-picture {

    .bg-product {
      position: absolute;
      top: -50px;
      z-index: -1;
      left: -300px;
    }
  }

  .sidebar-right {
    position: relative;
    bottom: 150px;
    z-index: 2;
    left: 210px;
  }

  .sidebar-right1 {
    position: relative;
    right: 150px;
    z-index: 1;
  }

  .sidebar-right2 {
    position: absolute;
    top: -350px;
  }

  .content--column {
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.get-started {
  overflow: hidden;
  padding-top: 40px;
  position: relative;

  .container {
    @include media-breakpoint-up(lg) {
      position: relative;
      z-index: 1;
    }
  }

  .section-inner {
    background: #F5F9FC;
    padding-top: 110px;
    padding-bottom: 70px;
    position: relative;

    .container {
      @include media-breakpoint-down(sm) {
        position: relative;
        z-index: 1;
      }
    }
  }

  .bg-img-right,
  .bg-img-left {
    position: absolute;
  }

  .bg-img-right {
    top: -155px;
    right: -55px;

    @include media-breakpoint-up(md) {
      top: -35px;
    }

    @include media-breakpoint-up(lg) {
      right: 15px;
      top: -145px;
    }
  }

  .circle--small {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .container--abs {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  .bg-img-left {
    bottom: -235px;
    left: -220px;

    @include media-breakpoint-up(lg) {
      bottom: -195px;
      left: 0;
    }
  }
}

.get-started-new {
  overflow: hidden;
  padding-top: 40px;
  position: relative;
  p,
  .section-inner,
  .get-started-note {
    color: $white;
  }
  p {
    opacity: .8;
  }
  .get-started-name-wrapper {
    z-index: 9;
    @include media-breakpoint-down(md) {
      right: 0;
      bottom: 30px;
    }
    @include media-breakpoint-up(md) {
      bottom: -12%;
      right: 0;
    }
  }
  .get-started-name {
    position: relative;
    background: rgba(#252530, .2);
    opacity: 0.8;
    border-radius: 3px;
    z-index: 2;
    padding: 10px 20px;
    color: $white;
  }
  .container {
    @include media-breakpoint-up(lg) {
      position: relative;
      z-index: 1;
    }
  }
  .section-inner-bg {
    background: $background-started;
  }
  .section-inner {
    position: relative;
    padding-top: 90px;
    padding-bottom: 55px;
    background: $custify-grey;
    @include media-breakpoint-up(md) {
      background: $background-started;
    }
    .container {
      @include media-breakpoint-down(sm) {
        position: relative;
        z-index: 1;
      }
    }
  }
  .bg-img-right,
  .bg-img-left,
  .bg-img-middle,
  .bg-img-top {
    position: absolute;
  }
  .bg-img-middle {
    left: 10%;
    top: -320px;
    bottom: inherit;
    z-index: -1;
    @include media-breakpoint-up(md) {
      bottom: -15px;
      left: 40%;
      top: inherit;
    }
  }
  .bg-img-top {
    left: -420px;
    top: -350px;
    z-index: -1;
    transform: scale(0.6);
    @include media-breakpoint-up(md) {
      left: -250px;
      top: -450px;
    }
  }
  .bg-img-right {
    top: 0;
    right: -20%;

  }
  .circle--small {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .container--abs {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }
  .bg-img-left {
    bottom: 0;
    left: 44%;

    @include media-breakpoint-up(lg) {
      left: 44%;
    }
  }
  &--picture {
    position:relative;
    right: -20%;
    top: 0;
    @include media-breakpoint-up(md) {
      right: 0;
      position:absolute;
      top: -90px;
    }
    @include media-breakpoint-up(lg) {
      right: 20%;
    }
    img {
      display: block;
    }
  }
}
.testimonials-fullscreen {
  font-family: $font-montserrat;
  overflow: hidden;
  position: relative;
  p,
  .section-inner,
  .get-started-note {
    color: $white;
  }
  .btn-link {
    font-weight: 500;
    text-decoration: underline;
  }

  .container {
    @include media-breakpoint-up(lg) {
      position: relative;
      z-index: 1;
    }
  }

  .section-inner {
    position: relative;
    min-height: 450px;
    max-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      @include media-breakpoint-down(sm) {
        position: relative;
        z-index: 1;
      }
    }
  }

  .section-inner-bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #2d2e3c;
    background-image: url(../images/product/testimonials-fullscreen.png);
    background-position: left center;
    background-clip: border-box;
    @include media-breakpoint-down(sm) {
      background-position: 50%;
    }
  }
}


@media (max-width: 1199.98px) {
  .product .hero-message .btn-primary {
    width: 100%;
    display: block;
  }

  .product-top-info,
  .get-customer,
  .customer-lifecycle,
  .product-adoption {
    margin: 0 0 60px;
  }

  .more-features {
    margin-bottom: 80px;
  }
}

// Content
//

.image-container {
  display: block;
}

.href-more {
  color: $blue;
  .icon-svg {
    margin-left: 5px;
  }
}

.social-proof {
  p {
    font-weight: $font-weight-medium;
    opacity: .5;
  }
}


.wistia {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;
}
.wistia img {
  width: 100%;
  top: 0;
  left: 0;
}
.wistia .play-button {
  width: 90px;
  height: 60px;
  background-color: rgb(0, 91, 157);
  box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
  z-index: 1;
  border-radius: 6px;
}
.wistia .play-button:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26.0px;
  border-color: transparent transparent transparent #fff;
}
.wistia img,
.wistia .play-button {
  cursor: pointer;
}
.wistia img,
.wistia iframe,
.wistia .play-button,
.wistia .play-button:before {
  position: absolute;
}
.wistia .play-button,
.wistia .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d( -50%, -50%, 0 );
}
.wistia iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

