// Buttons
// ======================

// Vars
// ----------------------

$faq-border: #E5E5E5;
$faq-button-color: $color-base;
$faq-button-color-hover: $blue;
$faq-icon-bg: #cfd3d5;


// Base
// ----------------------
.accordion {
  border-bottom: 1px solid $faq-border;

  .card {
    border: 0;
    box-shadow: none;

    &-header {
      border: 0;
      margin: 0;
      padding: 0;

      .btn {
        @extend %font-size-xl;

        background: none;
        border-top: 1px solid $faq-border;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        line-height: 20px;
        font-weight: $font-weight-semibold;
        display: block;
        width: 100%;
        text-align: left;
        color: $faq-button-color;
        position: relative;
        border-radius: 0;
        cursor: pointer;
        padding: 28px 55px 28px 20px;

        @include media-breakpoint-up(lg) {
          padding: 28px 55px 28px 32px;
          line-height: 24px;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          background-color: $faq-icon-bg;
          transform: translateY(-50%);
          transition: all 0.3s;
        }

        &::before {
          width: 16px;
          height: 2px;
          right: 32px;
        }

        &::after {
          width: 2px;
          height: 16px;
          right: 39px;
          z-index: 1;
        }

        &,
        &:hover {
          text-decoration: none;
        }

        &:focus {
          box-shadow: none;
        }

        &:hover {
          color: $faq-button-color-hover;

          &::before,
          &::after {
            background-color: $faq-button-color-hover;
          }
        }

        &[aria-expanded="true"] {
          background: #fff;

          &:after {
            opacity: 0;
          }
        }
      }
    }

    &-body {
      padding: 0 20px 20px;

      @include media-breakpoint-up(lg) {
        padding: 0 32px 28px;
      }
    }

    .card-header .btn[aria-expanded="true"],
    .collapse-item {
      border-right: 1px solid $faq-border;
      border-left: 1px solid $faq-border;
      outline: none;
      box-shadow: none;
    }
  }
}
