// Header
// ======================

// Vars
// ----------------------

$header-scroll-bg: #fff;
$header-scroll-bg-home: rgba(#262736, 0.15);
$header-scroll-shadow: 0px 4px 10px rgba(42, 43, 60, 0.06);

// Baseß
// ----------------------

.header {
  padding: 20px 0;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  will-change: transform;
  transition: transform 200ms linear, background 0.3s ease-in;

  @include media-breakpoint-up(lg) {
    height: 95px;
  }

  &.header--pinned {
    @include media-breakpoint-down(sm) {
      transform: translateY(0%) !important;
    }
  }

  &.header--unpinned {
    @include media-breakpoint-down(sm) {
      transform: translateY(-100%);
    }
  }

  &.has-scroll,
  &.has-background {
    padding: 5px 0;

    @include media-breakpoint-down(md) {
      padding: 20px 0;
    }

    @include media-breakpoint-up(lg) {
      height: 65px;
    }
  }

  &.has-scroll,
  &.has-background {
    background: $header-scroll-bg;
    box-shadow: $header-scroll-shadow;
  }

  &.is-menu-open {
    z-index: 10000;
  }

  .navbar {
    padding: 0;
  }

  .navbar-brand {
    padding: 0;
    margin: 0;
    line-height: 0;
    position: relative;

    @include media-breakpoint-down(lg) {
      z-index: 1000;
    }
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .navbar-toggler {
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 1000;

    .nav-bar {
      background: $blue;
      display: block;
      width: 24px;
      height: 3px;
      margin: 0 0 4px;
      transition: all 0.3s;
      position: relative;
      border-radius: 2px;

      &.nav-bar-bot {
        margin: 0;
      }
    }
  }

  .navbar-toggler[aria-expanded="true"] {
    .nav-bar {
      &-top {
        transform: translateY(7px) rotate(225deg);
      }

      &-mid {
        opacity: 0;
        visibility: hidden;
        transform: rotate(180deg);
      }

      &-bot {
        transform: translateY(-7px) rotate(-225deg);
      }
    }
  }
}
