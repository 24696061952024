
.referral {
  .main {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
  .accordion {
    border-bottom: 0;
  }
  .bg-blue {
    background-color: #E1F3FF;
  }
  .page-faq {
    padding-top: 0;
    background: $white;
    ul {
      li {
        margin-left: 30px;
      }
    }
    .card-header {
      background: $white;
    }
  }
  .referral-badges {
    picture,
    img {

    }
  }
  .get-started {
    .btn-light {
      color: #383A4F;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      border-radius: 3px;
    }
  }

  .referral-form {
    @include media-breakpoint-up(md) {
      margin-top: -280px;
    }

    .card {
      color:$white;
      background: linear-gradient(162.26deg, #636371 -13.49%, #171A2D 53.27%);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
      border-radius: 20px;
    }
  }
}


