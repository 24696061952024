// Customers
// ======================

@-webkit-keyframes animation-hero {
  0% {
    -webkit-transform: translateX(0%) rotate(0deg);
    transform: translateX(0%) rotate(0deg);
  }
  50% {
    -webkit-transform: translateX(16%) rotate(0deg);
    transform: translateX(16%) rotate(0deg);
  }
  100% {
    -webkit-transform: translateX(0%) rotate(0deg);
    transform: translateX(0%) rotate(0deg);
  }
}
@-webkit-keyframes animation-fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes animation-fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.customers {
  .hero {
    margin: 0;
    .hero-note {
      font-family: $font-montserrat;
      font-size: (18/16 + rem);
      padding: .75rem 0;
    }
  }

  .main {
    padding-top: 90px;

    @include media-breakpoint-up(md) {
      padding-top: 140px;
    }
  }
  .e-cards {
    .card {

      height: 100%;
      background: $white;
      @include border-radius(4px);
      .card-image {
        margin: 0 auto;
        display: flex;
        align-items: center;
        align-content: center;

        figure {
          margin: 0 auto;
          z-index: 1;
        }
      }
      .card-category {
        font-weight: 400;
        font-size: 1rem;
        line-height: 16px;
        color: $dark-blue;
        text-transform: uppercase;
      }
      .card-body {
        border: 1px solid #C2C5CC;
        border-bottom: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .badge-secondary {
          background-color: $light-gray-010;
          color: $dark-blue;
          @include border-radius(4px);
          padding: 4px 12px;
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
        }
      }
      .card-footer {
        text-align: center;
        border: 0;
        padding: 0;
        background-color:$orange;
        &:hover {
          background-color:darken($orange, 15%);
        }
        a {
          text-decoration: none;
          color: $white;
          display: block;
          padding: 0.75rem 1.25rem;
        }
      }
    }
  }
  .category-pils {
    .badge {
      font-weight: 700;
      font-size: 16px;
      color: $blue-900;
      @include border-radius(5px);
      background: $white;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      padding: 8px 24px;
      margin: 8px 16px 8px 0;
      &:hover {
        color: $blue;
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background: $blue;
        color: $white;
      }
    }
  }

  // Progress circle
  .progressCircle {
    position: relative;
    .svg {
      transform: rotate(-90deg);
      margin: auto;
      display: flex;
      circle {
        stroke-linecap: round;
        stroke: #e8e8e8;
        fill: transparent;
      }
      #bar-bg {
        stroke-dashoffset: 0;
        stroke-width: 25px;
        stroke: url('#linearBg');
        opacity: .15;
      }
      #bar {
        transition: stroke-dashoffset 1s linear;
        stroke: url('#linear');
        stroke-width: 35px;
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
      }
    }
    .percentage {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      color: #044D80;
      font-size: 2.5rem;
      font-weight: 500;
      opacity: 0.8;
    }
  }

  .hero-graphic {
    position: relative;
    padding: 0;
    text-align: center;
    @include media-breakpoint-up(lg) {
      text-align: center;
      padding: 31px 27px 31px 0;
    }
  }
  .hero-customers {
    img {
      padding: .5rem;
      display: block;
    }
    //.hide {
    //  visibility: hidden;
    //}
    .hero-graphic-item {

    }

    .animation-lr {
      picture {
        transition: all 0.4s ease 0s;
        transform-origin: center center 0px;
        animation-duration: 10s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-name: animation-hero;
      }
      &.delay1 {
        picture {
          animation-delay: 2s;
        }
      }
      &.delay2{
        picture {
          animation-delay: 1s;
        }
      }
      &.delay3{
        picture {
          animation-delay: 3s;
        }
      }
    }

    .hero-grahic-logo1 {
      top: 40px;
      left: 170px;
    }
    .hero-grahic-logo2 {
      bottom: 110px;
      right: 50px;
    }
    .hero-grahic-logo3 {
      bottom: 0px;
      left: 120px;
    }

    .hero-grahic-profile1 {
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
    }
    .hero-grahic-profile2 {
      top: -10px;
      right: 110px;
      z-index: 100;
    }
    .hero-grahic-profile3 {
      bottom: -50px;
      right: 190px;
      z-index: 100;
    }
  }


  .videoOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 91, 157, 0.7);
    width: 6rem;
    height: 4rem;
  }
  .infographic {
    &-banner {
      background: $blue;
      border-radius: 120px 0px;
      position: relative;
      overflow: hidden;
      padding: 4rem 3rem;
      svg {
        position: absolute;
        right: 100px;
        top: -10px;
      }
      .btn-outline-white {
        @include media-breakpoint-up(md) {
          padding-left: 100px;
          padding-right: 100px;
        }
      }
      .opacity-1 {
        opacity: 1 !important;
      }
    }
    &-chart{
      &--percentage {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 70px;
        color: $custify-grey;
        span{
          font-style: normal;
          font-weight: 500;
          font-size: 42px;
        }
        small {
          position: absolute;
          top: -25px;
          left: 0;
          font-size: 22px;
        }
        .count {
          @extend .infographic-chart--percentage;
        }
        &.opacity-08 {
          opacity: .8;
        }
      }
    }
  }

  .product-testimonials {
    &__image--card {
      overflow: hidden;

      img,
      picture,
      figure {
        width: auto !important;
      }
    }

    .owl-buttons {
      > div {
        width: 18px;
        height: 30px;
        margin-top: -40px;
      }
      .owl-prev {
        background: url(../images/home/testimonials/testimonial-left.svg) no-repeat;
        left: -10px;
        @include media-breakpoint-up(lg) {
          left: -60px;
        }
      }
      .owl-next {
        background: url(../images/home/testimonials/testimonial-right.svg) no-repeat;
        right: -10px;
        @include media-breakpoint-up(lg) {
          right: -60px;
        }
      }
    }
  }
  .social-logos {
    &--item {
      &:not(.current) {
        display: none;
      }
      img {
        max-width: 100%;
        object-fit: contain;
        -webkit-animation: animation-fadeIn  1s linear;
        animation: animation-fadeIn  1s linear;
      }
    }
  }

  .flip-inner {
    position: relative;
    transition: transform 1s;
    transition-timing-function: ease;
    transform-style: preserve-3d;
    padding-top: 90.3%;
  }
  .front, .back {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

  }
  .back {
    transform: rotateY(180deg);
  }
  .flipOnce {
    transform: rotateY(180deg);
  }

  .webinars {
    &--card {
      background: $white;
      border: 1px solid $gray-300;
      border-radius: 8px;
      picture {
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.08));
      }
      .card-role {
        background: $gray-300;
      }
      .card-author {
        background: $gray-300;
        span {
          font-weight: 400;
          color: $light-gray-600;
          @extend %font-primary;
        }
      }
      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        margin-top: -20px;
      }
      &:hover {
        border: 1px solid #F7F9FA;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
        .card-role,
        .card-author {
          background: #E0EBF1;
        }
      }
    }
  }

  .get-started-new {
    &--philip {
      .section-inner {
        padding-top: 55px;
        padding-bottom: 55px;
      }
      .get-started-name-wrapper {
        z-index: 9;

        @include media-breakpoint-up(md) {
          bottom: -30px;
          right: -5%;
        }
      }
      .get-started-name {
        background: rgba(#252530, .4);
      }
      .get-started-new--picture {
        @include media-breakpoint-up(md) {
          right: -8%;
          position:absolute;
          top: inherit;
          bottom: -55px;
        }
        @include media-breakpoint-up(lg) {
          right: -8%;
        }
        img {
          display: block;
        }
      }

    }
  }

}
