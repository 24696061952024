// Buttons
// ======================

// Vars
// ----------------------

$btn-border-radius: 3px;
$btn-font-size: 1rem;
$btn-line-height: 24px;
$btn-padding-x: (24/16 + rem);
$btn-padding-y: (12/16 + rem);
$btn-light-padding-x: (23/16 + rem);
$btn-light-padding-y: (11/16 + rem);
$btn-font-weight: $font-weight-medium;
$btn-primary-gradient: linear-gradient(180deg, #78CB38 0%, #70C230 100%);
$btn-primary-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
$btn-primary-bg-start: #78CB38;
$btn-primary-bg-end: #70C230;

$btn-light-bg: #fff;
$btn-light-border: 1px solid #DAE0E6;
$btn-light-color: #7A8A99;

$btn-light-bg--secondary: #F7F9FA;
$btn-light-color--secondary: #006cbb;

$btn-light-padding-x--secondary: (30/16 + rem);
$btn-light-padding-y--secondary: (12/16 + rem);

$btn-sm-padding-y: (8/16 + rem);
$btn-sm-padding-x: 1rem;
$btn-sm-font-size: 12px;
$btn-sm-line-height: 14px;

$btn-md-padding-y: (8/16 + rem);
$btn-md-padding-x: (34/16 + rem);
$btn-md-font-size: (14/16 + rem);
$btn-md-line-height: 20px;

// Base
// ----------------------

.btn {
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  padding: $btn-padding-y $btn-padding-x;
  cursor: pointer;
  border-radius: $btn-border-radius;

  &,
  &:hover {
    outline: none;
  }

  .icon-svg-right {
    margin-left: 2px;
  }
}

// Button variation
//

.btn-primary {
  @include background-gradient($btn-primary-bg-start, $btn-primary-bg-end);

  color: #fff;
  box-shadow: $btn-primary-box-shadow;
  border: 0;

  &,
  &:hover,
  &:active,
  a:focus {
    color: #fff;
  }

  &:hover {
    @include background-gradient(#68AB39, #68AB39);
    color: #fff;
    border: 0;
  }

  &:active,
  &:focus {
    @include background-gradient(#55853A, #55853A);
    box-shadow: 0 0 0 3px rgba(42, 43, 60, 0.15);;
  }
}

.btn-secondary {
  color: #fff;
  background: #006cbb;
  border: 0;
  padding: 10px 50px;
  font-size: (17/16 + rem);

  &:hover {
    color: #fff;
    background: darken(#006cbb, 10%);
  }

  &.btn-lg {
    border-radius: 3px;
    padding: 13px 50px;
  }
}

.btn-light {
  background: $btn-light-bg;
  border: $btn-light-border;
  padding: $btn-light-padding-y $btn-light-padding-x;
  color: $btn-light-color;
}

.btn-light-secondary {
  background: $btn-light-bg--secondary;
  border: 0;
  padding: $btn-light-padding-y--secondary $btn-light-padding-x--secondary;
  color: $btn-light-color--secondary;
  font-size: 14px;
  line-height: 16px;

  &,
  &:focus,
  &:hover {
    outline: none;
    border: 0;
  }

  &:hover {
    background: #006CBB;
    color: #fff;
    border: 0;
  }

  &:active,
  &:focus {
    background: #094F88;
    box-shadow: 0 0 0 3px rgba(42, 43, 60, 0.15);;
  }
}

.btn-rounded {
  border-radius: 20px;
}

.btn-link {
  color: $blue;
}

.btn-radius-white {
  background-color: $white;
  color: $custify-grey;
  padding: 8px 20px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 50px;

  &:hover {
    background-color: $white;
    color: $custify-grey;
  }
}


.btn-outline-white {
  border: 2px solid $white;
  color: $white;
  padding: 8px 20px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;

  &:hover {
    background-color: $white;
    color: $blue;
    border-color: $white;
  }
}
.btn-outline-secondary {
  border: 2px solid $blue;
  color: $blue;
  padding: 8px 20px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;

  &:hover {
    background-color: $blue;
    color: #fff;
    border-color: $blue;
  }
}

.btn-outline-success {
  padding-top: 11px;
  padding-bottom: 11px;

  &:hover {
    background: #78CB38;
    color: #fff;
  }

  &:active,
  &:focus {
    background: #68AB39;
    box-shadow: 0 0 0 3px rgba(42, 43, 60, 0.15);;
  }
}

// Icons
//

.btn-icon {
  &--right {
    .icon-svg {
      margin-left: 4px;
    }
  }
}

// Button sizes
//

.btn-sm {
  font-size: $btn-sm-font-size;
  line-height: $btn-sm-line-height;
  padding: $btn-sm-padding-y $btn-sm-padding-x;
}

.btn-md {
  font-size: $btn-md-font-size;
  line-height: $btn-md-line-height;
  padding: $btn-md-padding-y $btn-md-padding-x;
}

.btn-lg {
  padding: 13px 37px;
  font-size: (17/16 + rem);
}

.btn-xlg {
  width: 100%;
  max-width: 340px;
}


