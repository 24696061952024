// Product
// ======================

.product-health {
  @keyframes animRunSlideForward {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes animRunSlideReverse {
    from {
      opacity: 1;
      visibility: visible;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
  }
  @keyframes animRunSlideReverseOpacity {
    from {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
    to {
      transform: translateX(80%);
      opacity: 0;
      visibility: hidden;
    }
  }
  overflow-x: hidden;
  .graphic-benefit,
  .get-started-new--picture,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }
  .main {
    overflow-x: hidden;
  }
  .get-customer-picture {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .section-animation,
  .content-animation {
    .graphic-item,
    .graphic-item_new,
    .graphic-item_new2 {
      visibility: hidden;
      opacity: 0;
      position: relative;
    }

    &.has-animation {
      .graphic-item {
        animation: animRunSlide .5s linear forwards;
      }
    }
  }
  .get-started,
  .testimonials-fullscreen {
    &:not(.get-started-new) {
      margin: 0;
    }
  }
  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper,
    .graphic-benefit {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }

    .graphic-benefit__left {
      transform: translate(0, -80px);
    }

    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }

    .get-started-new--picture {
      transform: translate(150px, 0);
    }

    .graphic__get-started--right {
      transform: translate(50px, 0);
    }

    .get-started-body {
      transform: translate(-50px, 0);
    }

  }
  .share-insight {
    &--bg {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      margin-right: -25%;
      z-index: -9;
    }
  }
  .content-animation {
    &.has-animation {
      .graphic-item,
      .graphic-item_new {
        animation: animRunSlide .6s linear forwards;
        animation-delay: .3s;
        &.insights {
          &--payment-line,
          &--email-line,
          &--status-line {
            animation: animHeight .6s linear forwards;
          }
        }

        &.personalized-health2 {
          &--red {
            animation: animRunSlideForward .5s linear forwards 4s;
          }
          &--green {
            animation:
              animRunSlideForward .5s linear forwards 1s,
              animRunSlideReverse .5s linear forwards 4s;
          }
          &--rect-red {
            animation: animRunSlideForward .5s linear forwards 4s;
          }
          &--rect-green {

            animation:
              animRunSlideForward .5s linear forwards 1.5s,
              animRunSlideReverse .5s linear forwards 4s;
          }
          &--chart-red {
            animation: animRunSlideForward .5s linear forwards 4s;
          }

          &--chart-green {
            animation:
              animRunSlideForward .5s linear forwards 2s,
              animRunSlideReverse .5s linear forwards 4s;
          }
          &--pointer {
            animation:
              animRunSlideForward .4s linear forwards 3.5s,
              animRunSlideReverse .4s linear forwards 4s;

          }
        }
      }
    }
    img {
      user-select: none;
    }
    //section5
    .share-insight {
      &--chart {
        justify-content: end;
        margin-right: 2%;
        transform: translate(-80%, 0%);
        animation-delay: .4s !important;
        animation-duration: .4s !important;
        @include media-breakpoint-down(xs) {
          margin-left: -20px;
        }
      }

      &--counts {
        top: 0;
        right: 0;
        margin-top: -10%;
        justify-content: end;
        margin-right: 0%;
        transform: translate(-80%, 0%);
        animation-delay: .8s !important;
        animation-duration: .8s !important;
        @include media-breakpoint-down(xs) {
          margin-left: -20px;
        }
      }

      &--rect {
        top: 0;
        right: 0;
        z-index: 1;
        margin-top: 24%;
        margin-right: 13%;
        transform: translate(0%, 80%);
        animation-delay: .8s !important;
        animation-duration: .4s !important;
        @include media-breakpoint-down(xs) {
          max-width: 40vw;
          margin-top: 50%;
          margin-left: 50%;
        }
      }

      &--persons {
        top: 0;
        right: 0;
        z-index: 2;
        margin-top: 34%;
        margin-right: 5%;
        @include media-breakpoint-down(xs) {
          margin-top: 65%;
          margin-left: 50%;
        }
      }

      &--john {
        margin-left: -65%;
        margin-bottom: -15px;
        transform: translate(0%, 80%);
        animation-delay: 1s !important;
        animation-duration: .4s !important;
        @include media-breakpoint-down(xs) {
          max-width: 44vw;
          margin-left: -31%;
          margin-bottom: -10px;
        }
      }

      &--margaret {
        margin-right: -25%;
        margin-bottom: -15px;
        transform: translate(0%, 80%);
        animation-delay: 1.2s !important;
        animation-duration: .4s !important;
        @include media-breakpoint-down(xs) {
          max-width: 50vw;
          margin-left: 10%;
          margin-right: 0;
          margin-bottom: -10px;
        }
      }

      &--anne {
        margin-left: -25%;
        transform: translate(0%, 80%);
        animation-delay: 1.4s !important;
        animation-duration: .4s !important;
        @include media-breakpoint-down(xs) {
          max-width: 44vw;
          margin-left: -20%;
        }
      }

      &--bg {
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        margin-top: -10%;
        margin-right: -20%;
        z-index: -9;
        width: 100%;
      }
    }
  }
  //master-section--item1
  .seamless-integration {
    &--payment {
      display: block;
      transform: translate(0px, -50px);
      animation-delay: .1s !important;
      max-width: 45vw;
      margin: 4% 0 0 -10%;
      @include media-breakpoint-down(sm) {
        margin: 4% 0 0 20%;
      }

    }

    &--payment-line {
      top: 0px;
      left: 0px;
      z-index: -1;
      animation-delay: 1.5s !important;
      overflow: hidden;
      max-width: 9vw;
      margin-top: 8%;
      margin-left: 37%;
      @include media-breakpoint-down(sm) {
        margin-top: 11%;
        margin-left: 38%;
      }
    }

    &--touchpoint {
      display: block;
      transform: translate(0px, -50px);
      animation-delay: 1.5s !important;
      max-width: 50vw;
      margin: 2% 0 0 40%;
      @include media-breakpoint-down(sm) {
        margin: 2% 0 0 45%;
      }
    }

    &--touchpoint-line {
      top: 0;
      left: 0;
      z-index: -1;
      animation-delay: 2.5s !important;
      overflow: hidden;
      max-width: 9vw;
      margin-top: 15%;
      margin-left: 60%;
      @include media-breakpoint-down(sm) {
        margin-top: 10%;
        margin-left: 65%;
      }
    }

    &--tickets {
      display: block;
      transform: translate(0px, -50px);
      animation-delay: 2.5s !important;
      max-width: 50vw;
      margin: 1% 0 0 -35%;
      @include media-breakpoint-down(sm) {
        margin: 1% 0 0 0%;
      }
    }

    &--tickets-line {
      top: 0;
      left: 0;
      z-index: -1;
      animation-delay: 3.5s !important;
      overflow: hidden;
      max-width: 9vw;
      margin-top: 33%;
      margin-left: 23%;
      @include media-breakpoint-down(sm) {
        margin-top: 40%;
        margin-left: 25%;
      }

    }


    &--rect {
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      transform: translate(0px, 50px);
      animation-delay: .25s !important;
      margin: 10% 0 0 0;
      max-width: 85vw;

      @include media-breakpoint-down(sm) {
        //margin: 4% 8% 0 0;
      }
    }

    &--bg {
      top: 100px;
      left: 100px;
      z-index: -9;
    }
  }
  .master-section {
    &--items {
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      margin-top: 6%;
      margin-left: 0%;
    }

    &--item1 {
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      margin-top: 0;
      margin-left: -14%;
      animation-delay: .8s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        margin-top: 3%;
        margin-left: -4%;
        max-width: 80vw;
      }
    }

    &--item2 {
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      margin-top: -5px;
      margin-left: -8%;
      animation-delay: 1s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        margin-top: -5px;
        margin-left: -3%;
        max-width: 80vw;
      }
    }

    &--item3 {
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      margin-top: -5px;
      margin-left: -4%;
      animation-delay: 1.2s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        margin-left: -2%;
        max-width: 80vw;
      }
    }

    &--item4 {
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      margin-top: -5px;
      margin-left: 0;
      animation-delay: 1.4s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        margin-left: -1%;
        max-width: 80vw;
      }
    }

    &--itemOverlay {
      display: none;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
      margin-top: -5px;
      margin-left: 0;
      animation-delay: 1.4s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        margin-left: -1%;
        max-width: 80vw;
      }
    }

    &--rect {
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      transform: translate(0%, -80%);
      animation-delay: .4s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(sm) {
        margin-left: 2%;
        max-width: 85vw;
      }
    }

    &--bg {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: -1;
    }
  }
  .nav-icons {
    color: #2F3941;
    border: 0;
    .nav-link {
      color: #2F3941;
      display: flex;
      align-items: center;
      margin: 1em;
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
      &:hover,
      &.active {
        border: 0;
      }
    }

    .icon-svg {
      circle {
        fill: rgba(#8B8C96, .15);
      }

      path {
        stroke: #2F3941;
      }
    }

    .item-green.active {
      h6 {
        color: #70C230;
      }

      border-radius: 4px;

      &:before {
        content: '';
        height: 100%;
        width: 4px;
        border-radius: 10px;
        background: #70C230;
        display: block;
        position: absolute;
        left: 0;
      }

      .icon-svg {
        circle {
          fill: rgba(#70C230, .15);
        }

        path {
          stroke: #70C230;
        }
      }

      .progress-ring__circle {
        stroke: #70C230;
      }
    }

    .item-blue.active {
      h6 {
        color: #006CBB;
      }

      border-radius: 4px;

      &:before {
        content: '';
        height: 100%;
        width: 4px;
        border-radius: 10px;
        background: #006CBB;
        display: block;
        position: absolute;
        left: 0;
      }

      .icon-svg {
        circle {
          fill: rgba(#006CBB, .15);
        }

        path {
          stroke: #006CBB;
        }
      }

      .progress-ring__circle {
        stroke: #006CBB;
      }
    }

    .item-orange.active {
      h6 {
        color: #F79A3E;
      }

      border-radius: 4px;

      &:before {
        content: '';
        height: 100%;
        width: 4px;
        border-radius: 10px;
        background: #F79A3E;
        display: block;
        position: absolute;
        left: 0;
      }

      .icon-svg {
        circle {
          fill: rgba(#F79A3E, .12);
        }

        path {
          stroke: #F79A3E;
        }
      }

      .progress-ring__circle {
        stroke: #F79A3E;
      }
    }

    .progress-circle {
      position: relative;
    }

    .progress-ring__circle {
      //transition: 0.45s stroke-dashoffset;
      // axis compensation
      transform-origin: 50% 50%;
      transform: rotate(-90deg);
    }

    .progress-ring {
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  //
  .react-time-section {
    &--chart {
      margin-top: -5px;
      margin-left: -8%;
      animation-delay: .4s !important;
      transform: translate(0%, -80%);
      animation-duration: .4s !important;
      max-width: 85vw;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }
    &--pointer {
      z-index: 9;
      bottom: 0;
      margin-bottom: 5.5%;
      margin-left: 41.8%;
      animation-delay: 2.4s !important;
      transform: translate(80%, 80%);
      animation-duration: .4s !important;
      max-width: 8vw;
      @include media-breakpoint-down(sm) {

      }
    }
    &--notify {
      opacity: 0;
      z-index: 9;
      bottom: 0;
      margin-bottom: -8%;
      margin-left: 41%;
      transform: translate(80%, 0%);
      animation-delay: .1s !important;
      animation-duration: .4s !important;
      max-width: 50vw;
      @include media-breakpoint-down(sm) {

      }
    }
    &--turn {
      z-index: 9;
      bottom: 0;
      margin-bottom: -8%;
      margin-left: 41%;
      animation-delay: 3s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
      max-width: 50vw;
      &-hover {
        display: none;
      }
      &:hover {
        .react-time-section--turn-hover {
          display: block;
        }
        .react-time-section--turn-normal {
          display: none;
        }
      }

      @include media-breakpoint-down(sm) {

      }
    }
    &--alert {
      top: 0;
      left: 0;
      margin-top: -10%;
      margin-left: 8%;
      z-index: 9;
      animation-delay: 2s !important;
      transform: translate(-80%, 0%);
      animation-duration: .4s !important;
      max-width: 60vw;
      @include media-breakpoint-down(sm) {
        margin-left: 10%;
      }
    }
    &--bg {
      top: 0;
      right: 0;
      margin-top: -15%;
      margin-right: -5%;
      z-index: -1;
    }
    @keyframes svg-elem-8 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: .55;
        fill: rgb(187, 220, 241);
      }
    }
    @keyframes svg-elem-9 {
      0% {
        stroke-dashoffset: 324.9979248046875px;
      }
      100% {
        stroke-dashoffset: 0px;
      }
    }
    @keyframes svg-elem-34 {
      0% {
        opacity: 0%;
      }
      100% {
        opacity: 100%;
      }
    }
    @keyframes svg-elem-35 {
      100% {
        stroke-dashoffset: 0;
      }
      0% {
        stroke-dashoffset: 280.79071044921875px;
      }
    }
    @keyframes svg-elem-40 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    svg {
      .svg-elem-1 {
        stroke-dashoffset: 1880.800048828125px;
        stroke-dasharray: 1880.800048828125px;
        fill: transparent;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
      }
      .svg-elem-2 {
        stroke-dashoffset: 1868.234130859375px;
        stroke-dasharray: 1868.234130859375px;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.12s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
      }
      .svg-elem-3 {
        stroke-dashoffset: 1635.41650390625px;
        stroke-dasharray: 1635.41650390625px;
        fill: transparent;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.24s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
      }
      .svg-elem-4 {
        stroke-dashoffset: 2px;
        stroke-dasharray: 2px;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3599999999999999s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
      }
      .svg-elem-5 {
        stroke-dashoffset: 2px;
        stroke-dasharray: 2px;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.48s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s;
      }
      .svg-elem-6 {
        stroke-dashoffset: 2px;
        stroke-dasharray: 2px;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
      }
      .svg-elem-7 {
        stroke-dashoffset: 2px;
        stroke-dasharray: 2px;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 1.72s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
      }
      .svg-elem-8 {
        stroke-dashoffset: 938px;
        stroke-dasharray: 938px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-9 {
        stroke-dashoffset: 324.9979248046875px;
        stroke-dasharray: 324.9979248046875px;
        transition: fill 2s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
      }
      .svg-elem-19 {
        stroke-dashoffset: 1006px;
        stroke-dasharray: 1006px;
        fill: transparent;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 3.16s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s;
      }
      .svg-elem-33 {
        stroke-dashoffset: 204px;
        stroke-dasharray: 204px;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 4.84s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 5.2s;
      }
      .svg-elem-34 {
        stroke-dashoffset: 204px;
        opacity: 0;
      }
      .svg-elem-35 {
        stroke-dashoffset: 280.79071044921875px;
        stroke-dasharray: 280.79071044921875px;
        transition: fill 2s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
      }
      .svg-elem-36 {
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-37 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        opacity: 1;
      }
      .svg-elem-38 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-39 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        opacity: 0;
      }
      .svg-elem-40 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-41 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        transition: stroke-dashoffset 3s cubic-bezier(0.47, 0, 0.745, 0.715) 5.8s, fill 5.7s cubic-bezier(0.47, 0, 0.745, 0.715) 6s;
        opacity: 0;
      }
      .svg-elem-42 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-43 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        opacity: 0;
      }
      .svg-elem-44 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-45 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        opacity: 0;
      }
      .svg-elem-46 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-47 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        opacity: 0;
      }
      .svg-elem-48 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-49 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        opacity: 0;
      }
      .svg-elem-50 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-51 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        opacity: 0;
      }
      .svg-elem-52 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        fill: transparent;
        opacity: 0;
      }
      .svg-elem-53 {
        stroke-dashoffset: 35.31552194981754px;
        stroke-dasharray: 35.31552194981754px;
        opacity: 0;
      }
      .svg-elem-54 {
        stroke-dashoffset: 1633.8458251953125px;
        stroke-dasharray: 1633.8458251953125px;
      }
    }
    .play-animation {
      .svg-elem-8 {
        animation: svg-elem-8 0.5s;
        animation-delay: .3s;
        animation-fill-mode: forwards;
      }
      .svg-elem-9 {
        animation: svg-elem-9 2s cubic-bezier(0.47, 0, 0.745, 0.715);
        animation-delay: .3s;
        animation-fill-mode: forwards;
      }
      .svg-elem-46 {
        stroke-dashoffset: 0;
        fill: rgb(120, 203, 56);
        animation: svg-elem-40 .3s;
        animation-delay: 1.3s;
        animation-fill-mode: forwards;
      }
      .svg-elem-47 {
        stroke-dashoffset: 0;
        animation: svg-elem-40 .3s;
        animation-delay: 1.3s;
        animation-fill-mode: forwards;
      }
      .svg-elem-48 {
        stroke-dashoffset: 0;
        fill: rgb(120, 203, 56);
        animation: svg-elem-40 .3s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
      }
      .svg-elem-49 {
        stroke-dashoffset: 0;
        animation: svg-elem-40 .3s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;
      }
      .svg-elem-50 {
        stroke-dashoffset: 0;
        fill: rgb(120, 203, 56);
        animation: svg-elem-40 .3s;
        animation-delay: 1.8s;
        animation-fill-mode: forwards;
      }
      .svg-elem-51 {
        stroke-dashoffset: 0;
        animation: svg-elem-40 .3s;
        animation-delay: 1.8s;
        animation-fill-mode: forwards;
      }
      .svg-elem-52 {
        stroke-dashoffset: 0;
        fill: rgb(120, 203, 56);
        animation: svg-elem-40 .3s;
        animation-delay: 2.2s;
        animation-fill-mode: forwards;
      }
      .svg-elem-53 {
        stroke-dashoffset: 0;
        animation: svg-elem-40 .3s;
        animation-delay: 2.2s;
        animation-fill-mode: forwards;
      }
    }
    .has-animation {
      svg.active {
        .svg-elem-1 {
          stroke-dashoffset: 0;
          fill: rgb(255, 255, 255);
        }
        .svg-elem-2 {
          stroke-dashoffset: 0;
        }
        .svg-elem-3 {
          stroke-dashoffset: 0;
          fill: rgb(255, 255, 255);
        }
        .svg-elem-4 {
          stroke-dashoffset: 0;
        }
        .svg-elem-5 {
          stroke-dashoffset: 0;
        }
        .svg-elem-6 {
          stroke-dashoffset: 0;
        }
        .svg-elem-7 {
          stroke-dashoffset: 0;
        }
        .svg-elem-19 {
          stroke-dashoffset: 0;
          fill: rgb(229, 231, 235);
        }
        .svg-elem-33 {
          stroke-dashoffset: 0;
        }
        .svg-elem-34 {
          animation: svg-elem-34 0.3s;
          animation-delay: 3s;
          animation-fill-mode: forwards;
        }
        .svg-elem-35 {
          animation: svg-elem-35 2s cubic-bezier(0.47, 0, 0.745, 0.715);
          animation-delay: 1s;
          animation-fill-mode: forwards;
        }
        .svg-elem-36 {
          fill: rgb(255, 73, 44);
          animation: svg-elem-40 .1s;
          animation-delay: 2s;
          animation-fill-mode: forwards;
        }
        .svg-elem-37 {
          stroke-dashoffset: 0;
          animation: svg-elem-40 .3s;
          animation-delay: 2s;
          animation-fill-mode: forwards;
        }
        .svg-elem-38 {
          stroke-dashoffset: 0;
          fill: rgb(255, 73, 44);
          animation: svg-elem-40 .3s;
          animation-delay: 1.6s;
          animation-fill-mode: forwards;
        }
        .svg-elem-39 {
          stroke-dashoffset: 0;
          animation: svg-elem-40 .3s;
          animation-delay: 1.6s;
          animation-fill-mode: forwards;
        }
        .svg-elem-40 {
          stroke-dashoffset: 0;
          fill: rgb(255, 73, 44);
          animation: svg-elem-40 .3s;
          animation-delay: 1.2s;
          animation-fill-mode: forwards;
        }
        .svg-elem-41 {
          stroke-dashoffset: 0;
          animation: svg-elem-40 .3s;
          animation-delay: 1.2s;
          animation-fill-mode: forwards;
        }
        .svg-elem-42 {
          stroke-dashoffset: 0;
          fill: rgb(255, 73, 44);
          animation: svg-elem-40 .3s;
          animation-delay: 2.4s;
          animation-fill-mode: forwards;
        }
        .svg-elem-43 {
          stroke-dashoffset: 0;
          animation: svg-elem-40 .3s;
          animation-delay: 2.4s;
          animation-fill-mode: forwards;
        }
        .svg-elem-44 {
          stroke-dashoffset: 0;
          fill: rgb(255, 73, 44);
          animation: svg-elem-40 .3s;
          animation-delay: 2.8s;
          animation-fill-mode: forwards;
        }
        .svg-elem-45 {
          stroke-dashoffset: 0;
          animation: svg-elem-40 .3s;
          animation-delay: 2.8s;
          animation-fill-mode: forwards;
        }
        .svg-elem-54 {
          stroke-dashoffset: 0;
        }
      }
    }
  }
  .signals {
    &--bg {
      top: 0;
      right: 0;
      margin-top: 0%;
      margin-right: -25%;
      z-index: -9;

    }
    &--pointer {
      top: 0;
      right: 0;
      margin-top: 17%;
      margin-right: 42%;
      animation-delay: 1s !important;
      transform: translate(120%, 0%);
      animation-duration: .6s !important;
      @include media-breakpoint-down(sm) {
        max-width: 8vw;
      }
    }

    &--activity {
      animation-delay: .4s !important;
      transform: translate(80%, 0%);
      animation-duration: .4s !important;
    }

    &--date {
      top: 0;
      right: 0;
      margin-top: 26%;
      margin-right: 2%;
      animation-delay: 1.8s !important;
      transform: translate(80%, 0%);
      animation-duration: .6s !important;
      @include media-breakpoint-down(sm) {
        max-width: 34vw;
      }
    }


    &--info1 {
      bottom: 0;
      right: 0;
      margin-bottom: 15%;
      margin-right: 2%;
      margin-left: 2%;
      animation-delay: 2.4s !important;
      transform: translate(80%, 0%);
      animation-duration: .6s !important;
      @include media-breakpoint-down(sm) {
        max-width: 78vw;
      }
    }
    &--info2 {
      bottom: 0;
      right: 0;
      margin-bottom: 2%;
      margin-right: 2%;
      margin-left: 2%;
      animation-delay: 3s !important;
      transform: translate(80%, 0%);
      animation-duration: .6s !important;
      @include media-breakpoint-down(sm) {
        max-width: 78vw;
      }
    }
    &--customize {
      &-start {
        margin-left: 20%;
        animation-delay: .5s !important;
        transform: translate(0%, -80%);
        animation-duration: .4s !important;
      }

      &-enabled {
        margin-left: -30%;
        animation-delay: .9s !important;
        transform: translate(0%, -80%);
        animation-duration: .4s !important;
      }

      &-decreased {
        margin-top: 3%;
        animation-delay: 1.3s !important;
        transform: translate(0%, -80%);
        animation-duration: .4s !important;
      }

      &-incresead {
        margin-top: 3%;
        animation-delay: 1.7s !important;
        transform: translate(0%, -80%);
        animation-duration: .4s !important;
      }

      &-notify {
        margin-top: 3%;
        margin-left: 15%;
        animation-delay: 2.1s !important;
        transform: translate(0%, -80%);
        animation-duration: .4s !important;
      }

      &-line {
        z-index: -1;
        top: 0;
        left: 50%;
        margin-top: 16%;
        animation-delay: 2.5s !important;
        animation-duration: .4s !important;

      }

    }

    &--drive {
      &-item1 {
        top: 0;
        left: 0;
        right: 0;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 23%;
        animation-delay: 2.4s !important;
        transform: translate(-80%, 0%);
        animation-duration: .4s !important;
      }
      &-item2 {
        top: 0;
        left: 0;
        right: 0;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 38%;
        animation-delay: 2.8s !important;
        transform: translate(-80%, 0%);
        animation-duration: .4s !important;
      }
      &-item3 {
        top: 0;
        left: 0;
        right: 0;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 53%;
        animation-delay: 3.2s !important;
        transform: translate(-80%, 0%);
        animation-duration: .4s !important;
      }
      &-toggle {
        @keyframes changeColorRect {
          from {
            fill: #767D8A;
          }
          to {
            fill: #78CB38;
          }
        }
        @keyframes moveCircle {
          from {
            transform: translate(0px,0px)
          }
          to {
            transform: translate(15px,0px)
          }
        }
        .rect {
          animation: changeColorRect .5s linear forwards;
          animation-delay: 2s !important;
        }
        .circle {
          animation: moveCircle .5s linear forwards;
          animation-delay: 2s !important;
        }

        top: 0;
        right: 0;
        margin-top: 15%;
        margin-right: 4%;
        z-index: 9;
        transform: translate(0%, 0%);
      }


      &-rect {

      }

      &-pointer {
        top: 0;
        right: 0;
        margin-top: 17%;
        margin-right: -2%;
        z-index: 9;
        animation-delay: .8s !important;
        transform: translate(-80%, 0%);
        animation-duration: .4s !important;
        @include media-breakpoint-down(sm) {
          max-width: 8vw;
        }
      }

      &-notify {
        top: 0;
        right: 0;
        margin-top: -12%;
        margin-right: -15%;
        z-index: 9;
        animation-delay: 1.2s !important;
        transform: translate(-80%, 0%);
        animation-duration: .4s !important;
        max-width: 60vw;
        @include media-breakpoint-down(sm) {
          margin-top: -5%;
          margin-right: -6%;
        }
      }
    }
    &-section {
      .tab-content > .active {
        display: inline-block;
      }
    }
  }
  .personalized-health {
    &--grey {
      top: 0;
      left: 0;
      margin-top: 18%;
      margin-left: -34%;
      animation-delay: .6s !important;
      animation-duration: .4s !important;
      margin-bottom: 5%;
      z-index: 1;
    }
    &--red {
      top: 0;
      left: 0;
      margin-top: 5%;
      margin-left: 30%;
      transform: translate(0%, 0%);
      z-index: 9;
      visibility: hidden;
      opacity: 0;
      @include media-breakpoint-down(md) {
        margin-right: 5%;
      }
    }
    &--green {
      top: 0;
      left: 0;
      margin-top: 5%;
      margin-left: 30%;
      transform: translate(-80%, 0%);
      z-index: 9;
      animation-delay: 1s !important;
      animation-duration: .4s !important;

      visibility: hidden;
      opacity: 0;
      @include media-breakpoint-down(md) {
        margin-right: 5%;
      }
    }

    &--rect-red {
      top: 0;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
    }
    &--rect-green {
      top: 0;
      left: 0;
      right: 0;
      animation-delay: 1.5s !important;
      animation-duration: .4s !important;
      transform: translate(-80%, 0%);
    }

    &--chart-red {
      margin-top: -4%;
      margin-right: 15%;
      transform: translate(0%, 0%);
      right: 0;
      visibility: hidden;
      opacity: 0;
    }
    &--chart-green {
      animation-delay: 1.9s !important;
      margin-top: -4%;
      margin-right: 15%;
      transform: translate(-80%, 0%);
      right: 0;

    }

    &--line-red-top {
      top: 0;
      right: 0;
      margin-right: 45%;
      margin-top: 10%;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
      @include media-breakpoint-down(md) {

      }

    }
    &--line-red-bottom {
      bottom: 0;
      right: 0;
      margin-right: 45%;
      margin-bottom: -10%;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
      @include media-breakpoint-down(md) {

      }
    }

    &--line-green-top {
      top: 0;
      right: 0;
      margin-right: 45%;
      margin-top: 10%;
      z-index: -1;
      animation-delay: 1.5s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(md) {

      }
    }
    &--line-green-bottom {
      bottom: 0;
      right: 0;
      margin-right: 45%;
      margin-bottom: -10%;
      z-index: -1;
      animation-delay: 2.3s !important;
      animation-duration: .4s !important;
      @include media-breakpoint-down(md) {

      }
    }

    &--bg {
      top: 0;
      right: 0;
      z-index: -9;
      margin-right: -10%;
      margin-top: 20%;
    }
    &--pointer-green {
      z-index: 9;
      top: 0;
      right: 0;
      margin-top: 16%;
      margin-right: 40%;
      animation-delay: 5s !important;
      animation-duration: .4s !important;

      transform: translate(120%, 80%);
      max-width: 8vw;
      @include media-breakpoint-down(sm) {
        margin-top: 25%;
      }
    }
    &--pointer-red {
      z-index: 9;
      top: 0;
      right: 0;
      margin-top: 15%;
      margin-right: 29%;
      animation-delay: 5s !important;
      animation-duration: .4s !important;
      visibility: hidden;
      opacity: 0;
      //margin-top: 13%;
      //  margin-right: 29%;
      transform: translate(120%, 80%);
      max-width: 8vw;
      @include media-breakpoint-down(sm) {
        margin-top: 25%;
      }
    }
  }
  .read-more-testimonial {
    //max-height: 4em;
    //line-height: 1.5;
    //overflow: hidden;
  }
  .js-show-more-testimonial-hero {
    background: #171A2D;
    color: #6A6C83;
    text-decoration: none;
    display: flex;
    justify-content: start;
    padding: 0 0 3.5rem 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 18px;
    line-height: 24px;
    left: 0;
  }
  .hero-testimonial-svg {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -12%;
  }
  .hero-cover {
    &--author {
      &.right {

        max-width: 100%;
        @include media-breakpoint-up(sm) {

        }
        @include media-breakpoint-up(md) {
          max-width: 100%;
        }
        @include media-breakpoint-up(lg) {
          max-width: 100%;
        }
        @include media-breakpoint-up(xl) {
          max-width: 100%;
        }
      }

    }
  }
  .pe-pointer {
    cursor: pointer;
  }
}

.has-animation {
  &.green {
    &.personalized--health-groups {
      .personalized-health {
        &--red,
        &--chart-red,
        &--rect-red,
        &--line-red-top,
        &--line-red-bottom,
        &--pointer-red {
          visibility: hidden;
          opacity: 0;
          position: relative;
          animation-name: none !important;
        }

        &--green {
          animation: animRunSlide .6s linear forwards!important;
          animation-delay: 0s !important;
          transform: translate(0%, 0%);
        }
        &--rect-green {
          animation: animRunSlide .6s linear forwards!important;
          animation-delay: 0s !important;
          transform: translate(0%, 0%);
        }
        &--chart-green {
          animation: animRunSlide .6s linear forwards!important;
          animation-delay: 0s !important;
          transform: translate(0%, 0%);
        }

        &--line-green-top,
        &--line-green-bottom {
          animation: animRunSlide .6s linear forwards !important;
          animation-delay: 0s !important;
        }

        &--pointer-green {
          animation-delay: 3s !important;
          animation-duration: .4s !important;
        }

      }
    }
  }

  &.red {
    &.personalized--health-groups {

      .personalized-health {
        &--rect-green,
        &--chart-green,
        &--green,
        &--line-green-top,
        &--line-green-bottom,
        &--pointer-green {
          visibility: hidden;
          opacity: 0;
          position: relative;
          animation-name: none !important;
        }

        &--red {
          animation: animRunSlide .6s linear forwards!important;
          animation-delay: 0s !important;
          transform: translate(0%, 0%);
        }
        &--rect-red {
          animation: animRunSlide .6s linear forwards!important;
          animation-delay: 0s !important;
          transform: translate(0%, 0%);
        }
        &--chart-red {
          animation: animRunSlide .6s linear forwards!important;
          animation-delay: 0s !important;
          transform: translate(0%, 0%);
        }
        &--pointer-red {
          animation: animRunSlide .6s linear forwards!important;
          animation-delay: 3s !important;
          animation-duration: .4s !important;
        }
        &--line-red-top,
        &--line-red-bottom {
          animation: animRunSlide .6s linear forwards!important;
          animation-delay: 0s !important;
        }
      }
    }
  }

}

