.dropdown {
  display: none;
  visibility: visible;
  opacity: 1;
  transition: transform .3s linear, opacity .3s linear, visibility .3s linear;

  @include media-breakpoint-up(lg) {
    background: #fff;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 20px);
    opacity: 0;
    width: 260px;
    visibility: hidden;
    display: block;
    will-change: transform, opacity;
    transition: transform .3s linear, opacity .3s linear, visibility .3s linear;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
  }

  &.show {
    display: block;
  }

  &.dropdown-multi {
    @include media-breakpoint-up(lg) {
      width: 640px;
    }
  }

  .drop-body {
    padding: 25px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 26px 24px 10px;
    }
  }

  .dl-desc {
    @extend %font-size-xs;

    display: block;
    position: relative;
    overflow: hidden;
    line-height: 16px;

    span {
      display: block;
    }

    .dl-sub {
      @include media-breakpoint-down(sm) {
        color: rgba($blue-900, 0.8);
      }
    }
  }

  .dl-title {
    @extend %font-size-md;

    color: $dropdown-title;
    font-weight: 600;
    line-height: 18px;
    margin: 0 0 4px;
    transition: color 0.3s;
    position: relative;
    display: inline-block;

    .badge {
      position: absolute;
      top: 0;
      left: 100%;
      margin-left: 12px;
    }
  }
}

.drop-menu {
  &.drop-menu-columns {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    li {
      @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: 50%;
        padding-right: 10px;
      }
    }
  }

  li {
    position: relative;
    padding: 4px 0 0 44px;
    margin: 0 0 22px;

    @include media-breakpoint-down(md) {
      &:last-child {
        margin: 0;
      }
    }

    &:hover {
      .icon-svg {
        circle {
          @extend .icon-svg--circle-fill-hover;
        }

        path,
        rect {
          @extend .icon-svg--item-fill-hover;
        }
      }
    }

    .icon-svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    a {
      color: $dropdown-text;
      display: block;
      transition: color 0.3s;

      &:hover {
        text-decoration: none;
        color: $dropdown-text-hover;

        .dl-title {
          color: $dropdown-title-hover;
        }
      }
    }
  }
}

.drop-sep {
  margin: 32px 0;
  padding: 0;
  border-top: 1px solid $dropdown-sep;
}

.toggle-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  display: block;
  will-change: transform;
  transition: transform .3s linear;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.nav-item {
  &.is-open {
    .toggle-dropdown {
      transform: rotate(180deg);
    }
  }
}
