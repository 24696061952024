// Articles
// ======================

// Vars
// ----------------------
$background-top: #383A4F;
$emal-background: #4C4E61;
$text-button: #383A4F;
$nl-spacing-left: 140px;

// Base
// ----------------------

.articles {
  .header {
    background: #fff;
    transition: transform 0.4s;
  }

  .main {
    padding-top: 240px;
  }

  .page-header {
    text-align: center;
    margin-bottom: 50px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;

      &,
      a {
        color: $blue-900;
        opacity: 0.4;
      }
    }
  }

  .blockquote {
    border: 0;
    margin: 35px 0 35px 0;
    padding: 20px 0 0 20px;
    position: relative;

    @include media-breakpoint-up(md) {
      margin: 40px 0 40px 0;
      padding: 37px 0 0 37px;
    }

    &:before {
      background: url(../images/svg/blockquote-graphic.svg) no-repeat;
      width: 40px;
      height: 40px;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
    }

    p {
      font-style: italic;

      @include media-breakpoint-up(md) {
        font-size: (22/16 + rem);
      }
    }
  }

  &.has-scroll {
    &.header--pinned {
      .article-header {
        transform: translateY(65px);
      }

      .social-share {
        transform: translateY(190px);
      }
    }

    &.header--unpinned {
      .article-header {
        transform: translateY(0);
      }
    }
  }

  .header--unpinned {
    transform: translateY(-100%);
  }

  .header--pinned {
    transform: translateY(0);
  }

  .iframe--subscribe {
    position: fixed;
    bottom: 110px;
    width: 400px;
    height: 100%;
    max-height: 300px;
    max-width: inherit;
    display: block;
    right: -10px;
    z-index: 10005;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100px);
    transition: all 0.5s;
    padding: 5px;
    overflow: hidden;

    .close-newsletter {
      display: inline-block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
      z-index: 1;
      line-height: 0;
    }

    &.is-visible {
      @include media-breakpoint-up(lg) {
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.article-header {
  background-color: $background-top;
  position: absolute;
  top: 0;
  width: 100%;
  transform: translateY(95px);
  transition: transform 0.4s;

  &.has-scroll {
    position: fixed;
    z-index: 10;
    transform: translateY(0);
  }

  @include media-breakpoint-down(md) {
    transform: translateY(70px);
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;

    @include media-breakpoint-down(md) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .mailchimp-embed-form {
    color: #fff;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      max-width: 313px;
    }
  }

  .category-links {
    @include media-breakpoint-up(md) {
      padding: 8px 0 0;
    }

    @include media-breakpoint-down(md) {
      margin: -40px 0 0;
      display: flex;
      justify-content: flex-end;
    }
  }

  .header-left {
    display: none;
    margin-left: auto;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: flex-start;
    }

    .form-input {
      display: flex;
      flex-direction: row;
    }

    a {
      color: $white;
      margin-left: 10px;
      line-height: 41px;

      @include media-breakpoint-up(md) {
        margin-left: 20px;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 40px;
        margin-left: 0;
        line-height: 24px;
      }
    }

    input {
      background-color: $emal-background;
      min-width: 218px;
      border-radius: 3px 0 0 3px;
    }

    input::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      opacity: 0.4;
    }

    input,
    .btn {
      height: 38px;
      border: 0 solid $white;

      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: $text-button;
    }

    .btn {
      border-radius: 0 3px 3px 0;
    }
  }

  .form-group {
    margin: 0;
  }

  h3 {
    text-transform: capitalize;
    margin: 0;

    @include media-breakpoint-up(lg) {
      padding-top: 8px;
    }

    @include media-breakpoint-down(lg) {
      line-height: 41px;
    }

    a {
      display: block;
      color: $white;
      font-size: 16px;
      font-weight: $font-weight-semibold;
      position: relative;
      padding-left: 28px;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
      }

      &,
      &:hover {
        text-decoration: none;
      }

      &:hover {
        &::before {
          transform: translateY(-2px);
          opacity: 1;
        }
      }

      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateY(2px);
        transition: all 0.3s;
        opacity: 0;
      }
    }

    .icon-svg {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .mailchimp-embed-form {
    @include media-breakpoint-down(md) {
      input {
        margin-left: 0;
      }
    }

    input {
      color: #fff;
    }
  }

  @media (max-width: 400px) {
    .category-links {
      display: flex;
      flex-direction: column;
      text-align: right;
    }
  }

  #mc_embed_signup div.mce_inline_error {
    @include media-breakpoint-down(md) {
      margin: 0 !important;
    }
  }
}

.page-articles {
  display: block;

  @include media-breakpoint-up(lg) {
    margin: auto;
    max-width: 770px;
  }
}

.single-article {
  .has-scroll {
    .header-article {
      display: none !important;
    }
  }

  .header {
    @include media-breakpoint-up(lg) {
      overflow: hidden;
    }

    &::before {
      @include media-breakpoint-up(lg) {
        background: url(../images/elipsis-pattern.png) no-repeat;
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 130px;
        height: 90px;
      }
    }

    @include media-breakpoint-up(lg) {
      min-height: 220px;

      .container {
        &.-top {
          position: relative;

          &::before {
            background: url(../images/header-article-pattern.png) no-repeat;
            content: '';
            position: absolute;
            top: -20px;
            right: -40px;
            width: 559px;
            height: 228px;
          }
        }
      }
    }

    &.has-scroll {
      height: inherit;
      min-height: auto;
    }
  }

  .header-article {
    display: none;

    position: absolute;
    color: #fff;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 65px;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    h2 {
      font-size: 46px;
      line-height: 50px;
      font-weight: 300;
    }
  }

  .main {
    padding: 80px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 300px 0 0;
    }
  }

  .page-header {
    margin: 0 0 50px;
    padding: 0;
  }
}

.article-content {
  border-bottom: 1px solid #DADDE2;
  position: relative;
  padding-bottom: 10px;
  margin: 0 0 40px;

  @include media-breakpoint-up(lg) {
    margin: 0 0 80px;
  }

  em {
    font-style: italic;
  }

  h2,
  h3,
  p,
  ul {
    margin: 0 0 30px;

    @include media-breakpoint-down(md) {
      margin: 0 0 15px;
    }
  }

  h3 {
    text-transform: capitalize;
  }

  h5 {
    font-weight: 500;
    margin: 0 0 20px;
  }

  h3,
  h5,
  strong {
    color: #2f3042;
  }

  h5,
  p,
  li {
    font-size: (18/16 + rem);
    line-height: 26px;
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  p,
  li {
    font-weight: 300;

    font-style: normal;
    font-size: 18px;
    line-height: 30px;
  }

  p + h3 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ul {
    list-style: inside;

    ul {
      padding-left: 20px !important;
      list-style-type: circle;
      margin: 0;
    }
  }

  .article-image {
    margin: 0 0 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }

    img {
      width: 100%;
    }
  }

  .list--styled {
    li {
      &::before {
        display: none;
      }
    }
  }
}

.article-sidebar {
  .btn-link {
    color: #006cbb;
    font-size: (15/16);
    font-weight: 700;
    position: relative;
    padding-left: 15px;
    margin: 0 0 5px;

    .fa {
      font-weight: (24/16 + rem);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-top: -2px;
    }
  }
}

.also-enjoy {
  padding-bottom: 70px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  &-container {
    h3 {
      text-align: center;
      text-transform: uppercase;
      color: $blue-900;
      margin-bottom: 64px;
      position: relative;
      font-size: 18px;
      line-height: 26px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 84px;
      }

      &:before {
        background: url(../images/svg/icon-arrow-down.svg) no-repeat;
        content: "";
        position: absolute;
        top: 30px;
        left: 50%;
        width: 18px;
        height: 20px;
        transform: translateX(-50%);
      }
    }
  }

  .row {
    > div {
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}

.social-share {
  position: absolute;
  top: 0;
  left: -105px;
  display: none;
  transition: transform 0.3s;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  &.stuck {
    position: fixed;
    margin-left: -105px;
    left: inherit;
    transform: translateY(100px);

    &.is-bottom {
      position: static !important;
    }
  }

  strong {
    color: $light-gray-800;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0 0 20px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    li {
      margin: 0 0 30px;
    }

    a {
      display: block;
      cursor: pointer;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.sticky-wrapper {
  height: 1px !important;
}

.row--newsletter {
  > div {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;

    .more-insights {
      position: relative;
      margin: 0;
      padding: 0;

      @include media-breakpoint-up(lg) {
        padding: 94px 80px 64px;
      }

      &--graphic {
        position: absolute;
        display: block;
      }

      &--graphic-top {
        top: 0;
        left: 50%;
        width: 348px;
        transform: translateX(-50%);
      }

      &--graphic-mail {
        left: 50%;
        top: 83px;
        transform: translateX(-50%);

        @include media-breakpoint-up(lg) {
          top: 64px;
          left: 80px;
          transform: translate(0, 0);
        }
      }

      &--graphic-bottom-left {
        left: 14px;
        bottom: 12px;
      }

      &--graphic-bottom-right {
        right: 0;
        bottom: 0;
      }

      &--inner {
        position: relative;
        z-index: 100;
        padding: 240px 24px 0;
        text-align: center;
        margin: 0 0 80px;

        @include media-breakpoint-up(lg) {
          //padding: 0 0 0 $nl-spacing-left !important;;
          margin: 0;
          text-align: left;
        }

        .mailchimp-embed-form {
          @include media-breakpoint-up(lg) {
            //margin-left: -$nl-spacing-left;
          }

          .insights-form {
            max-width: inherit;
          }
        }
      }
    }
  }
}

.article-source {
  border-left: 3px solid #dcdce6;
  display: table;
  width: 100%;
  min-height: 50px;
  padding: 5px 0 5px 17px;
  position: relative;

  > div {
    display: table-cell;
    vertical-align: middle;
  }

  span {
    font-style: italic;
  }
}

.update-date {
  color: rgba(47, 57, 65, 0.9);
}

// off subscribe panel
//

body.subscribe--body {
  overflow: hidden;
  background: none;
}

#newsletter--panel {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08), 0 0 1px rgba(0, 0, 0, 0.2);
  height: auto;
  right: 0;
  padding: 32px 24px 40px;
  position: relative;
  background: none;
  background: #fff;
  margin: 10px;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  .more-insights--inner {
    padding: 0;
    margin: 0;
  }

  h5,
  p {
    color: #2F3941;
  }

  h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    margin: 0 0 16px;
  }

  p {
    margin: 0 0 20px;
    line-height: 24px;
  }

  .mailchimp-embed-form {
    &,
    .form-group {
      margin: 0;
    }

    input.form-control {
      border: 2px solid #DADDE2;
      border-right: 0;
    }
  }

  .insights-form .btn {
    outline: 0;
    box-shadow: 0;
  }
}

#mce-responses {
  font-size: 13px;
  font-weight: 300;
  text-align: left;
  padding: 10px 0 0;
  line-height: 1;
}

// Author
//

.blog-author {
  .sep {
    border-top: 1px solid #DADDE2;
    margin: 0 0 48px;
  }

  .more-title {
    font-size: 20px;
    margin: 0 0 30px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 48px;
    }
  }
}

.author {
  margin-bottom: 60px;

  &-inner {
    position: relative;
    padding-top: 95px;

    @include media-breakpoint-up(sm) {
      padding-top: 0;
      padding-left: 100px;
    }
  }

  &-thumb {
    width: 76px;
    height: 76px;
    border: 4px solid #fff;
    box-shadow: 0 0 0 2px #006CBB;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(sm) {
      left: 0;
      transform: translateX(0);
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  &-content {
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      margin: -5px 0 10px;

      &,
      a {
        color: #2F3941;
      }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    p {
      color: $light-gray-600;
      font-size: 14px;
      margin: 0 0 1rem;
    }
  }

  &-social {
    ul {
      list-style: none;
      display: flex;

      li {
        margin-right: 16px;
      }
    }

    a {
      display: inline-block;
      width: 32px;
      height: 32px;
    }
  }

  // Variation
  &--large {
    .author-thumb {
      @include media-breakpoint-up(sm) {
        width: 170px;
        height: 170px;
        border-width: 6px;
      }
    }

    .author-inner {
      @include media-breakpoint-up(sm) {
        padding-top: 0;
        padding-left: 200px;
      }
    }

    .author-content {
      h3 {
        font-size: 28px;
        line-height: 38px;
        margin: -8px 0 14px;
      }

      p {
        font-size: 1rem;
        color: #41474D;
      }
    }
  }

  &--middle {
    width: 100%;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 80px;
  }
}

// Formula
//

.table.table-formula {
  margin: 0 auto;
  border: 0;
  border-collapse: collapse;
  max-width: 90%;

  @include media-breakpoint-up(md) {
    max-width: 60%;
  }

  td {
    font-style: italic;
    text-align: center;
    border: 0;
  }

  tr:first-child {
    td {
      border-bottom: 1px solid #000;
    }
  }
}

