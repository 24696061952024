// Font face
// ======================

// Vars
// ----------------------

$env: 'development' !default;
$assetPath: '../fonts' !default;


// Base
// ----------------------

// Roboto
//

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
  url('#{$assetPath}/roboto-v19-latin-300.woff2') format('woff2'),
  url('#{$assetPath}/roboto-v19-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
  url('#{$assetPath}/roboto-v19-latin-300italic.woff2') format('woff2'),
  url('#{$assetPath}/roboto-v19-latin-300italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
  url('#{$assetPath}/roboto-v19-latin-regular.woff2') format('woff2'),
  url('#{$assetPath}/roboto-v19-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('#{$assetPath}/roboto-v19-latin-500.woff2') format('woff2'),
  url('#{$assetPath}/roboto-v19-latin-500.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Italic'), local('Roboto-Italic'),
  url('#{$assetPath}/roboto-v19-latin-italic.woff2') format('woff2'),
  url('#{$assetPath}/roboto-v19-latin-italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('#{$assetPath}/roboto-v19-latin-700.woff2') format('woff2'),
  url('#{$assetPath}/roboto-v19-latin-700.woff') format('woff');
}

// Montserrat
//

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('#{$assetPath}/montserrat-v13-latin-regular.woff2') format('woff2'),
  url('#{$assetPath}/montserrat-v13-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('#{$assetPath}/montserrat-v13-latin-500.woff2') format('woff2'),
  url('#{$assetPath}/montserrat-v13-latin-500.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('#{$assetPath}/montserrat-v13-latin-600.woff2') format('woff2'),
  url('#{$assetPath}/montserrat-v13-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
  url('#{$assetPath}/montserrat-v13-latin-700.woff2') format('woff2'),
  url('#{$assetPath}/montserrat-v13-latin-700.woff') format('woff');
}
