// Base
// ======================

// Vars
// ----------------------

$body-bg: #fff;
$hero-bg: $gray-300;

// Base
// ----------------------

body {
  background: $body-bg;
  color: $color-base;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  overflow-y: scroll;
  -webkit-overflow-scrolling:touch;

  &.menu--opened {
    overflow-y: hidden;
  }
}

.container {
  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
}

a {
  color: $blue;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  &--styled {
    li {
      &::before {
        @extend .icon-check;

        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        width: 11px;
        height: 8px;
      }
    }
  }

  &--check-circle {
    li {
      &::before {
        @extend .icon-check-circle;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 28px;
        height: 28px;
        margin-top: -2px;
      }
    }
  }

  li {
    color: #2f3042;
    margin: 0 0 15px;
    padding: 0 12px 0 23px;
    line-height: 20px;
    position: relative;
    .icon {
      position: absolute;
      top: 5px;
      left: 0;
    }
    &:last-child {
      margin: 0;
    }

    .card-learn-more {
      background: none;
      color: $blue;
    }
  }

  .icon-info {
    position: absolute;
    top: 2px;
    right: 0;
  }
  &--blurCircle {
    counter-reset: item;
    li {
      margin-bottom: 40px;
      padding-left: 70px;
      position: relative;
      list-style: none;
      &::before {
        counter-increment: item; /* Increment the counter for each li */
        content: counter(item) ". "; /* Display the counter with a period */
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 15px;
        transform: translateY(-50%);
        font-weight: bold;
        color: $white;
        z-index: 2;
        font-size: 30px;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        background: linear-gradient(180deg, #C5E5FD 0%, #006CBB 100%);
        filter: blur(5px);
        border-radius: 25px;
        z-index: 1;
      }
    }

  }

}

// Helper
//

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.is-hidden {
  display: none;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mrn-15 {
  margin-right: -15px;
}

.mrn-37 {
  margin-right: -37px;
}

.mln-40 {
  margin-left: -40px;
}

.mln-50 {
  margin-left: -50px;
}

.w-530 {
  max-width: 530px;
}

.pt-100 {
  padding-top: 100px;

  @include media-breakpoint-down(sm) {
    padding-top: 50px;
  }
}

.h-25 {
  height: 25px;
}

.h-44 {
  height: 40px;
}

.t30 {
  top: 30px !important;
}
.t130 {
  top: 130px !important;
}

.tn30 {
  top: -30px !important;
}
.r20 {
  right: 20px !important;
}
.z100 {
  z-index: 100 !important;
}

.h-position-relative {
  position: relative;
}

.text-blue {
  color: $light-blue;
}
.opacity-1 {
  opacity: 1 !important;
}
