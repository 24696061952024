// Navigation
// ======================

// Vars
// ----------------------

$nav-link-color: $blue-800;
$nav-link-fz: 0.875rem;
$nav-link-lh: 18/16;
$nav-link-padding: 20px 0;
$nav-link-border: $blue-100;
$nav-item-padding: 0 16px;
$footer-nav-link-color: #6A6C83;
$footer-nav-link-animation: color 0.3s;
$dropdown-title: $blue-900;
$dropdown-title-hover: $blue;
$dropdown-text: rgba($blue-900, 0.6);
$dropdown-text-hover: rgba($blue-900, 0.8);
$dropdown-sep: $gray-100;

// Base
// ----------------------

// Header main navigation
//

.header {
  .navbar {
    @include media-breakpoint-down(md) {
      position: static;
    }
  }

  .navbar-nav {
    align-items: center;

    @include media-breakpoint-down(md) {
      align-items: flex-start;
      padding: 105px 30px 0;
    }

    .nav-item {
      margin: 0;
      padding: $nav-item-padding;
      position: relative;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-top: 15px;

        @include media-breakpoint-up(lg) {
          padding-top: 0;
          padding-left: 8px;
          padding-right: 0;
        }
      }

      &.left-offset {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }

        @include media-breakpoint-up(xl) {
          padding-left: (30/16 + rem);
        }

        @include media-breakpoint-up(xl) {
          padding-left: (65/16) + rem;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0 0 32px;
        padding: 0;
      }

      &:hover {
        @include media-breakpoint-up(lg) {
          .nav-link:before {
            opacity: 1;
            visibility: visible;
            width: 100%;
          }
        }
      }

      &.has-dropdown {
        &:hover {
          @include media-breakpoint-up(lg) {
            .dropdown {
              opacity: 1;
              visibility: visible;
              transform: translate(-50%, 0);
            }
          }
        }

        a {
          @include media-breakpoint-down(lg) {
            cursor: pointer;
          }
        }
      }
    }

    .nav-link {
      @extend %font-size-sm;

      color: $nav-link-color;
      line-height: $nav-link-lh;
      padding: $nav-link-padding;
      overflow: hidden;
      position: relative;
      font-weight: 500;

      @include media-breakpoint-down(md) {
        padding: 0;
        font-size: 18px;
        font-weight: $font-weight-medium;
      }

      &:before {
        @include media-breakpoint-up(lg) {
          content: '';
          background: $nav-link-border;
          position: absolute;
          left: 50%;
          bottom: 0;
          height: 2px;
          transform: translate(-50%, -8px);
          transition: all 0.3s;
          opacity: 0;
          visibility: hidden;
          width: 10px;
        }
      }
    }

    .btn {
      @include media-breakpoint-down(md) {
        display: block;
        width: 100%;
        padding: 13px;
      }
    }
  }

  // Menu dropdown
  //
  @import "dropdown";
}

#main-navigation {
  @include media-breakpoint-down(md) {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    will-change: transform, opacity;
    transition: transform .2s linear, opacity .2s linear, visibility .2s linear;
    perspective: 1300px;
    backface-visibility: hidden;
    overflow: auto;

    .navbar-nav {
      opacity: 0;
      transform: translateY(100%);
      transition: all 0.3s;
      transform-style: preserve-3d;
    }

    &.show {
      visibility: visible;
      opacity: 1;
      width: 100%;
      height: 100vh;
      transform: translate(0, 0);
      top: 0;
      left: 0;

      .navbar-nav {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

// Footer navigation
//

.footer-navigation {
  flex-direction: column;

  .nav-link {
    @extend %font-size-sm;

    padding: 0;
    transition: $footer-nav-link-animation;
    color: $footer-nav-link-color;
    display: inline-block;
    line-height: 1.5;
    margin: .4em 0;
    &:hover {
      color: #fff;
    }
  }

  .badge {
    margin-left: 5px;
    position: relative;
  }
}

.footer-bottom {
  .nav {
    position: relative;

    @include media-breakpoint-down(sm) {
      margin: 0 0 15px;
      padding-left: 32px;
    }

    &-link {
      @extend %font-size-xxs;

      color: $footer-nav-link-color;
      font-weight: $font-weight-medium;
      transition: $footer-nav-link-animation;
      text-transform: uppercase;
      padding: 0 1em;
      line-height: 1.5;
      margin: .8em 0;
      &:hover {
        color: #fff;
      }

      @include media-breakpoint-down(sm) {
        padding-top: 0;
      }
    }

    li {
      &:first-child {
        a {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0;
        }

        @include media-breakpoint-down(sm) {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
