// About
// ======================

// Vars
// ----------------------

$about-paragraphs-color: #525D66;
$about-border: #F2F5F7;

// Base
// ----------------------

.about {
  .page-header {
    @include media-breakpoint-down(sm) {
      margin-bottom: 40px;
    }
  }
}

.our-mission {
  position: relative;

  &--text {
    padding: 32px 22px;
    text-align: left;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    background-color: $white;

    @include media-breakpoint-up(lg) {
      padding: 56px;
    }

    .top-paragraph {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: $blue;
      margin-bottom: 8px;
    }

    h2 {
      text-transform: capitalize;
      color: $blue-900;
      margin: 0 0 32px;
    }

    p {
      color: $about-paragraphs-color;
      margin-bottom: 23px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &--graphic {
    position: absolute;
    top: -60px;
    right: 0;
    z-index: -1;
  }
}

.our-values {
  &.content-section {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: $vspacing;
    }
  }

  &--row {
    position: relative;

    > div {
      @include media-breakpoint-up(md) {
        border-top: 2px solid $about-border;
        border-right: 2px solid $about-border;
        border-bottom: 0;

        &:nth-child(1),
        &:nth-child(2) {
          border-top: 0;
        }

        &:nth-child(2n + 2) {
          border-right: 0;
        }
      }

      &:last-of-type {
        .values-block {
          @include media-breakpoint-down(sm) {
            margin: 0;
          }
        }
      }
    }
  }

  .values-block {
    text-align: center;
    color: $blue-900;
    padding: 0;
    margin: 0 0 32px;

    @include media-breakpoint-up(md) {
      padding: 30px 25px;
    }

    @include media-breakpoint-up(lg) {
      padding: 57px 0 48px;
      margin: 0 auto;
      max-width: 380px;
      width: 100%;
    }

    strong {
      @include px-rem('margin-bottom', 20);
      @include px-rem('font-size', 20);

      display: block;
      font-weight: $font-weight-semibold;
    }

    p {
      margin: 0;
      color: $light-gray-600;
    }

    br {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__logo {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

.our-team {
  position: relative;
  overflow-x: hidden;
  padding-top: 80px;

  @include media-breakpoint-up(md) {
    overflow-x: inherit;
    padding-top: 0;
  }

  &--text {
    text-align: left;
    color: $blue-900;
    width: 100%;

    h2 {
      text-transform: capitalize;
      padding-bottom: 14px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 24px;

      color: $about-paragraphs-color;
    }
  }

  &--card {
    position: relative;
    z-index: 1;
    height: 374px;
    margin-bottom: 30px;

    &-front {
      padding: 48px 0 0;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      background: $white;
      text-align: center;
      color: $blue-900;

      strong {
        display: block;
        font-size: 20px;
        line-height: 26px;
        margin: 0 0 8px;
      }

      img {
        position: relative;
        display: block;
        margin: 0 auto 24px;
      }

      p {
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        margin: 0 0 32px;
        color: $light-gray-400;
      }
    }

    &-back {
      right: 0;
      bottom: 0;
      color: #fff;
      border-radius: 4px;

      &:before {
        background: $blue;
        content: "";
        position: absolute;
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;
        z-index: 0;
        border-radius: 4px;
      }
    }

    &-inner {
      position: relative;
      z-index: 10;
      font-size: 14px;
      line-height: 20px;
      overflow: auto;
      height: 100%;
      margin: 0 -10px;

      p {
        margin: 0 0 20px;
      }
    }

    &-text {
      padding: 20px 30px;
    }

    header {
      margin: 0 0 24px;

      strong {
        @extend %font-secondary;

        display: block;
        font-size: 20px;
        font-weight: $font-weight-semibold;
        line-height: 26px;
        text-transform: capitalize;
      }

      p {
        color: rgba(255, 255, 255, 0.4);
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
      }
    }
  }

  &--graphic {
    position: absolute;
    top: 0;
    left: -420px;

    @include media-breakpoint-up(md) {
      top: -80px;
      left: -160px;
    }
  }

  .simplebar-scrollbar:before {
    background: #fff;
    border-radius: 0;
  }
}

.about-bottom {
  text-align: left;
  color: $blue-900;

  .container {
    border-top: 1px solid #E5E5E5;
    padding-top: 60px;
  }

  .icon-svg--top {
    width: 48px;
    height: 48px;
    position: relative;
    margin-bottom: 20px;
  }

  p {
    @include px-rem('margin-bottom', 12);
  }

  strong {
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;

    margin-bottom: 20px;
  }

  .row {
    > div {
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}

// Featured in
//

.featured-in {
  background: #F7F9FA;
  padding: 70px 0 0;
  position: relative;

  @include media-breakpoint-up(sm) {
    padding: 58px 0 24px;
  }

  .section-header {
    @include media-breakpoint-up(sm) {
      display: none;
    }

    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
  }

  h5 {
    display: inline-block;
    color: $light-gray-400;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
  }

  &--logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    figure {
      position: relative;

      @include media-breakpoint-down(sm) {
        max-width: 50%;
        width: 100%;
        margin: 0 0 30px;
        text-align: center;
      }

      h5 {
        position: absolute;
        top: -33px;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    a {
      &:hover {
        img {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }

    img {
      transition: all 0.4s;
      filter: grayscale(1);
      opacity: 0.7;
    }
  }
}
