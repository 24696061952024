// About
// ======================

// Vars
// ----------------------

// Base
// ----------------------
.page-404 {
  .main {
    text-align: center;
    padding: 100px 0 0;

    @include media-breakpoint-up(lg) {
      padding: 156px 0 0;
    }

    picture {
      display: block;
      padding-bottom: 40px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 55px;
      }
    }
  }

  h2 {
    text-transform: capitalize;
    padding-bottom: 8px;
  }

  p {
    padding-bottom: 40px;
  }

  .button-404 {
    margin-bottom: 70px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 140px;
    }

    .btn-lg {
      width: 100%;
      max-width: 370px;
      max-height: 48px;
    }
  }
}
