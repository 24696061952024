// Contact
// ======================

// Vars
// ----------------------

$contact-bg: #f5f5f5;
$contact-color: #666666;
$contact-title: #4c4c4c;
$placeholder-color: #949A9E;
$border-color: #DADDE2;
$terms-color: #646F79;

// Base
// ----------------------

.contact {
  .hero-secondary {
    .container {
      text-align: center;

      h2 {
        margin-bottom: 24px;
      }

      p {
        margin-bottom: 84px;
      }

      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
  }

  .main {
    padding: 0;
    position: relative;
    z-index: 100;
  }

  .form-section {
    .card {
      max-width: 770px;
    }
  }
}

.map-section {
  margin-bottom: $vspacing--mob;

  @include media-breakpoint-up(lg) {
    margin-bottom: $vspacing;
  }

  .card {
    background: #F5F9FC;
    height: 100%;
    border-radius: 4px;
    box-shadow: none;

    &-body {
      @include media-breakpoint-up(lg) {
        padding: 56px;
      }
    }

    h3,
    p {
      margin-bottom: 32px;
    }
  }

  .social-links {
    li {
      margin: 0 12px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: $blue;

      &:hover {
        color: darken($blue, 20%);
      }
    }
  }

  .contact-map {
    position: relative;
    height: 240px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: 357px;
    }

    .google-map {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 420px !important;
    }
  }
}

