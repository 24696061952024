// Competitors
// ======================

// Vars
// ----------------------

$contact-bg: #f5f5f5;
$contact-color: #666666;
$contact-title: #4c4c4c;
$placeholder-color: #949A9E;
$border-color: #DADDE2;
$terms-color: #646F79;

// Base
// ----------------------

.competitors {
  .hero-secondary {
    .container {
      text-align: center;
      margin-bottom: 0;
      padding-bottom: 50px;

      @include media-breakpoint-up(lg) {
        padding-bottom: 100px;
      }

      h2 {
        margin-bottom: 24px;
      }

      p {
        margin: 0;
        padding: 0;
      }

      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
  }

  .hero-intersect {
    @include media-breakpoint-down(sm) {
      top: auto !important;
    }

    svg {
      * {
        fill: #fff;
      }

      .no-fill {
        stroke: #fff;
        fill: none;
      }
    }
  }

  .main {
    padding: 0;
    position: relative;
    z-index: 100;
  }

  .get-started {
    margin-bottom: 0;
  }

  .hero-secondary,
  .app-features-row {
    margin-bottom: $vspacing--mob;

    @include media-breakpoint-up(lg) {
      margin-bottom: $vspacing;
    }
  }

  .app-features {
    &-row {
      h2 {
        margin: 0 0 32px;
      }

      p {
        color: $light-gray-700;
        margin: 0 0 23px;
      }
    }

    &-image {
      .media {
        @include media-breakpoint-down(md) {
          padding-top: 32px;
          justify-content: center;
        }
      }
    }
  }
}

