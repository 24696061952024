// Slider
// ======================

// Vars
// ----------------------

$slider-bg: #e8e9eb;
$slider-bg--active: #006cbb;
$slider-handle-bg: #383a4f;
$slider-bg-white: #fff;

// Base
// ----------------------

.slider-component-item {
  &.noUi-horizontal {
    background: $slider-bg;
    height: 6px;
    border: 0;
    border-radius: 2px;

    .noUi-handle {
      background: $slider-bg-white;
      border-radius: 20px;
      border-width: 4px;
      border-color: $slider-bg--active;
      width: 34px;
      height: 34px;
      top: -16px;
      outline: none;

      @include media-breakpoint-up(sm) {
        width: 28px;
        height: 28px;
        top: -13px;
      }

      &:after,
      &:before {
        background: #fff;
        height: 6px;
        top: 12px;
      }

      &:before {
        left: 8px;
      }

      &:after {
        left: 11px;
      }
    }
  }

  .noUi-connect {
    background: $slider-bg--active;
  }

  .noUi-tooltip {
    @extend %font-size-sm;

    bottom: 38px;
    background: #2F3941;
    border-radius: 20px;
    padding: 3px 10px;
    line-height: 18px;
    font-weight: 700;
    border: 0;

    @include media-breakpoint-up(sm) {
      bottom: 33px;
    }

    @include media-breakpoint-up(lg) {
      padding: 3px 20px;
    }

    color: $white;
    text-align: center;

    &,
    &:after {
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      border: 10px solid transparent;
      border-top-color: #333;
      border-bottom-width: 20px;
      position: absolute;
      bottom: -24px;
    }
  }
}
