
.lp-ads {
  .section-badged {
    margin-bottom: 0 !important;
    .section-inner {
      background: #171A2D;
      padding-top: 50px;
      padding-bottom: 40px;
      position: relative;
      border-bottom-right-radius: 0;
      border-top-right-radius: 80px;
    }
  }
  .more-challenge--row  {
    padding-top: 30px;
    padding-bottom: 30px;
    .more-box {
      transition: all 0.4s;
      height: 100%;
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 24px 12px;
      a {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 24px 12px;
        &:hover {
          text-decoration: none;
        }
      }
      //&:hover {
      //  transform: translateY(-5px);
      //  .icon-svg {
      //    circle {
      //      @extend .icon-svg--circle-fill-hover;
      //    }
      //
      //    path,
      //    rect {
      //      @extend .icon-svg--item-fill-hover;
      //    }
      //  }
      //}
    }
    .icon-svg {
      width: 48px;
      height: 48px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
    .more-text {
      flex: 1;
      padding-left: 16px;

      p {
        color: $blue-900;
        overflow: hidden;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
      }
      .badge {
        position: relative;
        top: -2px;
        margin-left: 8px;
      }
    }
  }
  .main {
    padding: 0;
  }
  .hero {
    padding: 100px 0 60px;
    margin: 0 0 60px;
    overflow: hidden;
    position: relative;
    @include media-breakpoint-up(md) {
      padding: 180px 0 100px;
      margin: 0;
    }
    .hero-circle-left {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 60%;
    }
  }

  .hero-stars {
    @include media-breakpoint-up(sm) {
      position: relative;
      bottom: unset;
    }
  }
  .ads-form-section {
    @include media-breakpoint-up(md) {
      margin-top: -80px;
    }
  }
  .ads-form {
    z-index: 9;
    .card {
      color:$white;
      background: linear-gradient(162.26deg, #636371 -13.49%, #171A2D 53.27%);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 20px;
      min-height: 550px;
      .text-link {
        text-decoration: underline;
      }

    }
  }


  &--cursor {
    bottom: 0;
    left: 0;
    margin-bottom: 13%;
    margin-left: 10%;
    z-index: 1;
    transform: translate(-50px, 50px);
    @include media-breakpoint-down(md) {
      max-width: 8vw;
      margin-bottom: 15%;
    }
  }
  &--dashboard {

  }

  &--margaret {
    bottom: 0;
    left: 0;
    margin-bottom: 1%;
    margin-left: -2%;
    z-index: 1;
    transform: translate(-50px, 0);
    @include media-breakpoint-down(md) {
      max-width: 46vw;
      margin-bottom: 0%;
      margin-left: 0;
    }
  }
  &--anne {
    bottom: 0;
    left: 0;
    margin-bottom: -9%;
    margin-left:  -12%;
    z-index: 1;
    transform: translate(-50px, 0);
    @include media-breakpoint-down(md) {
      max-width: 46vw;
      margin-bottom: -10%;
      margin-left: -3%;
    }
  }
  &--sync {
    bottom: 0;
    right: 0;
    margin-bottom: -8%;
    margin-right:  -2%;
    z-index: 1;
    transform: translate(50px, 0);
    @include media-breakpoint-down(md) {
      max-width: 40vw;
    }
  }

  .graphic-item {
    visibility: hidden;
    opacity: 0;
  }

  .lp-hero-ads1 {
    top: 0;
    left: 0;
    margin-top: 15%;
    margin-left: 40%;
    display: block;
    transform: translate(50px, 0);


    @include media-breakpoint-down(md) {
      margin-left: 0%;
    }


  }
  .lp-hero-ads2 {
    top: 0;
    left: 0;
    margin-top: 5%;
    margin-left: 25%;
    display: block;
    transform: translate(50px, 0);
    @include media-breakpoint-down(md) {
      margin-left: 0%;
    }

  }
  .lp-hero-ads3 {
    top: 0;
    left: 0;
    margin-top: 5%;
    margin-left: 10%;
    display: block;
    transform: translate(50px, 0);
    @include media-breakpoint-down(md) {
      margin-left: 0%;
    }

  }
  .lp-hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -5%;
    margin-left: 20%;
    z-index: -1;
  }

  .content-animation {
    &.has-animation {

      .graphic-item {
        animation: animRunSlide .4s linear forwards;
        animation-delay: .3s;
      }

      .lp-ads {

        &--cursor {
          animation-delay: 2s;
        }

        &--dashboard {
          animation-delay: .5s;
        }

        &--anne {
          animation-delay: 1s;

        }

        &--margaret {
          animation-delay: 1.5s;

        }

        &--sync {
          animation-delay: 2s;

        }
      }
      .lp-hero-ads1 {
        animation-delay: 1s;
      }
      .lp-hero-ads2 {
        animation-delay: 1.5s;
      }
      .lp-hero-ads3 {
        animation-delay: 2s;

      }
    }

  }

}
