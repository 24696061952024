
.product-concierge {
  overflow-x: hidden;
  .main {
    overflow-x: hidden;
  }
  .page-faq {
    background: none;
    .accordion {
      .card-header {
        background-color: transparent;
      }
    }
  }
  //hero
  .hero {
    &--testimonial {
      @include media-breakpoint-up(xl) {
        margin-top: -7%;
      }
    }
  }
  .hero-cover {
    &--author {
      &.left {
        @include media-breakpoint-up(xl) {
          right: 47.5%;
        }
      }
    }
  }
  .graphic-benefit,
  .get-started-new--picture,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }
  .product-top-info .hero-message h1 {
    margin-bottom: 3rem;
  }
  .section-inner-bg {
    @include media-breakpoint-down(sm) {
      background-size: cover !important;
    }
  }
  .get-started-new {
    &--philip {
      padding-top: 73px;
      .section-inner {
        padding-top: 55px;
        padding-bottom: 55px;
      }
      .get-started-name-wrapper {
        z-index: 9;

        @include media-breakpoint-up(md) {
          bottom: -30px;
          right: 0;
        }
      }
      .get-started-name {
        background: rgba(#252530, .4);
        color: #28293C;
        font-weight: bold;
        background: linear-gradient(110.64deg, rgba(255, 255, 255, 0.4) 59.69%, rgba(255, 255, 255, 0.06) 66.23%);
        box-shadow: 0px 4px 4px rgba(47, 57, 65, 0.24);
        backdrop-filter: blur(15px);
        border-radius: 3px;

      }
      .get-started-new--picture {
        @include media-breakpoint-up(md) {
          right: -8%;
          position:absolute;
          top: inherit;
          bottom: -55px;
        }
        @include media-breakpoint-up(lg) {
          right: -8%;
        }
        img {
          display: block;
        }
      }
    }
  }
  .content-section .section-subtitle {
    letter-spacing: 0.06em;
  }
  .bg-blue {
    background-color: #E1F3FF;
  }
  .section-testimonial {
    .testimonial-yt {
      display: block;
      position: relative;
      picture,
      img {
        user-select: none;
        display: flex;
      }
      &--blur {
        @include media-breakpoint-down(md) {
          display: none;
        }
        backdrop-filter: blur(15px);
        border-radius: 24px;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 5%;
        margin-bottom: 0;
        margin-left: 30px;
        max-width: 33%;
        background: linear-gradient(121.8deg, rgba(255, 255, 255, 0.6) 2.87%, rgba(255, 255, 255, 0.1) 95.86%);
        box-shadow: 0px 4px 4px rgba(47, 57, 65, 0.24);
      }
      &:hover {
        .testimonial-yt--play-hover {
          animation: animRunShow .3s linear forwards;
        }
      }
      &--play {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 1;
        @include media-breakpoint-down(md) {
          transform: translate(-50%, -50%) scale(0.5);
          transform-origin: center center;
        }

      }
      &--play-hover {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 2;
        @include media-breakpoint-down(md) {
          transform: translate(-50%, -50%) scale(0.5);
          transform-origin: center center;
        }
      }
      &--quote {
        padding: 15% 10%;
        p {
          color: $white;
          font-family: $font-roboto;
          opacity: 0.7;
          strong {
            font-weight: 600;
          }
          &:last-child {
            margin-top: 10%;
          }
        }
      }
      &--label {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 1%;
        margin-bottom: 1%;
        @include media-breakpoint-down(md) {
          transform: scale(0.5);
          transform-origin: right bottom;
        }
      }
    }
    .youtube-testimonial {
      display: flex;
      justify-content: center;
      border-radius: 30px;
      overflow: hidden;

      iframe {
        aspect-ratio: 16 / 9;
        width: 100% !important;
        border-radius: 30px;
      }
    }
  }
  .section-animation,
  .content-animation,
  .scale-tailor-animation,
  .realtime-score--section-animation,
  .scaling--section-animation {
    .graphic-item,
    .graphic-item_new,
    .graphic-item_new2 {
      visibility: hidden;
      opacity: 0;
      position: relative;
    }

    &.has-animation {
      .graphic-item {
        animation: animRunSlide .5s linear forwards;
      }
    }
  }
  .workflow--section-animation,
  .smarttask--section-animation {
    .graphic-item {
      visibility: hidden;
      opacity: 0;
    }
    &.has-animation {
      .graphic-item {
        animation: animRunShow .5s linear forwards;
      }
    }
  }
  .content-animation {
    &.has-animation {
      .graphic-item,
      .graphic-item_new {
        animation: animRunSlide .6s linear forwards;
        animation-delay: .3s;
      }
    }
    img {
      user-select: none;
    }
    //section5
    border: 2px solid transparent;
  }
  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper,
    .graphic-benefit {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }
    .graphic-benefit__left {
      transform: translate(0, -80px);
    }
    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }
    .get-started-new--picture {
      transform: translate(150px, 0);
    }
    .graphic__get-started--right {
      transform: translate(50px, 0);
    }
    .get-started-body {
      transform: translate(-50px, 0);
    }
  }
  .content-section {
    p {
      &.section-subtitle {
        font-family: $font-montserrat;
        color: $blue-900;
        margin-bottom: .5em;
      }
    }
  }
  .hero {
    &.content-section {
      border: none;
    }
  }
  //nav loading
  .nav {
    &-loading {
      border: 0;
      .nav-item {
        margin-bottom: 1.5em;
        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }
      .nav-link {
        position: relative;
        border: 0;
        padding: 0 1em 0 2.5em;
        @include media-breakpoint-down(sm) {
          padding: 0 .5em 0 1.75em;
        }
        font-family: $font-roboto;
        color: #5D6871;
        &.active {
          background-color: transparent;
          h4 {
            @include media-breakpoint-down(sm) {
              font-size: 1.3rem;
            }
          }

          .loading-vertical {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            border-radius: 6px;
            z-index: 9;
            background: #5D6871;
            height: auto;
            transition: height .3s linear;
            max-height: 100%;
          }
          &.stop-autoplay {
            .loading-vertical {
              transition: height 0s;
              height: 100% !important;
            }
          }
          &:after {
            background: #EFEFF1;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            border-radius: 6px;
            height: 100%;
          }
          //&:before {
          //  content: '';
          //  position: absolute;
          //  top: 0;
          //  left: 0;
          //  width: 6px;
          //  border-radius: 6px;
          //  z-index: 9;
          //  background: #5D6871;
          //  height: 100%;
          //  transition: height 6s linear;
          //
          //}
          p {
            display: block;
            margin-bottom: 0;
          }
        }

        &:not(.active) {
          h3 {
            color: #A4B5C1;
            font-size: 1rem;
          }
          //&:before {
          //  content: '';
          //  position: absolute;
          //  top: 0;
          //  left: 0;
          //  width: 6px;
          //  border-radius: 6px;
          //  height: 0;
          //  z-index: 9;
          //}

          p {
            display: none;
          }
        }
        p {
          line-height: 1.5em;
          min-height: calc(1.6em * 3);
          @include media-breakpoint-down(sm) {
            line-height: 1.3em;
            min-height: calc(1.3em * 4);
          }
        }
      }
    }
  }
  .intro {
    &--icon {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -60px;
      margin-left: 15%;
      background-color: $white;
      border-radius: 50%;
      width: 76px;
      height: 76px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      z-index: 9;
      svg {
        path {
          fill:none;
          stroke:$custify-grey;
        }
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &--author {
      transition: all 0.4s;
      filter: grayscale(1);
    }
    &--item {
      position: relative;
      margin-top: 60px;
      border: 2px solid #C2C5CC;
      border-radius: 50px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
      font-family: $font-montserrat;

      &.highlight,
      &:hover {
        border: 2px solid $custify-grey;
        .intro--icon {
          svg {
            path {
              fill:none;
              stroke: $white;
            }
          }
          background: linear-gradient(180deg, #262631 0%, #6A6A89 100%);
        }
        .intro--author {
          filter: grayscale(0);
        }
      }
      h4 {
        font-size: 1.5rem;
      }
    }
    &--landing {
      border-radius: 30px;
      width: 100%;
      height: 16px;
      background-color: #D9D9D9;
      box-shadow: inset 4px 4px 16px rgba(23, 26, 45, 0.24);
      &-bar {
        border-radius: 30px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(90deg, #252530 0%, #747496 100%);
      }
    }

    &--content {
      p {
        @include media-breakpoint-up(md) {
          line-height: 1.3em;
          min-height: calc(1.3em * 10);
        }
      }
    }
    &--benefit {
      p {
        @include media-breakpoint-up(md) {
          line-height: 1.3em;
          min-height: calc(1.3em * 4);
        }
      }
    }

  }

  .included--bg,
  .benefits--bg {
    top: 0;
    left: 0;
    margin-top: 25%;
    z-index: -9;
  }
  .included--section,
  .benefits--section {
    .tab-content {
      position: relative;
      min-height: 600px;
      display: block;
      @include media-breakpoint-down(md) {
        min-height: auto;
      }
    }
  }
  .benefits--section {
    @include media-breakpoint-up(md) {
      margin-bottom: 100px !important;
    }
  }
  .get-started {
    &--marina {
      .get-started-new--picture {
        @include media-breakpoint-down(md) {
          margin-top: -60%;
        }
      }
    }
  }

  //included-tab1
  .included-tab1 {
    &--avatar1 {
      left: 0;
      top: 0;
      margin-top: 15%;
      margin-left: 23%;
      z-index: 1;
      transform: translate(0%, -80%);
    }
    &--avatar1-label {
      left: 0;
      top: 0;
      margin-top: 35%;
      margin-left: 23%;
      z-index: 2;
      transform: translate(0%, -80%);
    }
    &--avatar2 {
      left: 0;
      top: 0;
      margin-top: 20%;
      margin-left: 50%;
      transform: translate(0%, -80%);
    }
    &--avatar2-label {
      left: 0;
      top: 0;
      margin-top: 41%;
      margin-left: 48%;
      z-index: 9;
      transform: translate(0%, -80%);
    }
    &--avatar3 {
      left: 0;
      top: 0;
      margin-top: 38%;
      margin-left: 5%;
      z-index: -1;
      transform: translate(80%, 0%);
    }
    &--avatar3-label {
      left: 0;
      top: 0;
      margin-top: 62%;
      margin-left: 5%;
      z-index: 9;
      transform: translate(80%, 0%);
    }
    &--avatar4 {
      left: 0;
      top: 0;
      margin-top: 40%;
      margin-left: 40%;
      z-index: 2;
      transform: translate(0%, 0%);
    }
    &--avatar4-label {
      left: 0;
      top: 0;
      margin-top: 60%;
      margin-left: 40%;
      z-index: 9;
      transform: translate(0%, 80%);
    }
    &--avatar5 {
      left: 0;
      top: 0;
      margin-top: 55%;
      margin-left: 20%;
      z-index: 2;
      transform: translate(0%, 80%);
    }
    &--avatar5-label {
      left: 0;
      top: 0;
      margin-top: 80%;
      margin-left: 20%;
      z-index: 9;
      transform: translate(0%, 80%);
    }
    &--avatar6 {
      left: 0;
      top: 0;
      margin-top: 58%;
      margin-left: 60%;
      transform: translate(0%, 80%);
    }
    &--avatar6-label{
      left: 0;
      top: 0;
      margin-top: 78%;
      margin-left: 60%;
      transform: translate(0%, 80%);
    }
  }

  //included-tab2
  .included-tab2 {
    &--avatar {
      top: 0;
      right: 0;
      margin-top: 40%;
      margin-right: -75%;
      transform: translate(80%, 0%);

    }
    &--custify {
      top: 0;
      right: 0;
      margin-top: 40%;
      margin-right: 30%;
      transform: translate(80%, 0%);

    }
    &--label {
      top: 0;
      right: 0;
      margin-top: 63%;
      margin-right: -8%;
      transform: translate(80%, 0%);

    }
    &--logo1 {
      top: 0;
      left: 0;
      margin-top: 55%;
      margin-left: -5%;
      transform: translate(-80%, 0%);

    }
    &--logo2 {
      top: 0;
      left: 0;
      margin-top: 35%;
      margin-left: -5%;
      transform: translate(-80%, 0%);
    }
    &--logo3 {
      top: 0;
      left: 0;
      margin-top: 25%;
      margin-left: 8%;
      transform: translate(-80%, 0%);
    }
    &--logo4 {
      top: 0;
      left: 0;
      margin-top: 45%;
      margin-left: 8%;
      transform: translate(-80%, 0%);
    }
    &--logo5 {
      top: 0;
      left: 0;
      margin-top: 65%;
      margin-left: 8%;
      transform: translate(-80%, 0%);

    }
    &--logo6 {
      top: 0;
      left: 0;
      margin-top: 75%;
      margin-left: 20%;
      transform: translate(-80%, 0%);

    }
    &--logo7 {
      top: 0;
      left: 0;
      margin-top: 56%;
      margin-left: 20%;
      transform: translate(-80%, 0%);

    }
    &--logo8 {
      top: 0;
      left: 0;
      margin-top: 34%;
      margin-left: 20%;
      transform: translate(-80%, 0%);

    }
    &--logo9 {
      top: 0;
      left: 0;
      margin-top: 13%;
      margin-left: 20%;
      transform: translate(-80%, 0%);

    }
    &--arrow1 {
      top: 0;
      left: 0;
      margin-top: 54%;
      margin-left: 35%;
      transform: translate(-80%, 0%);

    }
    &--arrow2 {
      top: 0;
      left: 0;
      margin-top: 54%;
      margin-left: 68%;
      transform: translate(-80%, 0%);

    }
  }
  //included-tab3
  .included-tab3 {
    &--avatar {
      top: 0;
      right: 0;
      margin-top: 80%;
      margin-right: 10%;
      z-index: 2;
      transform: translate(0%, 80%);

    }

    &--avatar-label {
      top: 0;
      right: 0;
      margin-top: 100%;
      margin-right: -10%;
      z-index: 2;
      transform: translate(0%, 80%);

    }

    &--cursor {
      top: 0;
      left: 0;
      margin-top: 75%;
      margin-left: 60%;
      z-index: 4;
      transform: translate(0%, 80%);

    }

    &--label1 {
      top: 0;
      left: 0;
      margin-top: 160px;
      margin-left: -50px;
      z-index: 2;
      transform: translate(0%, -80%);

    }

    &--label2 {
      top: 0;
      left: 0;
      margin-top: -40px;
      margin-left: -50px;
      z-index: 2;
      transform: translate(0%, -80%);

    }

    &--label3 {
      top: 0;
      left: 0;
      margin-top: -40px;
      margin-left: -50px;
      z-index: 2;
      transform: translate(0%, -80%);

    }

    &--label4 {
      top: 0;
      left: 0;
      margin-top: -40px;
      margin-left: -50px;
      z-index: 2;
      transform: translate(0%, -80%);

    }

    &--label5 {
      top: 0;
      left: 0;
      margin-top: -40px;
      margin-left: -50px;
      z-index: 2;
      transform: translate(0%, -80%);
    }

    &--rect {
      top: 0;
      left: 0;
      margin-top: 10%;
      margin-left: 20%;
      z-index: 1;
      transform: translate(0%, 0%);

    }
  }
  //included-tab4
  .included-tab4 {
    &--avatar {
      top: 0;
      left: 0;
      margin-top: 50%;
      margin-left: -30%;
      transform: translate(0%, 80%);

    }
    &--avatar-label {
      top: 0;
      left: 0;
      margin-top: 68%;
      margin-left: 8%;
      transform: translate(0%, -80%);

    }
    &--gmail {
      top: 0;
      left: 0;
      margin-top: 35%;
      margin-left: 0;
      transform: translate(-80%, 0%);

    }
    &--phone {
      top: 0;
      left: 0;
      margin-top: 60%;
      margin-left: 18%;
      transform: translate(-80%, 0%);

    }
    &--slack {
      top: 0;
      left: 0;
      margin-top: 35%;
      margin-left: 60%;
      transform: translate(80%, 0%);

    }
    &--zoom {
      top: 0;
      left: 0;
      margin-top: 50%;
      margin-left: 45%;
      transform: translate(80%, 0%);

    }
    &--line-blue {
      top: 0;
      left: 0;
      margin-top: 40%;
      margin-left: 40%;
      z-index: -1;
      transform: translate(0%, 0%);

    }

    &--line-green {
      top: 0;
      left: 0;
      margin-top: 50%;
      margin-left: 10%;
      z-index: -1;
      transform: translate(0%, 0%);

    }

  }
  //included-tab5
  .included-tab5 {
    &--book1 {
      top: 0;
      left: 0;
      margin-top: 44%;
      margin-left: 5%;
      z-index: 4;
      transform: translate(0%, 80%);
    }
    &--book2 {
      top: 0;
      left: 0;
      margin-top: 46%;
      margin-left: -3%;
      z-index: 1;
      transform: translate(0%, 80%);
    }
    &--book3 {
      top: 0;
      left: 0;
      margin-top: 41%;
      margin-left: 33%;
      z-index: 3;
      transform: translate(0%, 80%);
    }
    &--book4 {
      top: 0;
      left: 0;
      margin-top: 46%;
      margin-left: 55%;
      z-index: 1;
      transform: translate(0%, 80%);
    }
    &--faq {
      top: 0;
      left: 0;
      margin-top: 12%;
      margin-left: 33%;
      z-index: 5;
      transform: translate(0%, -80%);
    }
  }

  //benefits-tab1
  .benefits-tab1 {
    &--avatar1 {
      top: 0;
      left: 0;
      margin-left: 30%;
      margin-top: 20%;
      transform: translate(-80%, 0%);

    }
    &--avatar2 {
      top: 0;
      left: 0;
      margin-top: 17%;
      margin-left: 46%;
      transform: translate(80%, 0%);

    }
    &--rect {
      top: 0;
      left: 0;
      margin-top: 44%;
      margin-left: 15%;
      transform: translate(-80%, 0%);

    }
    &--userpilot {
      top: 0;
      left: 0;
      margin-top: 60%;
      margin-left: 60%;
      transform: translate(80%, 0%);
      z-index: 9;

    }
    &--whale {
      top: 0;
      left: 0;
      margin-top: 40%;
      margin-left: 18%;
      transform: translate(-80%, 0%);
    }
  }
  //benefits-tab2
  .benefits-tab2 {
    &--rect {
      top: 0;
      left: 0;
      margin-top: 32%;
      margin-left: -35%;
      z-index: 9;
      transform: translate(-80%, 0%);
    }
    &--label1 {
      top: 0;
      right: 0;
      margin-top: 19%;
      margin-right: 8%;
      transform: translate(-80%, 0%);

    }
    &--label2 {
      top: 0;
      right: 0;
      margin-top: 37%;
      margin-right: 4%;
      transform: translate(-80%, 0%);
    }
    &--label3 {
      top: 0;
      right: 0;
      margin-top: 56%;
      margin-right: 2%;
      transform: translate(-80%, 0%);
    }
    &--label4 {
      top: 0;
      right: 0;
      margin-top: 76%;
      margin-right: -2%;
      transform: translate(-80%, 0%);
    }
    &--time {
      top: 0;
      left: 0;
      margin-top: 36%;
      margin-left: -5%;
      transform: translate(-80%, 0%);
    }
    &--line1 {
      top: 0;
      left: 0;
      margin-top: 23%;
      margin-left: 46%;
      z-index: 9;
      transform: translate(-80%, 0%);

    }
    &--line2 {
      top: 0;
      left: 0;
      margin-top: 42%;
      margin-left: 45%;
      z-index: 9;
      transform: translate(-80%, 0%);
    }
    &--line3 {
      top: 0;
      left: 0;
      margin-top: 60%;
      margin-left: 45%;
      transform: translate(-80%, 0%);
      z-index: 9;
    }
    &--line4 {
      top: 0;
      left: 0;
      margin-top: 80%;
      margin-left: 45%;
      z-index: 9;
      transform: translate(-80%, 0%);

    }
  }
  //benefits-tab3
  .benefits-tab3 {
    &--rect {
      top: 0;
      right: 0;
      margin-top: 20%;
      margin-left: 0%;
      transform: translate(80%, 0%);
    }
    &--kpi {
      top: 0;
      left: 0;
      margin-top: 35%;
      margin-left: -10%;
      z-index: -1;
      transform: translate(-80%, 0%);
    }
  }
  //animations
  .content-animation {
    &.has-animation {
      //benefits-tab1
      .benefits-tab1 {
        &--avatar1 {
          animation: animRunSlide .4s .4s forwards;

        }
        &--avatar2 {
          animation: animRunSlide .4s .8s forwards;

        }
        &--rect {
          animation: animRunSlide .4s 1.4s forwards;

        }
        &--userpilot {
          animation: animRunSlide .4s 1s forwards;

        }
        &--whale {
          animation: animRunSlide .4s .6s forwards;
        }
      }
      //benefits-tab2
      .benefits-tab2 {
        &--time {
          animation: animRunSlide .4s .4s forwards;
        }
        &--rect {
          animation: animRunSlide .4s .8s forwards;
        }
        &--label1 {
          animation: animRunSlide .4s 1s forwards;
        }
        &--label2 {
          animation: animRunSlide .4s 1.2s forwards;
        }
        &--label3 {
          animation: animRunSlide .4s 1.4s forwards;
        }
        &--label4 {
          animation: animRunSlide .4s 1.6s forwards;
        }
        &--line1 {
          animation: animRunSlide .4s 1.4s forwards;
        }
        &--line2 {
          animation: animRunSlide .4s 1.6s forwards;
        }
        &--line3 {
          animation: animRunSlide .4s 1.8s forwards;
        }
        &--line4 {
          animation: animRunSlide .4s 2s forwards;
        }
      }
      //benefits-tab3
      .benefits-tab3 {
        &--rect {
          animation: animRunSlide .4s .5s forwards;

        }
        &--kpi {
          animation: animRunSlide .4s 1s forwards;
        }
      }

      //included-tab1
      .included-tab1 {
        &--avatar1 {
          animation: animRunSlide .4s .8s forwards;
        }
        &--avatar1-label {
          animation:
            animRunSlide .4s .8s forwards,
            animRunSlideReverse .4s 2s forwards;
        }
        &--avatar2 {
          animation: animRunSlide .4s 1s forwards;
        }
        &--avatar2-label {
          animation:
            animRunSlide .4s 2s forwards,
            animRunSlideReverse .4s 3s forwards;
        }
        &--avatar3 {
          animation: animRunSlide .4s 1.2s forwards;
        }
        &--avatar3-label {
          animation:
            animRunSlide .4s 3s forwards;
        }
        &--avatar4 {
          animation: animRunSlide .4s .4s forwards;
        }
        &--avatar4-label {
          animation:
            animRunSlide .4s 3s forwards;

        }
        &--avatar5 {
          animation: animRunSlide .4s .8s forwards;
        }
        &--avatar5-label {
          animation:
            animRunSlide .4s 2s forwards,
            animRunSlideReverse .4s 3s forwards;

        }
        &--avatar6 {
          animation: animRunSlide .4s 1s forwards;
        }
        &--avatar6-label{
          animation:
            animRunSlide .4s 1s forwards,
            animRunSlideReverse .4s 2s forwards;

        }
      }
      //included-tab2
      .included-tab2 {
        &--avatar {
          animation: animRunSlide .4s 2.4s forwards;

        }
        &--custify {
          animation: animRunSlide .4s 2s forwards;
        }
        &--label {
          animation: animRunSlide .4s 2.4s forwards;
        }
        &--logo1 {
          animation: animRunSlide .4s .4s forwards;
        }
        &--logo2 {
          animation: animRunSlide .4s .5s forwards;
        }
        &--logo3 {
          animation: animRunSlide .4s .9s forwards;
        }
        &--logo4 {
          animation: animRunSlide .4s .8s forwards;
        }
        &--logo5 {
          animation: animRunSlide .4s .7s forwards;
        }
        &--logo6 {
          animation: animRunSlide .4s 1.2s forwards;
        }
        &--logo7 {
          animation: animRunSlide .4s 1.3s forwards;
        }
        &--logo8 {
          animation: animRunSlide .4s 1.4s forwards;
        }
        &--logo9 {
          animation: animRunSlide .4s 1.5s forwards;
        }
        &--arrow1 {
          animation: animRunSlide .4s 2s forwards;
        }
        &--arrow2 {
          animation: animRunSlide .4s 2.4s forwards;
        }

      }
      //included-tab3
      .included-tab3 {
        &--avatar {
          animation: animRunSlide .4s 2.8s forwards;
        }

        &--avatar-label {
          animation: animRunSlide .4s 3s forwards;
        }

        &--cursor {
          animation: animRunSlide .4s 3.2s forwards;
        }

        &--label1 {
          animation: animRunSlide .4s .8s forwards;
        }

        &--label2 {
          animation: animRunSlide .4s 1.2s forwards;
        }

        &--label3 {
          animation: animRunSlide .4s 1.6s forwards;
        }

        &--label4 {
          animation: animRunSlide .4s 2s forwards;
        }

        &--label5 {
          animation: animRunSlide .4s 2.4s forwards;
        }
        &--rect {
          animation: animRunSlide .4s .4s forwards;
        }
      }
      //included-tab4
      .included-tab4 {
        &--avatar {
          animation: animRunSlide .4s .4s forwards;

        }
        &--avatar-label {
          animation: animRunSlide .4s .8s forwards;

        }
        &--gmail {
          animation: animRunSlide .4s 1.2s forwards;

        }
        &--phone {
          animation: animRunSlide .4s 1.6s forwards;

        }
        &--slack {
          animation: animRunSlide .4s 1.2s forwards;

        }
        &--zoom {
          animation: animRunSlide .4s 1.6s forwards;

        }
        &--line-blue {
          animation: animRunSlide .4s 2s forwards;

        }

        &--line-green {
          animation: animRunSlide .4s 2s forwards;

        }

      }
      //included-tab5
      .included-tab5 {
        &--book1 {
          animation: animRunSlide .4s .4s forwards;
        }
        &--book2 {
          animation: animRunSlide .4s .4s forwards;
        }
        &--book3 {
          animation: animRunSlide .4s .4s forwards;
        }
        &--book4 {
          animation: animRunSlide .4s .4s forwards;
        }
        &--faq {
          animation: animRunSlide .4s .8s forwards;
        }
      }
    }
  }
}
