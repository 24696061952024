// Cards
// ======================

// Vars
// ----------------------

$card-background: #FFFFFF;
$card-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
$card-shadow--hover: 0 6px 24px rgba(0, 0, 0, 0.12);
$card-border-radius: 4px;
$card-padding: 32px 24px;
$card-title-border: $orange;

// Base
// ----------------------

.card {
  background: $card-background;
  border-radius: $card-border-radius;
  box-shadow: $card-shadow;
  border: 0;

  &:hover {
    box-shadow: $card-shadow--hover;
  }

  &-title {
    @extend %font-secondary;
    @extend %font-size-xxl;
    @include px-rem('line-height', 26);
    @include px-rem('margin-bottom', 20);
    @include px-rem('padding-bottom', 20);

    font-weight: $font-weight-semibold;
    position: relative;

    &:before {
      background: $card-title-border;

      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40px;
      height: 3px;
    }

    a {
      color: $color-base;

      &,
      &:hover {
        text-decoration: none;
      }
    }
  }

  &-body {
    padding: $card-padding;
  }

  &-text {
    color: $light-gray-600;
    font-size: 1rem;
  }

  &-help {
    @include px-rem('font-size', 13);
    @include px-rem('line-height', 16);
    @include px-rem('margin-bottom', 12);

    color: $light-gray-400;
    display: block;
    text-transform: uppercase;
  }
}
