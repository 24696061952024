// Metrics
// ======================

.product-metrics  {
  @keyframes showBorder {
    100% {
      border: 2px solid #78CB38;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    }
  }
  @-webkit-keyframes showBorder {
    100% {
      border: 2px solid #78CB38;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    }
  }
  @keyframes line-metric1 {
    /* clean-css ignore:start */
    0% {
      d: path("M58 191C58 191 105.5 191 153 191C206.5 191 208.141 191 222.5 191C255.715 191 251.977 191 284.5 191C317.715 191 323.977 191 356.5 191C389.023 191 388.285 191 421.5 191C454.023 191 486 79 486 79");
    }
    100% {
      d: path("M108 231C108 231 161.335 220.296 203.5 190C236.834 166.049 258.687 121 273.046 121C306.262 121 306.262 88 338.785 88C372 88 372 171 404.523 171C437.046 171 437.046 245 470.262 245C502.785 245 536 119 536 119");
    }
    /* clean-css ignore:end */
  }
  @-webkit-keyframes line-metric1 {
    /* clean-css ignore:start */
    0% {
      d: path("M58 191C58 191 105.5 191 153 191C206.5 191 208.141 191 222.5 191C255.715 191 251.977 191 284.5 191C317.715 191 323.977 191 356.5 191C389.023 191 388.285 191 421.5 191C454.023 191 486 79 486 79");
    }
    100% {
      d: path("M108 231C108 231 161.335 220.296 203.5 190C236.834 166.049 258.687 121 273.046 121C306.262 121 306.262 88 338.785 88C372 88 372 171 404.523 171C437.046 171 437.046 245 470.262 245C502.785 245 536 119 536 119");
    }
    /* clean-css ignore:end */
  }
  @keyframes line-metric2 {
    /* clean-css ignore:start */
    0% {
      d: path("M58.5 192.5C58.5 192.5 113.655 192.501 150.5 192.5C195 192.5 192 192.5 221 192.5C250 192.5 248.477 192.5 281 192.5C314.215 192.5 321.977 192.5 354.5 192.5C387.023 192.5 388.285 192.5 421.5 192.5C454.023 192.5 484.5 192.5 484.5 192.5");
    }
    100% {
      d: path("M108.5 232.5C108.5 232.5 165.979 223.645 200 209.5C238.319 193.569 259 224 272.5 217.5C286 211 306.477 59 339 59C372.215 59 371.977 92 404.5 92C437.023 92 437.285 220 470.5 220C503.023 220 537.5 118 537.5 118");
    }
    /* clean-css ignore:end */
  }
  @-webkit-keyframes line-metric2 {
    /* clean-css ignore:start */
    0% {
      d: path("M58.5 192.5C58.5 192.5 113.655 192.501 150.5 192.5C195 192.5 192 192.5 221 192.5C250 192.5 248.477 192.5 281 192.5C314.215 192.5 321.977 192.5 354.5 192.5C387.023 192.5 388.285 192.5 421.5 192.5C454.023 192.5 484.5 192.5 484.5 192.5");
    }
    100% {
      d: path("M108.5 232.5C108.5 232.5 165.979 223.645 200 209.5C238.319 193.569 259 224 272.5 217.5C286 211 306.477 59 339 59C372.215 59 371.977 92 404.5 92C437.023 92 437.285 220 470.5 220C503.023 220 537.5 118 537.5 118");
    }
    /* clean-css ignore:end */
  }
  @keyframes trendsLine-metric1 {
    /* clean-css ignore:start */
    0% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    100% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    /* clean-css ignore:end */
  }
  @-webkit-keyframes trendsLine-metric1 {
    /* clean-css ignore:start */
    0% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    100% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    /* clean-css ignore:end */
  }
  @keyframes trendsLine-metric2 {
    /* clean-css ignore:start */
    0% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    100% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    /* clean-css ignore:end */
  }
  @-webkit-keyframes trendsLine-metric2 {
    /* clean-css ignore:start */
    0% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    100% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    /* clean-css ignore:end */
  }
  @keyframes trendsLine-metric3 {
    /* clean-css ignore:start */

    0% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    100% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    /* clean-css ignore:end */
  }
  @-webkit-keyframes trendsLine-metric3 {
    /* clean-css ignore:start */
    0% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    100% {
      d: path("M10 149C10 149 45.4806 149 83.5 149C116.45 149 137.998 149 169.5 149C206.071 149 219.808 149 256 149C292.192 149 310.722 149 341.5 149C390.744 149 426 149 426 149");
    }
    /* clean-css ignore:end */
  }

  .main {
    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }
    .content-section {
      margin: 0 0 $vspacing--mob / 2;
      @include media-breakpoint-up(lg) {
        margin: 0 0 $vspacing / 2;
      }
      border-top: 1px dotted transparent;
      border-bottom: 1px dotted transparent;
    }
  }

  .graphic-benefit,
  .get-started-new--picture,
  .graphic__get-started--right,
  .get-started-body,
  .get-started-name-wrapper {
    visibility: hidden;
    opacity: 0;
  }

  .get-customer-picture {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .section-animation,
  .content-animation {
    .graphic-item {
      visibility: hidden;
      opacity: 0;
      position: relative;
    }
    &.has-animation {
      .graphic-item{
        animation: animRunSlide .5s linear forwards;
      }
    }
  }

  .get-started {
    &:not(.get-started-new) {
      margin: 0;
    }
  }

  .has-animation {
    .get-started-new--picture,
    .graphic__get-started--right,
    .get-started-body,
    .get-started-name-wrapper,
    .graphic-benefit {
      animation: animRunSlide .4s linear forwards;
      animation-delay: .4s;
    }
    .graphic-benefit__left {
      transform: translate(0, -80px);
    }
    .get-started-name-wrapper {
      transform: translate(150px, 0);
    }
    .get-started-new--picture {
      transform: translate(150px, 0);
    }
    .graphic__get-started--right {
      transform: translate(50px, 0);
    }
    .get-started-body {
      transform: translate(-50px, 0);
    }
    .svg-animate {
      g,
      circle {
        animation: animation-showhide .5s linear forwards 1.5s;
      }
      #line-metric1 {
        animation: line-metric1 1s linear forwards  .5s;
      }
      #line-metric2 {
        animation: line-metric2 1s linear forwards  .5s;
      }
      #trendsLine-metric1 {
        animation: trendsLine-metric1 4s infinite forwards  1s;
      }
      #trendsLine-metric2 {
        animation: trendsLine-metric2 4s linear forwards  2s;
      }
      #trendsLine-metric3 {
        animation: trendsLine-metric3 4s linear forwards 3s;
      }
    }

    .trends-section--border {
      animation: showBorder .1s linear forwards;
    }
  }


  .hero-metrics {
    &--animation {

    }
    &--img-circle {
      margin-left: -80%;
    }
    &--img-bg {
      bottom: 0;
      right: 0;
      margin-bottom: 14%;
      margin-right: -7%;
      transform: rotateY(10deg);
    }
    &--img1 {
      margin-left: 10%;
      bottom: 0;
      transform: translate(0%, 0%);
      animation-delay: .5s !important;
      animation-duration: .5s !important;
      @include media-breakpoint-down(sm) {
        max-width: 20vw;
        margin-left: -1%;
      }
    }
    &--img2 {
      bottom: 0;
      left: 50%;
      transform: translate(0%, 80%);
      margin-bottom: 4.9%;
      margin-left: 0;
      animation-delay: 2.8s !important;
      @include media-breakpoint-down(sm) {
        max-width: 20vw;
        margin-bottom: 5%;
        margin-left: -12%;
      }
    }
    &--img3 {
      bottom: 0;
      left: 50%;
      transform: translate(0%, -80%);
      margin-bottom: 23%;
      margin-left: 16%;
      animation-delay: 3s !important;
      @include media-breakpoint-down(sm) {
        max-width: 20vw;
        margin-bottom: 28%;
        margin-left: 7%;
      }
    }
    &--img4 {
      bottom: 0;
      left: 50%;
      transform: translate(0%, 80%);
      margin-bottom: 6.5%;
      margin-left: 26%;
      animation-delay: 3.5s !important;
      @include media-breakpoint-down(sm) {
        max-width: 20vw;
        margin-bottom: 7.5%;
        margin-left: 23%;
      }
    }
    &--line {
      bottom: 20px;
      right: 0;
      z-index: -1;
      &-blue {
        margin-bottom: 1.5%;
        z-index: -1;
      }
      &-orange {
        margin-bottom: 2%;
        z-index: -1;
      }
      &-white {
        z-index: -1;
      }
      &-dotted {
        margin-bottom: 1%;
        z-index: -1;
      }
    }
  }

  .metrics-section {
    .metric {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }

      //step1
      &--step1 {
        &--event {
          left: 0;
          bottom: 0;
          z-index: 1;
          margin: 0 0 -5% -10%;
          transform: translate(80%, 0%);
          animation-delay: .5s !important;
          @include media-breakpoint-down(sm) {
            max-width: 22vw;
          }
          &-hover {
            left: 0;
            bottom: 0;
            z-index: 1;
            margin: 0 0 -5% -10%;
            animation-delay: 1.3s !important;
            animation-duration:  0s !important;
            @include media-breakpoint-down(sm) {
              max-width: 22vw;
            }
          }
        }
        &--form {
          right: 0;
          top: 0;
          z-index: 1;
          margin: -5% -45% 0 0%;
          transform: translate(-80%, 80%);
          animation-delay: 1.3s !important;
          @include media-breakpoint-down(sm) {
            max-width: 45vw;
            margin:-5% -15% 0 0%;
          }
        }
        &--rect {

        }

        &--acalc {
          left: 0;
          top: 0;
          z-index: 2;
          margin: 1% 0 0% 56%;

          @include media-breakpoint-down(sm) {
            margin: 27% 0 0% 40%;
          }

          transform: translate(80%, 0%);
          animation-delay: 1.2s !important;
        }
        &--aform {
          left: 0;
          top: 0;
          z-index: 2;
          margin: -14% 0 0% 25%;
          @include media-breakpoint-down(sm) {
            margin: -30% 0 0% 10%;
            max-width: 100vw;
          }

          transform: translate(0%, -80%);
          animation-delay: .8s !important;
        }
        &--aname {
          margin: 10% 0 0 -25%;
          @include media-breakpoint-down(sm) {
            margin: 10% 0 0 0%;
            max-width: 75vw;
          }
          transform: translate(0%, 80%);
          animation-delay: .4s !important;
        }
        &--aevent {
          left: 0;
          bottom: 0;
          z-index: 2;
          margin: 0% 0% 6% 1%;
          @include media-breakpoint-down(sm) {
            margin: 0% 0% -19% 5%;
            max-width: 24vw;
          }
          transform: translate(-80%, 0%);
          animation-delay: 1.5s !important;
        }
      }

      // step2
      &--step2 {}

      //step3
      &--step3 {
        &--notify {
          right: 0;
          top: 0;
          z-index: 1;
          margin: -8% -15% 0 0;
          transform: translate(0%, 80%);
          animation-delay: .5s !important;
          @include media-breakpoint-down(sm) {
            margin: -8% -6% 0 0;
            max-width: 38vw;
          }

        }
        &--rect {

        }
      }

      //step4
      &--step4 {
        &--rect {

        }
      }

      //step5
      &--step5 {
        &--alert {
          left: 0;
          top: 0;
          z-index: 1;
          margin: -5% 0 0 -15%;
          transform: translate(0%, 80%);
          animation-delay: .5s !important;
          @include media-breakpoint-down(sm) {
            margin: -8% 0 0 -10%;
            max-width: 38vw;
          }
        }
        &--rect {

        }
      }

      //step6
      &--step6 {
        &--calc {
          right: 0;
          top: 0;
          z-index: 1;
          margin: -5% -25% 0 0;
          transform: translate(0%, 80%);
          animation-delay: .5s !important;
          @include media-breakpoint-down(sm) {
            margin: -5% -10% 0 0;
            max-width: 46vw;
          }
        }
        &--notify {
          left: 0;
          top: 0;
          z-index: 1;
          margin: 3% 0 0 -25%;
          transform: translate(0%, 80%);
          animation-delay: 1.5s !important;
          @include media-breakpoint-down(sm) {
            max-width: 38vw;
            margin: 3% 0 0 -15%;
          }
        }
        &--form {

        }
      }

      //step7
      &--step7 {
        &--notify {
          left: 0;
          right: 0;
          top: 0;
          z-index: 1;
          margin: -8% 0 0 0;
          transform: translate(0%, 80%);
          animation-delay: .8s !important;
          @include media-breakpoint-down(sm) {
            max-width: 38vw;
            margin: -8% 0 0 25%;
          }
        }
        &--form {

        }
      }

      //step8
      &--step8 {
        &--notify {
          left: 0;
          right: 0;
          top: 0;
          z-index: 1;
          margin: -8% 0 0 0;
          transform: translate(0%, 80%);
          animation-delay: .8s !important;
          @include media-breakpoint-down(sm) {
            max-width: 38vw;
            margin: -8% 0 0 25%;
          }
        }
        &--form {

        }
      }

      //slider
      &--slider-item {
        @include media-breakpoint-down(sm) {
          max-width: 70vw;
        }
      }
      &--slider-wrapper {
        @include media-breakpoint-up(md) {
          //margin-top: 80px !important;
        }
        .hide {
          display: none;
          opacity: 0;
          z-index: -1;
        }
        .show {
          display: block;
          opacity: 1;
          z-index: 9;
        }
      }

    }
    &--bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -9;
      margin-top: -7%;
      img {
        @include media-breakpoint-down(sm) {
          max-width: 200vw;
          height: auto;
        }
      }
    }

    //new
    &--container {
    }
    &--form {
      margin: 0 auto;
      min-width: 100%;
      min-height: 100%;
      width: 548px;
      height: 290px;
      max-width: 548px;
      max-height: 290px;
      aspect-ratio: 274 / 145;
      flex-shrink: 0;
      border-radius: 16px;
      border: 2px solid #78CB38;
      background: #FFF;
      box-shadow: 0px 0.836px 1.672px 0px rgba(0, 0, 0, 0.12), 0px 1.672px 6.687px 0px rgba(0, 0, 0, 0.05);
      padding: 20px;
      &_item {
        display: block;
        @extend .mb-3;
        .hide {
          display: none;
          opacity: 0;
        }
        .show {
          display: block;
          opacity: 1;
        }
      }
      &_label {
        color: var(--Text-Soft, #767D8A);
        font-feature-settings: 'liga' off;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.374px; /* 111.452% */
        letter-spacing: 0.334px;
        @extend .mb-2;
      }
      &_value {
        color: #2F3941;
        font-feature-settings: 'liga' off;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.374px; /* 111.452% */
        letter-spacing: 0.334px;

      }
      &_input {
        display: block;
        width: 100%;
        height: 30px;
        border-radius: 2.5px;
        border: 0.836px solid var(--Light-Gray-5, #C4C9CC);
        background: #FFF;
        padding: 0 12px;
        overflow: hidden;
        line-height: 25px;
      }
      &_placeholder {
        color: var(--Text-Soft, #767D8A);
        font-feature-settings: 'liga' off;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 13.374px; /* 111.452% */
        letter-spacing: 0.334px;
      }

    }
    &--title {
      color: #283240;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20.061px; /* 143.296% */
      letter-spacing: 0.418px;
      @extend .pt-3;
    }
    &--formula {
      color: var(--Text-Muted, #A7AEB8);
      font-family: Roboto;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px; /* 130% */
      letter-spacing: 0.334px;
      @extend .my-3;
      .badge {
        color: #2F3941;
        font-family: Roboto;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px; /* 109.091% */
        letter-spacing: 0.4px;
        padding: 3px 10px;
        border-radius: 10px;
        background: #E2E4E9;
        @extend .m-1;
        &-blue {
          background: #C8E7FE;
        }
      }
      //.hide {
      //  display: none;
      //  opacity: 0;
      //}
      //.show {
      //  display: block;
      //  opacity: 1;
      //}

    }




    //
  }
  .trends-section {
    &--tasks-lines {
      height: 182px;
      @include media-breakpoint-down(sm) {
        height: 135px;
      }
    }
    &--border {
      animation-delay: 1.6s !important;
      border: 2px solid transparent;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0);
      padding: 15px;
      margin-left: 15%;
      border-radius: 16px;
    }
    &--numbers {
      transform: translate(0%, -80%);
      animation-delay: 1s !important;
      @include media-breakpoint-down(sm) {
        max-width: 30vw;
        left: 54%;
      }
      @include media-breakpoint-up(md) {
        max-width: 18vw;
        margin-left: 50%;
      }
    }
    &--graph {
      circle {
        opacity: 0;
      }
      transform: translate(0%, -80%);
      animation-delay: .5s !important;

    }
    &--chart {
      circle {
        opacity: 0;
      }
      transform: translate(0%, -80%);
      animation-delay: 1s !important;
      @include media-breakpoint-down(sm) {
        max-width: 37vw;
      }

      @include media-breakpoint-up(md) {
        max-width: 20vw;
      }

    }
    &--slider {
      .hide {
        display: none;
        opacity: 0;
        z-index: -1;
      }
      .show {
        display: block;
        opacity: 1;
        z-index: 9;
      }
    }
  }
  .benchmarks-section {
    &--chart {
      g {
        opacity: 0;
      }
      transform: translate(0%, -80%);
      animation-delay: .6s !important;

    }
  }
}
