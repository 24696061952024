// Careers
// ======================

// Vars
// ----------------------

$card-oportunities-bg: #f0f6fb;
$dark-blue-hero: #2A2B3C;

// Base
// ----------------------
.careers {
  .hero {
    margin: 0;

    .container {
      padding-bottom: 60px;
    }
  }

  .main {
    padding-top: 140px;
  }

  .content-section {
    h3 {
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 23px;
      color: $blue-900;
      opacity: 0.7;
    }
  }

  .career-bg {
    width: 100%;
  }

  .hero {
    picture {
      position: absolute;
      width: 100%;
      z-index: -1;
    }

    .hero-intersect {
      bottom: 0;

      &--right {
        left: auto;
        right: 50%;
        margin: 0 calc(-50vw - 140px) 0 0;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .main {
      padding-top: 90px;
    }
  }
}

.hero-more {
  max-width: 90%;
  margin: 0 auto;

  @include media-breakpoint-up(lg) {
    max-width: 45%;
  }

  p {
    text-align: left;
  }
}

.who-we-are {
  @include media-breakpoint-up(lg) {
    picture {
      display: block;
      text-align: right;
    }
  }
}

.join-us {
  text-align: center;

  .container {
    max-width: 770px !important;
  }

  &.content-section {
    p {
      margin: 0 0 40px;

      @include media-breakpoint-up(lg) {
        margin: 0 0 60px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 140px;
  }

  .card {
    text-align: left;
    background: $white;
    border-radius: 0;
    box-shadow: none;
    border-top: 1px solid $light-gray-010;
    border-bottom: 1px solid $light-gray-010;

    &-body {
      padding: 110px 0 30px;
      position: relative;

      p {
        padding-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        padding: 32px 200px 32px 110px;
      }
    }

    .card-title {
      color: #575766;
      font-size: (20/16 + rem);
      line-height: 1.3;
      margin: 0;
      padding: 0;

      @include media-breakpoint-up(lg) {
        padding-bottom: 9px;
      }

      &:before {
        display: none;
      }
    }

    .card-text {
      margin: 0;
      color: #9c9ca6;
      font-size: (14/16 + rem);
    }

    .learn-more {
      display: block;
      margin-top: 15px;

      @include media-breakpoint-up(lg) {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }
    }

    .icon-arrow-right {
      margin-left: 5px;
    }

    .icon-full-stack {
      top: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;

      @include media-breakpoint-up(lg) {
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  .card + .card {
    border-top: 0;
  }
}

.page-career {
  .app-features {
    @include media-breakpoint-down(sm) {
      margin: 0 0 40px;
    }
  }

  .app-features-row {
    margin: 0 0 70px;

    @include media-breakpoint-up(lg) {
      margin: 0 0 140px;
    }

    &:last-of-type {
      padding: 0;
    }
  }

  .app-features .h2,
  .app-features p,
  .app-features .lead {
    @include media-breakpoint-down(sm) {
      text-align: center !important;
    }
  }

  .app-features-body {
    @include media-breakpoint-down(sm) {
      margin: 0 0 40px;
    }
  }
}

.about-team.-career {
  padding: 70px 0 0;
  margin: 0;

  .media {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-down(md) {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      margin: 0 0 30px;
    }

    .media-body {
      padding: 0;
    }

    .about-position {
      float: none;
    }

    img {
      margin: 0 auto 25px;
    }
  }
}

.on-2-cols {
  @include media-breakpoint-up(md) {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    text-align: justify;
  }
}

