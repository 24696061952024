// Home
// ======================

// Vars
// ----------------------

$hero-circle-size: 8000px;
$hero-circle-size--mob: 3000px;

$front-vspacing: $vspacing;
$front-vspacing--mob: $vspacing--mob;

$testimonials-bg: $gray-300;
$testimonials-border-radius: 20px;
$tab-active-bg: rgba(0, 108, 187, 0.04);
$tab-active-border: $blue;
$tab-image-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

// Base
// ----------------------

.home {
  .header {
    @include media-breakpoint-up(lg) {
      background: $dark-blue;
    }
  }

  .get-started {
    .hero-logos {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .get-started-note {
      @include media-breakpoint-up(lg) {
        margin: 0 0 32px;
      }
    }
  }

  .hero-description {
    color: #006CBB;
  }

  // Home products cards
  //
  @-webkit-keyframes delayedShow {
    to {
      visibility: visible;
    }
  }
  @keyframes delayedShow {
    to {
      visibility: visible;
    }
  }
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateY(80px);
    }
    to {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0;
      visibility: hidden;
      transform: translateY(80px);
    }
    to {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  .hero-home {
    .hide {
      visibility: hidden;
    }
    &.has-animation {
      .hero-grahic-user1,
      .hero-grahic-user2,
      .hero-grahic-user3,
      .hero-grahic-item1,
      .hero-grahic-item2,
      .hero-grahic-item3,
      .hero-grahic-item4 {
        -webkit-animation: fadein forwards, delayedShow;
        animation: fadein forwards, delayedShow;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        &.delay1 {
          animation-delay: 1400ms;
          -webkit-animation-delay: 1400ms;
        }
        &.delay2 {
          animation-delay: 2400ms;
          -webkit-animation-delay: 2400ms;
        }
        &.delay3 {
          animation-delay: 3400ms;
          -webkit-animation-delay: 3400ms;
        }
        &.delay4 {
          animation-delay: 4400ms;
          -webkit-animation-delay: 4400ms;
        }
      }
      .hero-grahic-user1 {
        animation-delay: 600ms;
        -webkit-animation-delay: 600ms;
      }
      .hero-grahic-user2 {
        animation-delay: 500ms;
        -webkit-animation-delay: 500ms;
      }
      .hero-grahic-user3 {
        animation-delay: 700ms;
        -webkit-animation-delay: 700ms;
      }

    }
  }

  .more-features {
    margin: 0 0 60px;
    @include media-breakpoint-up(lg) {
      margin: 110px;
    }
  }
  .hero-graphic-circle {
    top: -30px;
    right: 30px;
    left: auto;
  }
  .hero-grahic-road {
    top: -70px;
    right: auto;
    left: -60px;
  }
  .hero-grahic-user1 {
    bottom: -130px;
    right: auto;
    left: 130px;
  }
  .hero-grahic-user2 {
    bottom: -95px;
    right: auto;
    left: 190px;
  }
  .hero-grahic-user3 {
    bottom: -115px;
    right: auto;
    left: 260px;
  }

  .hero-grahic-item1 {
    bottom: -50px;
    left: 100px;
    right: auto;
    z-index: 100;
  }
  .hero-grahic-item2 {
    bottom: 24px;
    left: 190px;
    right: auto;
    z-index: 100;
  }
  .hero-grahic-item3 {
    bottom: 60px;
    left: auto;
    right: 110px;
    z-index: 100;
  }
  .hero-grahic-item4 {
    top: 140px;
    left: auto;
    right: 20px;
    z-index: 100;
  }
  .hero-graphic-circle-small {
    bottom: 20px;
    left: 220px;
  }
}



.front-section {
  margin: 0 0 $front-vspacing--mob;

  @include media-breakpoint-up(lg) {
    margin: 0 0 $front-vspacing;
  }

  h2 {
    @include px-rem('margin-bottom', 40);

    @include media-breakpoint-up(lg) {
      @include px-rem('margin-bottom', 60);
    }
  }

  &.from-blog {
    h2 {
      @include px-rem('margin-bottom', 16);
    }
    p {
      @include px-rem('margin-bottom', 24);

      @include media-breakpoint-up(lg) {
        @include px-rem('margin-bottom', 60);
      }

      color: rgba(#2F3941, 0.6);
    }
  }
}

.block-sep {
  border-top-color: $light-gray-010;
  margin: 40px 0;
}

// Hero
//

.hero-graphic {
  position: relative;
  padding: 0;
  text-align: center;

  @include media-breakpoint-up(lg) {
    text-align: right;
    padding: 31px 27px 31px 0;
  }

  @include media-breakpoint-down(sm) {
    transition: transform .3s linear, opacity .3s linear, visibility .3s linear;
    animation: animHomeSlide 0.3s linear forwards;
    transform: translateY(20px);
    opacity: 0;
  }

  &-item {
    position: absolute;
  }

  &-circle {
    top: 0;
    left: 0;
  }

  &-square {
    right: 0;
    bottom: 0;
  }

  picture {
    position: relative;
    z-index: 10;
    display: block;
  }
  &.t50 {
    top: -50px;
  }
}

@keyframes animHomeSlide {
  from {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.hero-stars {
  display: flex;
  flex-direction: row;

  @include media-breakpoint-up(sm) {
    position: absolute;
    bottom: 20px;
  }

  > div {
    margin-right: 20px;

    @include media-breakpoint-up(lg) {
      margin-right: 40px;
    }
  }

  .star-item {
    &:hover {
      img {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }

  img {
    transition: all 0.4s;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(100%) contrast(0%);
    opacity: 0.7;
  }
}


// Features
//

.product-features {
  &-item {
    @include media-breakpoint-down(sm) {
      margin: 0 0 20px;
    }

    .icon-svg {
      @include px-rem('margin-bottom', 24);
    }

    .icon-svg,
    h4 {
      display: block;
    }

    h4 {
      @include px-rem('margin-bottom', 20);
      @include px-rem('font-size', 20);

      font-weight: $font-weight-semibold;

      br {
        display: none;

        @include media-breakpoint-up(xl) {
          display: block;
        }
      }
    }

    p {
      color: $light-gray-600;
      @include px-rem('margin-bottom', 20);
    }
  }

  .block-sep {
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }
}

// Tabs
//

.product-tab {
  @include media-breakpoint-up(xl) {
    min-height: 765px;
  }

  @include media-breakpoint-up(lg) {
    margin: 0 0 120px;
  }

  &-navigation {
    margin: 0;
    padding: 0 25px 0 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
      flex: 1;
      position: relative;

      &.has-animation .product-tab--trigger,
      .product-tab--trigger:hover {
        color: $light-gray-800;

        strong {
          color: $blue;
        }
      }

      &.has-animation {
        &.loading {
          .product-tab--trigger {
            &:before {
              height: 100%;
              transition: height 4500ms linear;
            }
          }
        }
        &.noLoading {
          .product-tab--trigger {
            &:before {
              height: 100%;
              transition: none;
            }
          }
        }
        .product-tab--content {
          overflow: inherit;
          white-space: inherit;
          margin: 0 0 24px;
        }

        .product-tab--more {
          max-height: 24px;
          visibility: visible;
          height: auto;
        }

        .product-tab--trigger {
          //box-shadow: 0 11px 12px 0 rgba(36, 50, 66, .12);

          &,
          &:hover {
            background: $tab-active-bg;
          }

        }
      }

      .product-tab--trigger {
        display: block;
        height: 100%;
        font-size: 1rem;
        color: $light-gray-600;
        border-radius: 5px;
        padding: 24px 28px;
        position: relative;
        transition: all 225ms;
        cursor: pointer;

        &,
        &:hover {
          text-decoration: none;
          background: none;
        }

        &:before {
          background: $tab-active-border;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 0;
          border-radius: 5px 0 0 5px;
          transition: all 225ms, height 0ms;

        }
      }

      strong {
        @include px-rem('margin-bottom', 12);
        @extend %font-size-xxl;

        display: block;
        font-weight: $font-weight-semibold;
        color: $color-base;
        transition: all 0.3s;
      }

      .product-tab--more {
        display: block;
        visibility: hidden;
        transition: max-height 225ms ease-out;
        max-height: 0;
        height: 0;
        overflow: hidden;
        color: $blue;

        .icon-svg {
          margin-left: 5px;
        }
      }
    }
  }

  &-body {
    top: 0;
    left: 20px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    width: 640px;

    .product-graphic-container {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      z-index: 100;

      &.has-animation {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &-body,
  &-circles,
  &-square {
    position: absolute;
  }

  &-circles {
    right: -70px;
    bottom: 0;
    z-index: 0;

    &.left {
      left: -140px;
      right: inherit;
      bottom: 0;
    }
  }

  &-square {
    position: absolute;
    bottom: 115px;
    left: -30px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    animation: animRunFade 0.3s linear forwards;
  }

  &-item {
    @include px-rem('margin-bottom', 40);

    text-align: left;

    &:last-of-type {
      margin: 0;
    }

    strong {
      @include px-rem('font-size', 20);
      @include px-rem('margin-bottom', 12);

      display: block;
      font-weight: $font-weight-semibold;
      line-height: 26px;
    }

    p {
      @include px-rem('margin-bottom', 16);
    }

    picture {
      display: block;
      text-align: center;

      @media (max-width: 380px) {
        text-align: right;
        overflow: hidden;
      }

      img {
        @media (max-width: 380px) {
          float: right;
          margin: 0 -30px 0 0;
        }
      }
    }
  }

  &--content {
    font-size: .875rem;
    margin: 0;
  }

  &-animation {
    min-height: 574px;
  }

  .container {
    padding: 0 30px;
  }

  h2 {
    @include px-rem('margin-bottom', 40);
    padding: 0 15px;

    @include media-breakpoint-up(sm) {
      padding: 0;
    }

    @include media-breakpoint-up(md) {
      @include px-rem('margin-bottom', 90);
    }

    br {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
}

.product-graphic {
  &-container {
    position: relative;

    .graphic-image {
      display: inline-block;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      top: 0;
      z-index: 10;

      &--left {
        left: 0;
        transform: translateX(-50px);
      }

      &--right {
        right: 0;
        transform: translateX(50px);
      }
      &--bottom {
        bottom: 100px;
        top: inherit;
      }

      &--top {
        z-index: 100;
        top: 117px;
        right: 152px;
        transform: translate(150px, -20px);
      }
    }

    img {
      //box-shadow: $tab-image-shadow;
      z-index: 10;
      //border-radius: 4px;

      @include media-breakpoint-up(lg) {
        max-width: 590px;
      }
    }

    &.has-animation {
      .graphic-image {
        animation: animRunSlide 0.3s linear forwards;
      }
    }
  }

  .tab1-graphic {
    .product-tab-square {
      top: -20px;
      left: -20px;
      bottom: auto;
    }
  }

  .tab2-graphic {
    .product-tab-square {
      top: auto;
      left: 200px;
      bottom: 90px;
    }
  }
  .tab4-graphic {
    .product-tab-square {
      top: 80px;
      left: 535px;
    }
  }
  .tab5-graphic {
    .product-tab-square {
      top: auto;
      left: 305px;
      bottom: 165px;
    }
  }
}

.current-anim--1 {
  .product-tab-circles {
    svg {
      path {
        //opacity: 0;
      }
    }
  }
}
.current-anim--5 {
  .product-tab-circles {
    //visibility: hidden;
  }
}

@keyframes animRunSlide {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes animRunFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

// Testimonials
//

.product-testimonials {
  &__carousel-item {
    height: 100%;
    background: #F7F9FA;
    border-radius: 8px;
    padding: 40px 24px 24px;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    &--card {
      padding: 24px;
      background: $card-background;
      border-radius: $card-border-radius;
      box-shadow: $card-shadow;
      border: 0;

      &:hover {
        box-shadow: $card-shadow--hover;
      }
    }
  }


  &__blockquote {
    @include px-rem('margin-bottom', 16);
    border: 0;
    padding: 0;
    flex: 1 1 auto;
    position: relative;

    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: (24/16);
      display: inline-block;
    }
    .read-more-testimonial {
      max-height: 12em;
      line-height: 1.5;
      overflow: hidden;
    }
    .js-show-more-testimonial {
      background: linear-gradient(180deg, rgba($white, 0.1) 5%, rgba($white, 0.60) 40%, $white 100%);
      color: $blue;
      text-decoration: underline;
      display: flex;
      justify-content: end;
      padding: 3rem 0 0 0;
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .tq {
      color: #006CBB;
      font-size: 40px;
      line-height: 0;
      position: relative;
      top: 13px;
      display: inline-block;
    }
    svg {
      margin: 0 !important;
      display: inherit !important;
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong,
    small {
      display: block;
    }

    strong {
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
      margin-bottom: 4px;
    }

    small {
      color: rgba(#2F3941, 0.6);
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
    a {
      svg {
        margin: 0 !important;
        display: inherit !important;
      }
    }
  }
  &__height {
    height: calc(100% - 250px);
  }

  &__image-container {
    width: 104px;
    height: 104px;
    margin: 0 auto 40px;
    overflow: hidden;

  }
  &__image--card {
    overflow: hidden;

    img,
    picture,
    figure {
      display: block;
      text-align: center;
      margin: 0 auto;
      width: auto !important;
      max-height: 250px;
    }
  }

  .owl-carousel {
    position: relative;

    .owl-wrapper,
    .owl-stage {
      display: flex !important;
      padding: 20px 0;
    }

    .owl-wrapper-outer {
      overflow: hidden;
    }
  }

  .owl-buttons,
  .owl-nav {
    > div,
    > button {
      width: 18px;
      height: 30px;
      position: absolute;
      top: 50%;
      text-indent: -9999px;
      cursor: pointer;
      display: inline-block;
      text-align: left;
      margin-top: -40px;
    }

    .owl-prev {
      background: url(../images/home/testimonials/testimonial-left.svg) no-repeat !important;
      left: -10px;
      @include media-breakpoint-up(lg) {
        left: -60px;
      }
    }

    .owl-next {
      background: url(../images/home/testimonials/testimonial-right.svg) no-repeat !important;
      right: -10px;
      @include media-breakpoint-up(lg) {
        right: -60px;
      }
    }
  }
}

// From blog
//

.from-blog {
  .row {
    > div {
      @include media-breakpoint-down(sm) {
        margin: 0 0 30px;

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
}

// Get started
//

.get-started {
  &-note {
    @extend %font-size-sm;
    @include px-rem('line-height', 24);

    color: rgba(#2F3941, 0.4);
    display: block;
  }

  h2 {
    @include px-rem('margin-bottom', 16);
  }

  p {
    @include px-rem('margin-bottom', 24);

    @include media-breakpoint-up(lg) {
      @include px-rem('margin-bottom', 60);
    }

    color: rgba(#2F3941, 0.6);
  }

  .btn-wrapper {
    margin: 0 0 1rem;

    @include media-breakpoint-down(sm) {
      margin: 0 -30px;
      min-height: 78px;
    }

    &--inner {
      @include media-breakpoint-down(sm) {
        padding-right: 30px;
        padding-left: 30px;
        padding-bottom: 30px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, transform 0.3s;
        transform: translateY(100px);

        &.is-fixed {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(180deg, rgba(245, 249, 252, 0) 0%, #F5F9FC 50.63%);
          padding-top: 80px;
          z-index: 9999;
          transform: translateY(0);
          visibility: visible;
          opacity: 1;

          .btn {
            margin: 0;
          }
        }

        &.is-bottom {
          position: static !important;
          background: none;
          padding-top: 0;
        }
      }
    }

    .btn {
      margin: 0;

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: inherit;
        display: block;
      }
    }
  }

  .get-started-note {
    @include media-breakpoint-down(sm) {
      margin-top: -15px;
    }
  }
}

.has-scroll--button {
  @include media-breakpoint-down(sm) {
    #drift-widget {
      bottom: 100px !important;
    }
  }

  &.is-bottom {
    @include media-breakpoint-down(sm) {
      #drift-widget {
        bottom: 0 !important;
      }
    }
  }
}
