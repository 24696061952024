// Overview
// ======================

// Vars
// ----------------------

$overview-card-title: $purple;
$overview-meta: #acafbf;
$card-vspacing: 48px;
$card-vspacing--mobile: 30px;

// Base
// ----------------------

.page.page-overview {
  padding-bottom: 60px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 140px;
  }

  #mc_embed_signup input.mce_inline_error {
    border: 0;
  }

  .btn-load-more {
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 370px;
    }
  }
}

// Overview cards
//

.articles-list-row {
  > div {
    margin-bottom: $card-vspacing--mobile;

    @include media-breakpoint-up(lg) {
      margin-bottom: $card-vspacing;
    }
  }
}

.card.overview-card {
  border: 0;
  height: 100%;
  margin: 0;

  &.-is-featured {
    display: flex;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      margin: 0;
    }

    .card-image {
      @include media-breakpoint-up(lg) {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
      }

      @include media-breakpoint-down(md) {
        &,
        img,
        a {
          border-radius: 4px 4px 0 0;
        }
      }

      padding-left: 0;

      a {
        display: block;
        position: relative;
        height: 100%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        @include media-breakpoint-between(lg, lg) {
          margin-right: 10px;
        }
      }

      img {
        border-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        height: auto !important;

        @include media-breakpoint-between(lg, lg) {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 100% !important;
        }

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    .card-body {
      @include media-breakpoint-up(lg) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }

      padding-top: 30px;
      padding-left: 25px;

      .is-featured-title {
        color: $light-gray-400;
        font-size: 13px;
        line-height: 16px;
        text-transform: uppercase;
        margin: 0 0 12px;
      }

      p {
        color: $light-gray-600;
        margin: 0;
      }

      .card-title {
        @include px-rem('margin-bottom', 20);

        a {
          font-size: 28px;
          line-height: 38px;
          text-transform: capitalize;
        }
      }
    }
  }

  &.mp-card {
    background-color: $gray-300;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0);
  }

  .most-popular-card {
    color: $blue-900;
    height: 100%;

    .mp-title {
      background-color: #EBEEF0;
      height: 64px;
      padding: 0 24px;
    }

    h5 {
      font-size: 20px;
      line-height: 64px;
      text-transform: capitalize;
      margin: 0;
    }

    .mp-content {
      padding: 12px 24px 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        border-bottom: 2px solid #EBEEF0;

        &:last-child {
          border-bottom: 0;
        }
      }

      a {
        padding: 20px 0;
        font-size: 15px;
        line-height: 20px;
        color: $color-base;

        &,
        small {
          display: block;
        }

        &,
        &:hover {
          text-decoration: none;
        }

        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }

      small {
        text-transform: uppercase;
        color: $light-gray-400;
        font-size: 13px;
        line-height: 16px;
        margin: 0 0 6px;
      }
    }
  }

  .card-image {
    overflow: hidden;
    border-radius: 4px 4px 0 0;

    a {
      display: block;
      overflow: hidden;
      border-radius: 4px 4px 0 0;
      position: relative;

      //@include media-breakpoint-down(sm) {
      //  background: none !important;
      //}

      //@include media-breakpoint-up(md) {
      //  height: 208px;
      //  background-position: top center;
      //  -webkit-background-size: cover;
      //  background-size: cover;
      //  background-repeat: no-repeat;
      //}

      &,
      img {
        border-radius: 4px 4px 0 0 !important;

        @include media-breakpoint-up(md) {
          border-radius: 4px 0 0 4px !important;
        }
      }


      img {
        //@include media-breakpoint-up(md) {
        //  display: none;
        //}
      }
    }
  }

  .card-body {
    color: $blue-900;
    padding: 32px 24px;

    .body-title {
      color: $light-gray-400;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      margin: 0 0 12px;
    }

    h5 {
      margin: 0 0 20px;

      a {
        display: block;
        font-size: 20px;
        line-height: 26px;
        text-transform: capitalize;
      }
    }

    p {
      color: $light-gray-600;
      margin: 0;
    }
  }

  .card-title {
    margin: 0;
    font-size: (24/16 + rem);
    font-weight: 500;

    &,
    a {
      text-decoration: none;

      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      text-transform: capitalize;

      color: $blue-900;
    }

    a:hover {
      text-decoration: underline;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: $light-gray-600;
    }
  }

  .article-meta {
    color: $overview-meta;
    display: block;
    font-size: (13/16 + rem);
    font-weight: 700;
    margin: 0 0 10px;
  }
}
