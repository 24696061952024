// Font Variables
$font-roboto: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-montserrat: 'Montserrat', 'Helvetica', 'Arial', sans-serif;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 700;
$font-family-base: $font-roboto;

%font-primary {
  font-family: $font-roboto;
}

%font-secondary {
  font-family: $font-montserrat;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-secondary {
  font-family: $font-montserrat;
}
.font-primary {
  font-family: $font-roboto;
}
